const component = () => import(/* webpackChunkName: "settings-pages" */'./delete-account');

export default {
	component,
	path : 'delete-account',
	name : 'delete-account',
	meta : {
		title       : 'Delete Log-in',
		secure      : true,
		skipBilling : true,
	},
};
