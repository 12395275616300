import localStorage from '@/services/local-storage.service';

export default {
	namespaced : true,

	state : {
		team : null,

		list : null,
		flag : null,
	},

	getters : {
		team(state) {
			if (!state.team)
				state.team = localStorage.get('team');

			return state.team;
		},

		list(state) {
			return flag => !state.flag || state.flag === flag ? state.list : null;
		},
	},

	mutations : {
		set(state, { team }) {
			state.team = team || null;

			localStorage.set('team', state.team);

			if (!state.team) {
				state.list = null;
				state.flag = null;
			}

			return state.team;
		},

		list(state, { list, flag }) {
			if (flag && !/^company_id=/.test(flag)) return list;

			state.list = list || null;
			state.flag = flag || null;

			return state.list;
		},

		clear(state) {
			state.team = null;
			state.list = null;
			state.flag = null;

			localStorage.set('team', null);

			return null;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},

		list({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('list', payload));
		},

		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};
