import localStorage from '@/services/local-storage.service';
import sessionStorage from '@/services/session-storage.service';

export default {
	namespaced : true,

	state : {
		user : null,

		list : null,
		flag : null,
	},

	getters : {
		user(state) {
			if (!state.user)
				state.user = localStorage.get('user');

			return state.user;
		},

		list(state) {
			return flag => !state.flag || state.flag === flag ? state.list : null;
		},
	},

	mutations : {
		set(state, { user }) {
			state.user = user || null;

			localStorage.set('user', state.user);
			sessionStorage.set('user', state.user);

			if (!state.user) {
				state.list = null;
				state.flag = null;
			}

			return state.user;
		},

		list(state, { list, flag }) {
			state.list = list || null;
			state.flag = flag || null;

			return state.list;
		},

		clear(state) {
			state.user = null;
			state.list = null;
			state.flag = null;

			localStorage.set('user', null);
			sessionStorage.set('user', null);

			return null;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},

		list({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('list', payload));
		},

		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};
