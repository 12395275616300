import Vue from 'vue';
import Component from 'vue-class-component';
import template from './avatar.html';

const props = {
	value : {
		type      : Object,
		structure : {
			icon : {
				type : String,
				hint : 'fa-icon identifier',
			},
			class : {
				type : String,
				hint : 'CSS class, applied to icon',
			},
			color : {
				type : String,
				hint : 'CSS3 color',
			},
			label : {
				type : String,
				hint : 'Short 2-3 character to display on avatar',
			},
		},
	},
	compact  : Boolean,
	expanded : Boolean,
	rounded  : Boolean,
};

@Component({
	template,
	props,
})
export class Avatar extends Vue {
	get background() {
		return this.value.icon && this.compact ? 'transparent' : this.value.color;
	}

	get classname() {
		return {
			compact  : this.compact,
			expanded : this.expanded,
			rounded  : this.rounded,
		};
	}
}

Vue.component('avatar', Avatar);
