import Vue from 'vue';
import Component from 'vue-class-component';
import template from './session.html';
import Async from '@/plugins/async-decorator';
import analytics from '@/services/analytics.service';
import BillingService from '@/services/billing.service';
import api from '@/api';
import { isDemoUser } from '@/services/permission.service';

@Component({
	template,
	beforeRouteUpdate(to, from, next) {
		this.loading = true;

		return Promise.all([
			to.matched.some(({ meta }) => meta.skipBilling),
			to.matched.some(({ meta }) => meta.skipLegal),
			to.matched.some(({ meta }) => meta.skipOnboarding),
		])
			.then(([skipBilling, skipLegal, skipOnboarding]) => Promise.all([
				skipBilling || this.checkBillingStatus(),
				skipLegal || this.checkLegalConsent(),
				skipOnboarding || this.checkOnboarding(to),
			]))
			.then(routes => routes.find(route => route && route !== true))
			.then(route => route && Promise.reject(route))
			.then(() => api.resetQueue())
			.then(() => next())
			.catch(error => next(error || { name : 'logout' }))
			.finally(() => { this.loading = false });
	},
})
export default class Session extends Vue {
	data() {
		return {
			loading : null,
		};
	}

	created() {
		this.loading = true;

		return Promise.all([
			this.checkBillingStatus(),
			this.checkLegalConsent(),
			this.checkOnboarding(this.$route),
		])
			.then(routes => routes.find(route => route && route !== true))
			.then(route => route ? this.$router.push(route) : this.continueToApplication())
			.catch(() => this.$router.push({ name : 'logout' }))
			.finally(() => { this.loading = false });
	}

	@Async
	get company() {
		return api.company.get();
	}

	beforeDestroy() {
		analytics.reset();
	}

	checkBillingStatus() {
		return Promise.resolve(api.company.get())
			.then(company => BillingService.checkBilling(company && company.company_id))
			.then(() => BillingService.isBillingIssue)
			.then(problem => problem ? { name : 'billing-settings' } : null);
	}

	checkLegalConsent() {
		return isDemoUser()
			.then(isDemo => isDemo || api.legalConsent.list()
				.then(list => list.every(({ legalConsentGranted }) => legalConsentGranted))
				.then(granted => granted ? null : { name : 'legal-consent' }));
	}

	checkOnboarding(route) {
		return Promise.all([
			route.matched.some(({ meta }) => meta.requireCompany),
			route.matched.some(({ meta }) => meta.requireWorkZone),
		])
			.then(([
				requireCompany,
				requireWorkZone,
			]) => Promise.all([
				!requireCompany || api.company.get(),
				!requireWorkZone || api.workZone.get(),
			]))
			.then(([company, workZone]) => company && workZone)
			.then(onboarded => onboarded ? null : { name : 'onboarding' });
	}

	continueToApplication() {
		return api.user.get()
			.then(user => analytics.identify(user));
	}
}
