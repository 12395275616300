import storage from '@/services/storage.service';
import { AddCompanyModal } from '@/modals/add-company-modal/add-company-modal';
import { AddWorkZoneModal } from '@/modals/add-work-zone-modal/add-work-zone-modal';
import { DateInputModal } from '@/modals/date-input-modal/date-input-modal';
import { ModalProgrammatic } from 'buefy/dist/components/modal';
import { ModalVerification } from '@/modals/modal-verification/modal-verification';
import { SelectCompanyModal } from '@/modals/select-company-modal/select-company-modal';
import { VerifyDeletionModal } from '@/modals/verify-deletion-modal/verify-deletion-modal';

const modalTypes = {
	'add-company'     : AddCompanyModal,
	'add-work-zone'   : AddWorkZoneModal,
	'date-input'      : DateInputModal,
	'select-company'  : SelectCompanyModal,
	'verify-deletion' : VerifyDeletionModal,
};

export default {
	modalTypes,

	launchModal,
	launchVerification,
};

const activeModals = {}; // { <modal_id> : Boolean }

function launch({ modal_id, component, canCancel = true, onSubmit, props }) {
	return new Promise((resolve, reject) => {
		if (modal_id && activeModals[modal_id]) return reject();

		activeModals[modal_id] = true;

		const modal = ModalProgrammatic.open({
			component,
			canCancel,

			onCancel : () => {
				reject();
				activeModals[modal_id] = false;
			},

			props : Object.assign({}, props, {
				submit : data => Promise.resolve(onSubmit ? onSubmit(data) : data)
					.then(data => {
						resolve(data);
						modal.close();
						activeModals[modal_id] = false;
					}),
				cancel : () => {
					reject();
					modal.cancel('button');
					activeModals[modal_id] = false;
				},
			}),
		});
	});
}

function launchModal(modal_id, props = {}) {
	const component = modalTypes[modal_id];

	if (!component) throw new Error(`Unknown modal: '${modal_id}'`);

	const buefyLauncher = props => launch({
		component,
		props,
		canCancel : props.canCancel,
		modal_id  : props.modal_id || modal_id,
		onSubmit  : props.onSubmit || (data => data),
	});

	const montageLauncher = props => storage.push('modal', {
		component,
		props,
	});

	const launcher = {
		'add-company'     : montageLauncher,
		'add-work-zone'   : montageLauncher,
		'date-input'      : montageLauncher,
		'select-company'  : montageLauncher,
		'verify-deletion' : montageLauncher,
	}[modal_id] || buefyLauncher;

	return launcher(props);
}

function launchVerification(props) {
	const component = ModalVerification;

	return launch({
		component,
		props,
		modal_id : props.modal_id,
	});
}
