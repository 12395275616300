import component from './purchase-orders-dashboard';

export default {
	component,
	path : '/purchase-orders-dashboard',
	name : 'purchase-orders-dashboard',
	meta : {
		title  : 'Purchase Orders Dashboard',
		secure : true,
	},
};
