import Vue from 'vue';
import Component from 'vue-class-component';
import template from './settings-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import { isDemoUser, checkCompanyPrivilege, checkWorkZonePrivilege } from '@/services/permission.service';

@Component({ template })
export class SettingsList extends Vue {
	get list() {
		const list = [{
			route : 'user-profile',
			label : `Your Profile`,
			shown : true,
		}, {
			route : 'companies',
			label : `All ${this.$dsl('Companies')}`,
			shown : true,
		}, {
			route : 'company-details',
			label : `${this.$dsl('Company')} Details`,
			shown : Boolean(this.company),
		}, {
			route : 'billing-settings',
			label : `${this.$dsl('Billing')}`,
			shown : Boolean(this.company),
		}, {
			route : 'users',
			label : `${this.$dsl('Users')}`,
			shown : this.showCompanySettings,
		}, {
			route : 'employees',
			label : `${this.$dsl('Employees')}`,
			shown : this.showCompanySettings,
		}, {
			route : 'work-codes',
			label : `${this.$dsl('Work Codes')}`,
			shown : this.showCompanySettings,
		}, {
			route : 'work-zones',
			label : `${this.$dsl('Work Zones')}`,
			shown : Boolean(this.company),
		}, {
			route : 'work-locations',
			label : `${this.$dsl('Work Locations')}`,
			shown : this.showCompanySettings,
		}, {
			route : 'settings/purchase-orders',
			label : `${this.$dsl('Purchase Orders')}`,
			shown : this.showCompanySettings,
		}, {
			route : 'settings/overtime-hours',
			label : 'Overtime',
			shown : this.showCompanySettings,
		}, {
			route : 'timesheet-export',
			label : 'Bulk Timesheet Print',
			shown : Boolean(this.company),
		}, {
			route : 'change-password',
			label : `Change ${this.$dsl('Password')}`,
			shown : true,
		}, {
			route : 'export-account',
			label : `Export ${this.$dsl('User')} Details`,
			shown : true,
		}, {
			route : 'legal-consent',
			label : `Legal Consent`,
			shown : true,
		}, {
			route : 'delete-account',
			label : `Delete Log-in`,
			shown : true,
		}];

		// for (let index = list.length - 1; index > 0; index--) {
		// 	const swap = Math.floor(Math.random() * (index + 1));
		// 	const temp = list[index];

		// 	list[index] = list[swap];
		// 	list[swap] = temp;
		// }

		return list;
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}

	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get workZone() {
		return api.workZone.get();
	}

	@Async(false)
	get showCompanySettings() {
		return checkCompanyPrivilege('update');
	}

	@Async(false)
	get showWorkZoneSettings() {
		return checkWorkZonePrivilege('update');
	}
}

Vue.component('settings-list', SettingsList);
