import Vue from 'vue';
import Component from 'vue-class-component';
import template from './click-to-copy.html';

const props = {
	value : {
		type     : String,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class ClickToCopy extends Vue {
	data() {
		return {
			id        : `${Date.now()}${Math.random()}`,
			classname : null,
			timeout   : null,
		};
	}

	copy() {
		const max = 99999;
		const element = document.getElementById(this.id);

		element.select();
		element.setSelectionRange(0, max);
		document.execCommand('copy');

		element.setSelectionRange(0, 0);
		element.blur();

		this.showMessage();
	}

	showMessage() {
		const delay = 1500; // how long the 'Copied' message is displayed.

		// remove any existing 'Copied' message.
		this.classname = null;

		// wait a tick to let the class-change be applied.
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			// show the message.
			this.classname = 'open';

			// remove the message after the delay.
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				this.classname = null;
			}, delay);
		});
	}
}

Vue.component('click-to-copy', ClickToCopy);
