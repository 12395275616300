import Vue from 'vue';
import Component from 'vue-class-component';
import template from './highlight-not-billed.html';
import Watch from '@/plugins/watch-decorator';
import { CheckboxInput } from '@/inputs/checkbox-input/checkbox-input';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	CheckboxInput,
	Spinner,
};

const props = {
	value : Object, // dateRange object, { startDate, endDate, ... }
	show  : Boolean, // currently showing filter controls
};

@Component({
	template,
	components,
	props,
})
export default class WorkOrderHighlightFilter extends Vue {
	data() {
		return {
			filters : null,
		};
	}

	@Watch.immediate('value')
	watchValue() {
		if (!this.value) return;

		this.filters = {
			highlightNotBilled : this.value.highlightNotBilled,
		};
	}

	@Watch('filters')
	watchFilters() {
		this.$emit('input', this.filters);
	}
}
