import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-reports.html';

import { MainSidebarLayout } from '@/layouts/main-sidebar-layout/main-sidebar-layout';

const components = {
	MainSidebarLayout,
};

@Component({
	template,
	components,
})
export default class WorkOrderReports extends Vue {}
