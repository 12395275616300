import component from './purchase-order-reports';

export default {
	component,
	path : '/purchase-order-reports',
	name : 'purchase-order-reports',
	meta : {
		title  : 'Purchase Order Reports',
		secure : true,
	},
};
