import Vue from 'vue';
import Component from 'vue-class-component';
import template from './purchase-order-form.html';
import api from '@/api';

import { BasicInput } from '@/base-components/basic-input/basic-input';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { DateInput } from '@/inputs/date-input/date-input';
import { DeleteButton } from '@/buttons/delete-button/delete-button';
import { EmployeeDropdown } from '@/components/employee-dropdown/employee-dropdown';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextareaInput } from '@/inputs/textarea-input/textarea-input';
import { WorkLocationDropdown } from '@/components/work-location-dropdown/work-location-dropdown';

const ivalue = '#value';
const components = {
	BasicInput,
	CancelButton,
	DateInput,
	DeleteButton,
	EmployeeDropdown,
	SubmitButton,
	TextareaInput,
	WorkLocationDropdown,
};
const props = {
	value : Object,
	type  : {
		type     : String,
		required : true,
	},
};

@Component({
	template,
	components,
	props,
})
export class PurchaseOrderForm extends Vue {
	data() {
		return {
			[ivalue]  : new api.purchaseOrder.PurchaseOrder(),
			locations : null,
			employees : null,
			loading   : false,
		};
	}

	mounted() {
		const value = this.value || {};
		const {
			workLocations : locations,
			employees,
		} = value;

		this.locations = getEmbeddedIds(locations, 'workLocation_id');
		this.employees = getEmbeddedIds(employees, 'employee_id');

		const workZone_id = value.workZone && value.workZone.workZone_id;

		Object.assign(this[ivalue], value, {
			workZone_id,
			type : this.type,
		});
	}

	get record() {
		return this[ivalue];
	}

	set record(value) {
		this[ivalue] = value;
	}

	get key() {
		return this[ivalue].purchaseOrder_id || null;
	}

	cancel() {
		this.$emit('error');
		this.$emit('input', null);
	}

	submit() {
		if (this.loading) return;

		this.loading = true;
		this.$emit('error');

		if (this.locations)
			this[ivalue].workLocations = this.locations.map(workLocation_id => ({ workLocation_id }));

		if (this.employees)
			this[ivalue].employees = this.employees.map(employee_id => ({ employee_id }));

		return api.purchaseOrder.save(this[ivalue])
			.then(({ purchaseOrder_id }) => api.purchaseOrder.read(purchaseOrder_id))
			.then(purchaseOrder => { this.$emit('input', purchaseOrder) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}

	del() {
		if (this.loading || !this.key) return;

		this.loading = true;
		this.$emit('error');

		return api.purchaseOrder.delete(this.key)
			.then(() => { this.$emit('input', null) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}
}

Vue.component('purchase-order-form', PurchaseOrderForm);


function getEmbeddedIds(list, keyProp) {
	if (!list)
		return null;

	return list.map(record => record[keyProp]);
}
