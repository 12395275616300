import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-location-dropdown.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

import { BasicSelect } from '@/base-components/basic-select/basic-select';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';

const components = {
	BasicSelect,
	Spinner,
	StatusMessage,
};

const props = {
	value    : [String, Array],
	multiple : Boolean,
	allowAll : Boolean,
	workZone : String,
	squish   : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class WorkLocationDropdown extends Vue {
	@AsyncLoading
	get workLocations() {
		if (this.workZone)
			return api.workLocation.list({ workZone_id : this.workZone });

		return api.workLocation.list();
	}

	get list() {
		if (!this.workLocations) return null;

		this.validateValue();

		return this.workLocations.map(({
			workLocationName : label,
			workLocation_id : value,
		}) => ({
			label,
			value,
		}));
	}

	get placeholder() {
		return this.allowAll
			? `All ${this.$dsl('Work Locations')}`
			: `${this.$dsl('Work Location')}`;
	}

	validateValue() {
		const selected = this.value;

		if (!selected) return;

		const locations = this.workLocations || [];

		const hasLocation = this.multiple && Array.isArray(selected)
			? arrayIncludes(selected, locations, (location_id, location) => location.workLocation_id === location_id)
			: locations.some(location => location.workLocation_id === selected);

		if (!hasLocation) {
			this.$emit('input', null);
			this.$emit('acres', null);
		}
	}

	select(workLocation_id) { // eslint-disable-line 
		if (!this.workLocations) return;

		if (this.multiple && Array.isArray(workLocation_id)) {
			const acres = arrayFindAll(
				workLocation_id,
				this.workLocations,
				(location_id, location) => location.workLocation_id === location_id
			)
				.map(location => location.acres);

			if (this.squish && !workLocation_id.length)
				this.$emit('input', null);
			else
				this.$emit('input', workLocation_id);
			this.$emit('acres', mathSum(...acres));

			return;
		}

		const location = this.workLocations.find(location => location.workLocation_id === workLocation_id);

		this.$emit('input', workLocation_id);
		this.$emit('acres', location && location.acres);
	}
}

Vue.component('work-location-dropdown', WorkLocationDropdown);


function arrayIncludes(must, possible, equality = null) {
	const test = equality ? equality : (a, b) => a === b;

	return must.every(item => possible.some(candidate => test(item, candidate)));
}

function arrayFindAll(match, possible, equality = (a, b) => a === b) {
	return possible.filter(candidate => match.some(key => equality(key, candidate)));
}

function mathSum(...values) {
	return values.map(Number.parseFloat)
		.filter(Boolean)
		.reduce((a, b) => a + b, 0);
}
