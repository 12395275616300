import { billing as api } from '@/api';

class BillingService {
	constructor() {
		this['#data'] = {
			billing    : null,
			wasGood    : null,
			company_id : null,
			lastCheck  : null,
		};
	}

	get isFree() {
		const account = this['#data'].billing;

		return account && account.accountType === 'free';
	}

	get isDisabled() {
		const account = this['#data'].billing;

		return account && account.accountType === 'disabled';
	}

	get isBillingIssue() {
		return !this['#data'].wasGood;
	}

	get hasAccount() {
		const account = this['#data'].billing;

		return account && account.stripe_customer_id;
	}

	get hasSubscription() {
		const account = this['#data'].billing;

		return account && account.stripe_subscription_id;
	}

	get account() {
		return this['#data'].billing;
	}

	getCheckoutUrl() {
		const account = this['#data'].billing;

		if (!account) return null;

		const company_id = account.company_id;

		// console.log('checkout_url', company_id);

		return api.checkout(company_id);
	}

	getManageUrl() {
		const account = this['#data'].billing;

		if (!account) return null;

		const company_id = account.company_id;

		return api.manage(company_id);
	}

	async checkBilling(company_id = null) {
		this._live(company_id);

		if (this['#data'].lastCheck) return !this.isBillingIssue;

		await this._readBilling();

		return !this.isBillingIssue;
	}

	async readBilling() {
		return this._readBilling();
	}

	_live(company_id) {
		const data = this['#data'];

		// console.log('verify living billing', company_id, data.company_id);

		if (company_id && data.company_id && data.company_id !== company_id)
			data.lastCheck = null;
			// console.log('Disagreed about id');

		else if (this._isStale())
			data.lastCheck = null;
			// console.log('Disagreed with age');
	}
	_isStale() {
		const data = this['#data'];

		if (!data.lastCheck) return true;

		return isDateOlderThanMinutes(data.lastCheck, 15);
	}

	async _readBilling() { // eslint-disable-line 
		const billing = await Promise.resolve(api.read());
		// const billing = await new Promise(resolve => setTimeout(() => resolve({ ok : false }), 3500));

		this['#data'].lastCheck = new Date();
		this['#data'].company_id = billing.company_id;
		this['#data'].billing = billing || null;
		this['#data'].wasGood = billing.ok;

		// console.log('Billing was checked and says', billing);

		return this['#data'].billing;
	}

	async shouldBeDisabled() {
		await this.checkBilling();

		return this.isBillingIssue;
	}
	wrapIsDisabled(yourOpinion = false) {
		if (yourOpinion == true) return false; // eslint-disable-line eqeqeq

		return this.isBillingIssue;
	}
}

export default new BillingService();


function isDateOlderThanMinutes(before, minutes = 15) {
	const now = new Date();
	const diff = now - before;

	return diff > (minutes * 60 * 1000);
}
