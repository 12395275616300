import Vue from 'vue';
import Vuex from 'vuex';
import localStorage from '../services/local-storage.service';
import sessionStorage from '../services/session-storage.service';

import apiProgress from './api-progress.storage';
import auth from './auth.storage';
import card from './card.storage';
import company from './company.storage';
import config from './config.storage';
import modal from './modal.storage';
import route from './route.storage';
import team from './team.storage';
import test from './test.storage';
import user from './user.storage';
import workZone from './work-zone.storage';

Vue.use(Vuex);

export default new Vuex.Store({
	state : {
		group : null,
	},

	getters : {
	},

	mutations : {
	},

	actions : {
		reset({ commit }) {
			localStorage.clear();
			sessionStorage.clear();

			commit({ type : 'user/set' });
			commit({ type : 'auth/set' });
			commit({ type : 'company/set' });
			commit({ type : 'config/set' });
			commit({ type : 'route/clear' });
			commit({ type : 'workZone/set' });
		},
	},

	modules : {
		apiProgress,
		auth,
		card,
		company,
		config,
		modal,
		route,
		team,
		test,
		user,
		workZone,
	},
});
