import { BillingSettings as component } from './billing-settings';

export default {
	component,
	path : 'billing-settings',
	name : 'billing-settings',
	meta : {
		title       : 'Billing Settings',
		secure      : true,
		skipBilling : true,
	},
};
