import component from './onboarding';

export default {
	component,
	path : '/onboarding',
	name : 'onboarding',
	meta : {
		title       : 'Onboarding',
		secure      : false,
		skipBilling : true,
	},
};
