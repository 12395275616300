const component = () => import(/* webpackChunkName: "tc-pages" */'./create-timecard');

export default {
	component,
	path : '/new-timecard',
	name : 'create-timecard',
	meta : {
		title  : 'Create Timecard',
		secure : true,
	},
};
