import Vue from 'vue';
import Component from 'vue-class-component';

const props = {
	value : Boolean, // whether the menu is open
};

@Component({
	template : '<div v-if="value" class="scrim" @click.stop="$emit(\'input\', !value)"></div>',
	props,
})
export class MenuScrim extends Vue {}

Vue.component('menu-scrim', MenuScrim);
