import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-location-table.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { DeleteIconButton } from '@/buttons/delete-icon-button/delete-icon-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { NumericInput } from '@/inputs/numeric-input/numeric-input';
import { SortableTable } from '@/components/sortable-table/sortable-table';
import { Spinner } from '@/base-components/spinner/spinner';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TableColumn } from '@/components/table-column/table-column';
import { TableStatusMessage } from '@/components/table-status-message/table-status-message';
import { TextInput } from '@/inputs/text-input/text-input';
import { WorkLocationInputRow } from '@/components/work-location-input-row/work-location-input-row';

const components = {
	CancelButton,
	DeleteIconButton,
	ErrorDisplay,
	NumericInput,
	SortableTable,
	Spinner,
	SubmitButton,
	TableColumn,
	TableStatusMessage,
	TextInput,
	WorkLocationInputRow,
};
const props = {
	workZone : {
		type     : Object, // WorkZone record
		required : true,
	},
};

@Component({
	template,
	components,
	props,
})
export class WorkLocationTable extends Vue {
	data() {
		return {
			error    : null,
			selected : null,
		};
	}

	@AsyncLoading
	get list() {
		const { workZone_id } = this.workZone;

		return api.workLocation.list({ workZone_id });
	}

	editing({ workLocation_id }) {
		return this.selected && this.selected.workLocation_id === workLocation_id;
	}

	deleteWorkLocation({ workLocation_id }) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;
		this.selected = null;

		const index = this.list.findIndex(workLocation => workLocation_id === workLocation.workLocation_id);

		return api.workLocation.delete(workLocation_id)
			.then(() => { this.list.splice(index, 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	submit(workLocation) {
		if (this.loading) return;
		if (!workLocation.workLocationName)
			return;

		this.loading = true;
		this.error = null;

		const index = this.list.indexOf(this.selected);

		return api.workLocation.save(workLocation)
			.then(workLocation => { this.list.splice(index, 1, workLocation) })
			.then(() => { this.selected = null })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-location-table', WorkLocationTable);
