import Vue from 'vue';
import Component from 'vue-class-component';
import template from './date-range.html';
import Watch from '@/plugins/watch-decorator';
import { Spinner } from '@/base-components/spinner/spinner';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { DaterangePicker } from '@/components/daterange-picker/daterange-picker';

const components = {
	Spinner,
	DaterangePicker,
	BasicButton,
};
const props = {
	value : Object, // filters object, { startDate, endDate, ... }
	show  : Boolean, // currently showing filter controls
};

@Component({
	template,
	components,
	props,
})
export default class DateRangeFilter extends Vue {
	data() {
		return {
			filters : null,
		};
	}

	get dateRangeLabel() {
		const { startDate, endDate } = this.filters;

		const language = navigator.userLanguage || navigator.language;

		const formattedStartDate = new Date(startDate).toLocaleDateString(language, { dateStyle : 'medium' });
		const formattedEndDate = new Date(endDate).toLocaleDateString(language, { dateStyle : 'medium' });

		if (startDate && endDate)
			return `Between ${formattedStartDate} and ${formattedEndDate}`;

		if (startDate)
			return `Since ${formattedStartDate}`;

		if (endDate)
			return `Before ${formattedEndDate}`;
	}

	@Watch.immediate('value')
	watchValue() {
		if (!this.value) return;

		this.filters = {
			startDate : this.value.startDate,
			endDate   : this.value.endDate,
		};
	}

	@Watch('filters')
	watchFilters() {
		this.$emit('input', this.filters);
	}
}
