import Vue from 'vue';
import Component from 'vue-class-component';

const props = {
	value : Object,
};

@Component({
	template : '<span class="debug"><slot><pre><code>{{ value | JSON }}</code></pre></slot></span>',
	props,
})
export class Debug extends Vue {}

Vue.component('debug', Debug);
