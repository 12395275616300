import Vue from 'vue';
import Component from 'vue-class-component';
import template from './delete-icon-button.html';
import { IconButton } from '@/buttons/icon-button/icon-button';
const components = {
	IconButton,
};
const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
	compact  : Boolean,
	danger   : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class DeleteIconButton extends Vue {}
