import store from '@/storage';
import properCase from '@/filters/proper-case.filter';
import camelCase from '@/filters/camel-case.filter';

export default {
	set(key, value) {
		const list = [
			key,
			`${key}/set`,
			`${camelCase(key)}/set`,
			`set${properCase(key).replace(/ /g, '')}`,
		];

		const type = list.find(type => store._actions[type]);

		if (!type) throw new Error(`Storage Service - could not find a setter for ${key}`);

		return this.get(key)
			.catch(() => null)
			.then(() => store.dispatch({
				type,
				[key]            : value,
				[camelCase(key)] : value,
			}))
			.then(() => value);
	},

	get(key) {
		const list = [
			key,
			`${key}/${key}`,
			`${key}/${camelCase(key)}`,
			`${camelCase(key)}/${camelCase(key)}`,
			`${key}/get`,
			`get${properCase(key).replace(/ /g, '')}`,
		];

		const type = list.find(type => typeof store.getters[type] !== 'undefined');

		if (!type) throw new Error(`Storage Service - could not find a getter for ${key}`);

		return Promise.resolve(store.getters[type] || Promise.reject(null));
	},

	increment(key) {
		const type = `${key}/increment`;

		if (!store._actions[type]) throw new Error(`Storage Service - could not find a incrementer for ${key}`);

		return store.dispatch({ type });
	},

	decrement(key) {
		const type = `${key}/decrement`;

		if (!store._actions[type]) throw new Error(`Storage Service - could not find a decrementer for ${key}`);

		return store.dispatch({ type });
	},

	list(key, flag, list) {
		const type = [
			`${key}/list`,
			`${camelCase(key)}/list`,
			`list${properCase(key).replace(/ /g, '')}`,
		].find(type => typeof store.getters[type] !== 'undefined');

		if (typeof list === 'object' && !store._actions[type])
			return Promise.reject(`No list setter for ${key}.`);

		return typeof list === 'object'
			? store.dispatch({
				type,
				flag,
				list,
			})
				.then(() => list)
			: Promise.resolve(store.getters[type](flag) || null);
	},

	toggle(key) {
		return new Promise(resolve => {
			store.dispatch({
				type : `${key}/toggle`,
				resolve,
			});
		});
	},

	show(key) {
		return new Promise(resolve => {
			store.dispatch({
				type : `${key}/show`,
				resolve,
			});
		});
	},

	hide(key) {
		return new Promise(resolve => {
			store.dispatch({
				type : `${key}/hide`,
				resolve,
			});
		});
	},

	push(key, value) {
		return new Promise((resolve, reject) => {
			store.dispatch({
				type  : `${key}/push`,
				[key] : Object.assign(value, {
					resolve,
					reject,
				}),
			});
		});
	},

	pop(key) {
		return store.dispatch({
			type : `${key}/pop`,
		});
	},

	resolve(key, value) {
		return store.dispatch({
			type : `${key}/resolve`,
			value,
		});
	},

	reject(key, value) {
		return store.dispatch({
			type : `${key}/reject`,
			value,
		});
	},

	clear(key) {
		return store.dispatch({
			type : `${key}/clear`,
		});
	},

	reset() {
		return store.dispatch({
			type : 'reset',
		});
	},
};
