import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-code.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	BasicButton,
};
const props = {
	value : Object, // dateRange object, { startDate, endDate, ... }
};

@Component({
	template,
	components,
	props,
})
export default class WorkCodeFilter extends Vue {
	@AsyncLoading
	get workCodes() {
		return api.workCode.list()
			.then(list => {
				this.ready();

				return list;
			});
	}

	async ready() {
		await this.$nextTick();
		this.$emit('ready');
	}

	get workCodeLabel() {
		if (!this.value || !this.value.workCode_id || !this.workCodes) return;

		const { workCode_id } = this.value;

		const workCode = this.workCodes.find(workCode => workCode.workCode_id === workCode_id);

		if (workCode) return workCode.label;
	}
}
