import Vue from 'vue';
import Component from 'vue-class-component';
import template from './table-filters-display.html';

import DateRangeFilter from './filters/date-range/date-range';
import EmployeeFilter from './filters/employee/employee';
import LocationFilter from './filters/location/location';
import WorkCodeFilter from './filters/work-code/work-code';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	DateRangeFilter,
	EmployeeFilter,
	LocationFilter,
	WorkCodeFilter,
	Spinner,
};
const props = {
	filters      : Object,
	showLocation : Boolean,
	showEmployee : Boolean,
	showWorkCode : Boolean,
};

@Component({
	template,
	components,
	props,
})
export default class TableFilters extends Vue {
	data() {
		return {
			readyCount : {},
		};
	}

	get visibleProps() {
		return [
			'workZone_id',
			'startDate',
			'endDate',
			this.showLocation && 'workLocation_id',
			this.showEmployee && 'employee_id',
			this.showWorkCode && 'workCode_id',
		].filter(Boolean);
	}

	get readyMax() {
		return [
			this.showLocation && 'workLocation_id',
			this.showEmployee && 'employee_id',
			this.showWorkCode && 'workCode_id',
		].filter(Boolean).length;
	}

	async ready(scope) {
		this.readyCount[scope] = true;

		const ready = Object.keys(this.readyCount).length;

		if (ready >= this.readyMax) {
			await this.$nextTick();
			this.$emit('ready');
		}
	}
}
