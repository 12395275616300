import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-code-table.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { DeleteIconButton } from '@/buttons/delete-icon-button/delete-icon-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { SortableTable } from '@/components/sortable-table/sortable-table';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TableColumn } from '@/components/table-column/table-column';
import { TableStatusMessage } from '@/components/table-status-message/table-status-message';
import { TextInput } from '@/inputs/text-input/text-input';
import { WorkCodeInputRow } from '@/components/work-code-input-row/work-code-input-row';

const components = {
	CancelButton,
	DeleteIconButton,
	ErrorDisplay,
	SortableTable,
	Spinner,
	StatusMessage,
	SubmitButton,
	TableColumn,
	TableStatusMessage,
	TextInput,
	WorkCodeInputRow,
};

@Component({
	template,
	components,
})
export class WorkCodeTable extends Vue {
	data() {
		return {
			error    : null,
			loading  : null,
			selected : null,
		};
	}

	@Async
	get list() {
		return api.workCode.list();
	}

	editing({ workCode_id }) {
		return this.selected && this.selected.workCode_id === workCode_id;
	}

	deleteWorkCode({ workCode_id }) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;
		this.selected = null;

		const index = this.list.findIndex(workCode => workCode_id === workCode.workCode_id);

		return api.workCode.delete(workCode_id)
			.then(() => { this.list.splice(index, 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	submit(workCode) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		const index = this.list.indexOf(this.selected);

		return api.workCode.save(workCode)
			.then(workCode => { this.list.splice(index, 1, workCode) })
			.then(() => { this.selected = null })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-code-table', WorkCodeTable);
