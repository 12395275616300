import Vue from 'vue';
import Component from 'vue-class-component';
import template from './billing-settings.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';

import api from '@/api';
import billingService from '@/services/billing.service';

import { BasicButton } from '@/base-components/basic-button/basic-button.js';
import { BasicInput } from '@/base-components/basic-input/basic-input.js';
import { MessageDisplay } from '@/base-components/message-display/message-display.js';
import { SubmitButton } from '@/base-components/submit-button/submit-button.js';

const components = {
	BasicButton,
	BasicInput,
	MessageDisplay,
	SubmitButton,
};

@Component({
	template,
	components,
})
export class BillingSettings extends Vue {
	data() {
		return {
			error   : null,
			loading : false,
			saving  : false,
			service : billingService,

			newlyAccount : false,

			form : {
				email : null,
				name  : null,
				phone : null,
			},

			urls : {
				checkout : null,
				billing  : null,
			},
		};
	}

	mounted() {
		this.loadPage();
	}

	@Watch(['company', 'user'])
	async loadPage() { // eslint-disable-line 
		if (this.loading) return;
		const started = Date.now();

		try {
			this.error = null;
			this.loading = true;

			await billingService.readBilling();
			await api.user.get();

			this.urls.checkout = billingService.getCheckoutUrl();
			this.urls.billing = billingService.getManageUrl();
			this.form.email = this.user.email;
			this.form.name = this.user.name;
			this.form.phone = this.user.phone;
		}
		catch (error) {
			this.error = error;
		}
		finally {
			// Just a funky little loading delay.
			// -AM Dec 19, 2023
			const delay = 800;
			const duration = Date.now() - started;

			if (duration < delay)
				setTimeout(() => { this.loading = false }, delay - duration);
			else
				this.loading = false;
		}
	}

	/*  */

	@Async()
	get user() {
		return api.user.get();
	}

	@Async
	get company() {
		return api.company.get();
	}

	get account() {
		if (this.loading) return;

		return billingService.account;
	}

	/*  */

	get rootClasslist() {
		return [
			this.isFree && 'r-is-free',
			this.hasAccount ? 'r-has-account' : 'r-need-account',
			this.hasSubscription ? 'r-has-sub' : 'r-need-sub',
		].filter(Boolean);
	}

	/*  */

	get isFree() {
		if (this.loading) return;

		return billingService.isFree;
	}
	get isDisabled() {
		if (this.loading) return;

		return billingService.isDisabled;
	}
	get hasAccount() {
		if (this.loading) return;

		return billingService.hasAccount;
	}
	get hasSubscription() {
		if (this.loading) return;

		return billingService.hasSubscription;
	}

	async submitNewAccount() {
		this.saving = true;
		this.error = null;

		try {
			await api.billing.create(this.form);
			this.loading = true;
			this.newlyAccount = true;
			await billingService.readBilling();
		}
		catch (error) {
			this.error = error;
		}
		finally {
			this.saving = false;
			this.loading = false;
		}
	}
}
