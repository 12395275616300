const component = () => import(/* webpackChunkName: "settings-pages" */'./work-locations');
import dsl from '@/services/dsl.service';

export default {
	component,
	path : 'work-locations',
	name : 'work-locations',
	meta : {
		title           : `${dsl('Work Locations')}`,
		secure          : true,
		requireCompany  : true,
		requireWorkZone : true,
	},
};
