import Vue from 'vue';
import Component from 'vue-class-component';
import template from './accordion-layout.html';
import AccordionHeader from './accordion-header/accordion-header';
import Watch from '@/plugins/watch-decorator';

const components = {
	AccordionHeader,
};

const props = {
	value : Boolean, // whether the accordion is open
};

@Component({
	template,
	components,
	props,
})
export class AccordionLayout extends Vue {
	data() {
		const open = this.$attrs.hasOwnProperty('open') && this.$attrs.open !== 'false';
		// Boolean attributes are actually empty strings, leaving naive checks useless.
		// This method checks that the attribute exists and is not explicitly false.
		// --AM 6/24/19

		return {
			open : this.value || open || false,
		};
	}

	@Watch('value')
	watchValue() {
		this.open = this.value;
	}

	@Watch('open')
	watchOpen() {
		this.$emit('input', this.open);
	}
}
