import api from './api';
import storage from '@/services/storage.service';
import user from './user';

const { User } = user;

export default {
	get,
	login,
	logout,
	register,
	checkAvailability,
};

function get() {
	return api.read('session');
}

function login(credentials) {
	return api.create('session', credentials)
		.then(user => new User(user))
		.then(user => {
			if (user) {
				storage.set('user', user);
				storage.set('auth', user.auth);
			}

			return user;
		});
}

function logout() {
	return api.destroy('session')
		.catch(() => null)
		.then(() => storage.reset());
}

function register(credentials) {
	return api.create('registration', credentials)
		.then(() => storage.reset())
		.then(() => login(credentials));
}

function checkAvailability(email) {
	return api.list('registration', { email });
}
