import Vue from 'vue';
import Component from 'vue-class-component';
import template from './progress-bar.html';

const props = {
	value : Number, // [0..1]
};

@Component({
	template,
	props,
})
export class ProgressBar extends Vue {
	get percent() {
		const hundred = 100;
		const percent = this.value * hundred;

		if (this.value > 1) return hundred;

		if (this.value > 0) return percent;

		return 0;
	}

	get width() {
		return `${this.percent}%`;
	}

	get style() {
		return {
			width : this.width,
		};
	}
}

Vue.component('progress-bar', ProgressBar);
