import localStorage from '@/services/local-storage.service';

export default {
	namespaced : true,

	state : {
		workZone : null,

		list : null,
		flag : null,
	},

	getters : {
		workZone(state) {
			if (!state.workZone)
				state.workZone = localStorage.get('work-zone');

			return state.workZone;
		},

		list(state) {
			return flag => !state.flag || state.flag === flag ? state.list : null;
		},
	},

	mutations : {
		set(state, { workZone }) {
			state.workZone = workZone || null;

			localStorage.set('work-zone', state.workZone);

			if (!state.workZone) {
				state.list = null;
				state.flag = null;
			}

			return state.workZone;
		},

		list(state, { list, flag }) {
			if (flag && !/^company_id=/.test(flag)) return list;

			state.list = list || null;
			state.flag = flag || null;

			return state.list;
		},

		clear(state) {
			state.workZone = null;
			state.list = null;
			state.flag = null;

			localStorage.set('work-zone', null);

			return null;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},

		list({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('list', payload));
		},

		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};
