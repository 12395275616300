import Vue from 'vue';
import Component from 'vue-class-component';
import template from './status.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { DestructoButton } from '@/buttons/destructo-button/destructo-button';
import { Spinner } from '@/base-components/spinner/spinner';
import { WorkOrderStatusDropdown } from '@/components/work-order-status-dropdown/work-order-status-dropdown';

const components = {
	DestructoButton,
	Spinner,
	WorkOrderStatusDropdown,
};
const props = {
	value : Object, // dateRange object, { startDate, endDate, ... }
	show  : Boolean, // currently showing filter controls
};

@Component({
	template,
	components,
	props,
})
export default class WorkOrderStatusFilter extends Vue {
	data() {
		return {
			filters : null,
		};
	}

	@AsyncLoading
	get statuses() {
		return api.workOrderStatus.list();
	}

	get statusLabel() {
		const { status : value } = this.filters;

		if (!value || !this.statuses) return;

		const status = this.statuses.find(status => status.value === value);

		if (status) return status.label;
	}

	@Watch.immediate('value')
	watchValue() {
		if (!this.value) return;

		this.filters = {
			status : this.value.status,
		};
	}

	@Watch('filters')
	watchFilters() {
		this.$emit('input', this.filters);
	}

	resetStatus() {
		this.$emit('clear', {
			status : null,
		});
	}
}
