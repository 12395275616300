export default {
	generateAvatar,
};

function generateAvatar({ color, icon, label } = {}) {
	return {
		color,
		icon,
		label : /\W|\w{4}/.test(label) ? generateInitials(label) : label,
	};
}

function generateInitials(label) {
	return label && label.replace(/@.*|\W/g, ' ')
		.replace(/(\w)\S+\s*/g, '$1')
		.replace(/\W/g, '');
}
