const component = () => import(/* webpackChunkName: "test-pages" */'./workorder-generator');

export default {
	component,
	path : 'workorder-generator',
	name : 'workorder-generator-test',
	meta : {
		title  : 'Workorder Generator Test',
		secure : false,
	},
};
