import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-input-row.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

const props = {
	filters : {
		type    : Object,
		default : () => ({}),
	},
};

@Component({
	template,
	props,
})
export class WorkOrderInputRow extends Vue {
	data() {
		return {
			workOrder : null,
			error     : null,
			loading   : null,
		};
	}

	get colspan() {
		return [{
			key : 'date',
		}, {
			key : 'workLocation_id',
		}, {
			key : 'employee_id',
		}, {
			key : 'workCode_id',
		}, {
			key : 'status',
		}, {
			key  : 'description',
			keep : true,
		}, {
			key  : 'hours',
			keep : true,
		}].filter(column => column.keep || !this.filters[column.key]).length;
	}

	@Watch('workOrder')
	watchWorkOrder() {
		this.error = null;
	}

	startWorkOrder() {
		this.workOrder = new api.workOrder.WorkOrder({
			workLocation_id : this.filters.workLocation_id,
			employee_id     : this.filters.employee_id,
			workCode_id     : this.filters.workCode_id,
			status          : this.filters.status,
		});
	}

	saveWorkOrder() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		return api.workOrder.save(this.workOrder)
			.then(({ workOrder_id }) => api.workOrder.read(workOrder_id))
			.then(workOrder => { this.$emit('input', workOrder) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-order-input-row', WorkOrderInputRow);
