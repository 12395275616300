import Vue from 'vue';
import Component from 'vue-class-component';
import template from './radio-set-input.html';

const props = {
	value      : String,
	label      : String,
	horizontal : Boolean, // render the radios in a row instead of column
};

@Component({
	template,
	props,
})
export class RadioSetInput extends Vue {}

Vue.component('radio-set-input', RadioSetInput);
