const component = () => import(/* webpackChunkName: "test-pages" */'./weekly-calendar');

export default {
	component,
	path : 'weekly-calendar',
	name : 'weekly-calendar-test',
	meta : {
		title  : 'Weekly Calendar Test',
		secure : false,
	},
};
