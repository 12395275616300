import Vue from 'vue';
import Component from 'vue-class-component';
import template from './logout.html';
import api from '@/api';

@Component({ template })
export default class Logout extends Vue {
	created() {
		api.session.logout()
			.then(() => { this.$router.replace('login') });
	}
}
