import Vue from 'vue';
import Component from 'vue-class-component';
import template from './hours-summary.html';

const props = {
	summary : Object,
	colspan : Number,
};

@Component({
	template,
	props,
})
export default class HoursSummary extends Vue {
	get reg() {
		return col(this.summary, 'regularHours');
	}

	get ot() {
		return col(this.summary, 'overtimeHours');
	}

	get dt() {
		return col(this.summary, 'doubletimeHours');
	}

	get holiday() {
		return col(this.summary, 'holidayHours');
	}

	get sick() {
		return col(this.summary, 'sickHours');
	}

	get quarantine() {
		return col(this.summary, 'quarantineHours');
	}

	get cols() {
		const displayCols = 6;

		if (!this.colspan) return 13;

		return this.colspan - displayCols;
	}
}

function col(source, keyprop, fallback = 0) {
	const value = Number.parseFloat(source && source[keyprop], 10);

	return Number.isNaN(value) ? fallback : value;
}
