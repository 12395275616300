import Vue from 'vue';
import service from '@/services/dsl.service';

export default function dsl(value) {
	if (!value) return value;

	return service(value);
}

Vue.filter('dsl', dsl);
