import Vue from 'vue';
import Component from 'vue-class-component';
import template from './table-filters.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import storage from '@/services/session-storage.service';

import FilterButton from './buttons/filter-button/filter-button';
import DateRangeFilter from './filters/date-range/date-range';
import EmployeeFilter from './filters/employee/employee';
import LocationFilter from './filters/location/location';
import StatusFilter from './filters/status/status';
import TypeFilter from './filters/type/type';
import WorkCodeFilter from './filters/work-code/work-code';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { Spinner } from '@/base-components/spinner/spinner';
import { SubmitButton } from '@/base-components/submit-button/submit-button';

const components = {
	FilterButton,
	DateRangeFilter,
	EmployeeFilter,
	LocationFilter,
	StatusFilter,
	TypeFilter,
	WorkCodeFilter,
	BasicButton,
	Spinner,
	SubmitButton,
};
const defaultFilterName = 'saved-filters';
const props = {
	showLocation : Boolean,
	showEmployee : Boolean,
	showPoType   : Boolean,
	showWoStatus : Boolean,
	showWorkCode : Boolean,
	saveAs       : {
		type    : String,
		default : defaultFilterName,
	},
};

@Component({
	template,
	components,
	props,
})
export class TableFilters extends Vue {
	data() {
		return {
			show    : null, // whether to show the filters section
			filters : this.getDefaultFilters(),
		};
	}

	mounted() {
		this.submit();
	}

	@Async
	get workZone() {
		return api.workZone.get();
	}

	@Watch('workZone')
	watchWorkZone() {
		const { workZone_id } = this.workZone;

		this.updateFilter({ workZone_id });
		this.submit();
	}

	getDefaultFilters() {
		const twoWeeks = 14;
		const endDate = new Date();
		const startDate = new Date();
		const filters = this.loadFilters();

		endDate.setHours(0, 0, 0, 0);
		startDate.setHours(0, 0, 0, 0);
		startDate.setDate(endDate.getDate() - twoWeeks);

		return Object.assign({
			workZone_id     : this.workZone && this.workZone.workZone_id,
			employee_id     : null,
			workLocation_id : null,
			workCode_id     : null,
			status          : null,
			type            : null,
			startDate,
			endDate,
		}, filters);
	}

	reset() {
		storage.set(this.saveAs || defaultFilterName, null);

		this.filters = this.getDefaultFilters();

		this.submit();
	}

	get visibleProps() {
		return [
			'workZone_id',
			'startDate',
			'endDate',
			this.showLocation && 'workLocation_id',
			this.showEmployee && 'employee_id',
			this.showPoType && 'type',
			this.showWoStatus && 'status',
			this.showWorkCode && 'workCode_id',
		].filter(Boolean);
	}

	submit() {
		this.show = false;

		if (!this.filters.workZone_id) return;

		const value = limit(this.filters, this.visibleProps);

		this.$emit('filter', value);

		this.saveFilters();
	}

	updateFilter(filter) {
		Object.assign(this.filters, filter);
	}

	clearFilter(filter) {
		Object.assign(this.filters, filter);

		this.submit();
	}

	saveFilters() {
		storage.set(this.saveAs || defaultFilterName, JSON.stringify({
			startDate : this.filters.startDate,
			endDate   : this.filters.endDate,
			type      : this.filters.type,
		}));
	}

	loadFilters() {
		const filters = JSON.parse(storage.get(this.saveAs || defaultFilterName));

		if (!filters) return filters;

		if (filters.startDate) filters.startDate = new Date(filters.startDate);
		if (filters.endDate) filters.endDate = new Date(filters.endDate);

		return filters;
	}
}

Vue.component('table-filters', TableFilters);


function limit(record, columns) {
	return Object.keys(record)
		.filter(key => columns.includes(key))
		.reduce((next, key) => Object.assign(next, { [key] : record[key] }), {});
}
