import Vue from 'vue';
import Component from 'vue-class-component';
import template from './sortable-table.html';
import sort from '@/services/sorting.service';

const props = {
	value : Object, // the selected row, if any
	list  : Array, // table body data

	clickable : Boolean, // whether rows are clickable; events are emitted regardless, but styling won't be applied
};

@Component({
	template,
	props,
})
export class SortableTable extends Vue {
	data() {
		return {
			columns   : [],
			column_id : null, // sort column ("-column_id" for reverse sort)
		};
	}

	get hasHead() {
		return this.columns.some(({ label }) => label);
	}

	get column() {
		return this.columns.find(({ column_id }) => column_id === this.column_id || `-${column_id}` === this.column_id);
	}

	get rows() {
		const rows = this.list.map(value => value);
		const column = this.column;

		if (column) {
			const order = this.column_id === column.column_id ? 1 : -1;

			rows.sort((one, two) => order * sort(one, two, column.sortable));
		}

		return rows;
	}

	sort({ sortable, column_id }) {
		if (!sortable) return;

		this.column_id = this.column_id === column_id ? `-${column_id}` : column_id;
	}

	classlist(row) {
		const base = {
			clickable : this.clickable,
			active    : this.value === row,
		};

		if (typeof row['.class'] === 'object') return Object.assign(base, row['.class']);

		return base;
	}
}

Vue.component('sortable-table', SortableTable);
