export default sort;

function sort(one, two, order = null) { // eslint-disable-line complexity
	if (Array.isArray(order)) return order.reduce((result, prop) => result || sort(one, two, prop), 0);

	// sort by specified property
	// with '-' prefix reversing the sort order
	if (typeof order === 'string')
		return /^-/.test(order)
			? sort(two, one, order.substr(1))
			: _sort(one[order] || '', two[order] || '');

	// custom sort-key extraction method
	// with optional custom sorting method
	if (typeof order === 'object') {
		const { extract, compare } = order;

		if (!extract) throw new Error('sort with object order expects an `extract` property');

		return compare
			? compare(extract(one), extract(two))
			: _sort(extract(one), extract(two));
	}

	// custom sort-key extraction method
	if (typeof order === 'function')
		return _sort(order(one), order(two));

	// boring sorting for strings
	return _sort(one, two);
}


function _sort(one, two) {
	const arrOne = one.toLowerCase()
		.split(/(\d+)/)
		.filter(string => string.length);
	const arrTwo = two.toLowerCase()
		.split(/(\d+)/)
		.filter(string => string.length);

	return arrOne.reduce((result, word, index) => result || compare(word, arrTwo[index]), 0) || arrOne.length - arrTwo.length;

	function compare(one, two) {
		const regex = /^\d+$/;

		if (regex.test(one) && regex.test(two)) return Number(one) - Number(two);

		return one.localeCompare(two);
	}
}
