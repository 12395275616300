import Vue from 'vue';
import Component from 'vue-class-component';
import template from './date-picker.html';
import NativeDatePicker from './components/native-date-picker';
import DesktopDatePicker from './components/desktop-date-picker';

const props = {
	value : String,
	// step     : [Number, String],
	// min      : String,
	// max      : String,
	// required : Boolean,
	// disabled : Boolean,
};

const components = {
	NativeDatePicker,
	DesktopDatePicker,
};

@Component({
	template,
	props,
	components,
	inheritAttrs : false,
})
export class DatePicker extends Vue {
	get isMobile() {
		if (this.$attrs.native === '')
			return true;

		if ('$isMobile' in this)
			return this.$isMobile;

		return /mobile|android/i.test(navigator.userAgent || navigator.vendor || window.opera);
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			input  : () => null,
			change : () => null,
		});
	}
}

Vue.component('date-picker', DatePicker);
