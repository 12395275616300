import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-accordion-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

import { AccordionLayout } from '@/layouts/accordion-layout/accordion-layout';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusDisplay } from '@/fragments/status-display';

const components = {
	AccordionLayout,
	Spinner,
	StatusDisplay,
};

@Component({
	template,
	components,
})
export class CompanyAccordionList extends Vue {
	@Async
	get list() {
		return api.company.list();
	}
}

Vue.component('company-accordion-list', CompanyAccordionList);
