import Vue from 'vue';
import Component from 'vue-class-component';
import template from './quantity-units-dropdown.html';

const props = {
	value : String,
};

@Component({
	template,
	props,
})
export class QuantityUnitsDropdown extends Vue {
	data() {
		return {
		};
	}

	get list() {
		return [{
			label : 'Hours',
			value : 'hours',
		}, {
			label : 'Acres',
			value : 'acres',
		}];
	}
}

Vue.component('quantity-units-dropdown', QuantityUnitsDropdown);
