import Vue from 'vue';

export default function titleCase(value) {
	if (!value) return value;

	const exceptions = [
		/* articles */
		'a', 'an', 'the',

		/* conjunctions */
		'for', 'and', 'nor', 'but', 'or', 'yet', 'so',

		/* prepositions */
		'about', 'above', 'across', 'against', 'along', 'among', 'around', 'at',
		'before', 'behind', 'below', 'beneath', 'beside', 'between', 'beyond', 'by',
		'down', 'during', 'except', 'for', 'from', 'in', 'inside', 'into', 'like', 'near',
		'of', 'off', 'on', 'since', 'than', 'through', 'to', 'toward',
		'under', 'until', 'up', 'upon', 'with', 'within',
	];
	const exceptionsString = `\\b(${exceptions.join('|')})\\b`;
	const exceptionsRegex = new RegExp(exceptionsString, 'gi');

	return `${value}`
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
		.replace(/([^\w-])([\w-])/g, '$1 $2')
		.replace(/[ _]+/g, ' ')
		.replace(/(?:[A-Z])\w+/g, match => match.toLowerCase())
		.replace(/(^|\s|-)\w/g, match => match.toUpperCase())
		.replace(exceptionsRegex, match => match.toLowerCase())
		.replace(/(^|: )./g, match => match.toUpperCase());
}

Vue.filter('title-case', titleCase);
