import Vue from 'vue';
import Component from 'vue-class-component';
import template from './destructo-button.html';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	BasicButton,
	Spinner,
};
const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	components,
	props,
})
export class DestructoButton extends Vue {}
