import Vue from 'vue';
import Buefy from 'buefy';
import Component from 'vue-class-component';

Vue.use(Buefy, {
	defaultIconPack : 'far',
});

// Hooks from https://router.vuejs.org/en/advanced/navigation-guards.html
Component.registerHooks([
	'beforeRouteEnter',
	'beforeRouteUpdate',
	'beforeRouteLeave',
]);

import './config/custom-locales';
import './components';
import './directives';
import './filters';
import './fragments';
import './icons';
import './inputs';

import dslService from '@/services/dsl.service';
import billingService from './services/billing.service';
import router from './views';
import store from './storage';

const agent = navigator.userAgent || navigator.vendor || window.opera;

Vue.prototype.$dsl = dslService; // eslint-disable-line id-match
Vue.prototype.$isMobile = /mobile|android/i.test(agent); // eslint-disable-line id-match
Vue.prototype.$billing = billingService; // eslint-disable-line id-match

export const app = new Vue({
	router,
	store,
	el : '#app',
});
