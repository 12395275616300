const component = () => import(/* webpackChunkName: "tc-pages" */'./create-timesheet');

export default {
	component,
	path : '/new-timesheet',
	name : 'create-timesheet',
	meta : {
		title  : 'Create Timesheet',
		secure : true,
	},
};
