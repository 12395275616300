import Vue from 'vue';

export default function truncate(value, length = 20) {
	if (typeof value !== 'string') return value;
	if (value.length <= length) return value;

	const before = value.substring(0, findNaturalBreak(value, length));
	const after = '...';

	return `${before}${after}`;
}

Vue.filter('truncate', truncate);

function findNaturalBreak(value, hardBreak = 255, wRange = 5) {
	const chunks = value.substring(hardBreak - wRange, hardBreak + wRange)
		.split(/(?=\w\b)/g)
		.map(sv => sv.length);

	let naturalBreak = 1 + (hardBreak - wRange);

	for (const chunkLength of chunks) {
		const nextBreak = naturalBreak + chunkLength;

		if (nextBreak > hardBreak) {
			if (nextBreak < (hardBreak + wRange)) return nextBreak;
			if (naturalBreak > (hardBreak - wRange)) return naturalBreak;

			return hardBreak;
		}

		naturalBreak = nextBreak;
	}

	return hardBreak;
}
