import Vue from 'vue';
import Component from 'vue-class-component';
import template from './textarea-input.html';

const props = {
	value       : String,
	label       : String,
	message     : String,
	messageType : String, // ['is-danger', 'is-info', etc]
};

@Component({
	template,
	props,
})
export class TextareaInput extends Vue {}

Vue.component('textarea-input', TextareaInput);
