const component = () => import(/* webpackChunkName: "tc-pages" */'./edit-timesheet');

export default {
	component,
	path  : '/edit-timesheet/:employee_id/:week',
	name  : 'edit-timesheet',
	props : true,
	meta  : {
		title  : 'Edit Timesheet',
		secure : true,
	},
};
