import Vue from 'vue';
import Component from 'vue-class-component';
import api from '@/api';

const labels = api.workOrderStatus.listSync() // eslint-disable-line no-sync
	.reduce((acc, cur) => {
		acc[cur.value] = cur.label;

		return acc;
	}, {});

const props = {
	value : String,
};

@Component({
	template : '<span>{{ display }}</span>',
	props,
})
export class StatusDisplay extends Vue {
	get display() {
		return labels[this.value] || 'Planned';
	}
}

Vue.component('status-display', StatusDisplay);
