import Vue from 'vue';
import { createDecorator } from 'vue-class-component';

import asyncDecorator from './async-decorator';

export default AsyncLoadingDecorator;

function AsyncLoadingDecorator(model, key, { deep = true } = {}) {
	const isVue = model instanceof Vue;

	const defaultValue = isVue ? null : model;
	const watchers = isVue || !key ? [] : [].concat(key);

	return isVue
		? decorator(defaultValue, watchers, { deep })(model, key)
		: decorator(defaultValue, watchers, { deep });
}

function decorator(defaultValue, watchers, { deep }) {
	return (model, key) => {
		wrapGetter()(model, key);
		asyncDecorator(defaultValue, watchers, { deep })(model, key);
	};
}

function wrapGetter() {
	return createDecorator((options, key) => {
		const computed = options.computed || {};
		const optionsData = options.data;

		if (!computed[key] || !computed[key].get) throw new Error('@AsyncLoading must be attached to a getter');

		const getter = computed[key].get;

		computed[key].get = function get() {
			return new Promise(resolve => {
				//
				// Attempting to read and write `loading` here would cause an infinite loop,
				// so we do the reading & writing on an unwatch, private property,
				// and then only write to the watched `loading` property.
				//
				// -CH 2018-10-03
				//

				this._loading = this._loading || 0;
				this.loading = ++this._loading;

				resolve(getter.call(this));
			})
				.finally(() => {
					setTimeout(() => {
						this.loading = --this._loading;
					});
				});
		};

		options.data = function data() {
			const dataObject = optionsData ? optionsData.call(this) : {};

			dataObject.loading = null;

			return dataObject;
		};
	});
}
