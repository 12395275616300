import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-code.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { DestructoButton } from '@/buttons/destructo-button/destructo-button';
import { Spinner } from '@/base-components/spinner/spinner';
import { WorkCodeDropdown } from '@/components/work-code-dropdown/work-code-dropdown';

const components = {
	DestructoButton,
	Spinner,
	WorkCodeDropdown,
};
const props = {
	value : Object, // dateRange object, { startDate, endDate, ... }
	show  : Boolean, // currently showing filter controls
};

@Component({
	template,
	components,
	props,
})
export default class WorkCodeFilter extends Vue {
	data() {
		return {
			filters : null,
		};
	}

	@AsyncLoading
	get workCodes() {
		return api.workCode.list();
	}

	get workCodeLabel() {
		const { workCode_id } = this.filters;

		if (!workCode_id || !this.workCodes) return;

		const workCode = this.workCodes.find(workCode => workCode.workCode_id === workCode_id);

		if (workCode) return workCode.label;
	}

	@Watch.immediate('value')
	watchValue() {
		if (!this.value) return;

		this.filters = {
			workCode_id : this.value.workCode_id,
		};
	}

	@Watch('filters')
	watchFilters() {
		this.$emit('input', this.filters);
	}

	resetWorkCode() {
		this.$emit('clear', {
			workCode_id : null,
		});
	}
}
