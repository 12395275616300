import Vue from 'vue';

export function json(value) {
	return JSON.stringify(value, (key, value) => {
		if (typeof value === 'undefined') return '[undefined]';

		return value;
	}, 2) || `${value}`;
}

Vue.filter('JSON', json);
