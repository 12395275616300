import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-zone-list.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';

const components = {
	Spinner,
	StatusMessage,
};

@Component({
	template,
	components,
})
export class WorkZoneList extends Vue {
	@Async
	get workZones() {
		return api.workZone.list();
	}

	@Async
	get activeWorkZone() {
		return api.workZone.get();
	}
}

Vue.component('work-zone-list', WorkZoneList);
