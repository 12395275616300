import api from './api';
import company from './company';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	BookConfig : _extend,
};

function list(filters) {
	return filters
		? api.list('id-format', filters)
			.then(list => list.map(bookConfig => _extend(bookConfig)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function read(bookName) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => company_id ? api.read('id-format', bookName, { company_id }) : null)
		.then(bookConfig => _extend(bookConfig));
}

function create(bookConfig) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => company_id ? api.create('id-format', Object.assign({ company_id }, bookConfig)) : null);
}

function update(bookConfig) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => Object.assign({ company_id }, bookConfig))
		.then(bookConfig => api.update('id-format', bookConfig.bookConfig_id || bookConfig.id, bookConfig));
}

function save(bookConfig) {
	return bookConfig.bookConfig_id
		? update(bookConfig)
		: create(bookConfig);
}

function _delete(bookConfig_id) {
	return company.get()
		.then(company => company ? company.company_id : null)
		.then(company_id => company_id ? api.delete('id-format', bookConfig_id, {
			company_id,
		}) : null);
}

function _extend(bookConfig = {}) {
	return Object.assign({

	}, bookConfig);
}
