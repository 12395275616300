import Vue from 'vue';
import Component from 'vue-class-component';
import template from './color-picker.html';
import defaultColors from '@/config/colors.json';
import service from '@/services/color.service';

const props = {
	value : {
		type    : String,
		default : defaultColors[0],
	},
	colors : {
		type : Array,
		default() {
			return defaultColors.slice();
		},
	},
	extra : String,
};

@Component({
	template,
	props,
})
export class ColorPicker extends Vue {
	created() {
		if (!this.value) this.$emit('input', defaultColors[0]);

		if (!defaultColors.includes(this.value)) {
			const replacement = service.migrate(this.value);

			if (replacement) this.$emit('input', replacement);
		}
	}

	isSelected(color) {
		return color === this.value;
	}

	get list() {
		const list = [...this.colors];

		if (this.extra && !list.includes(this.extra)) list.push(this.extra);
		if (this.value && !list.includes(this.value)) list.push(this.value);

		return list;
	}
}

Vue.component('color-picker', ColorPicker);
