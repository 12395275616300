import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-pages" */'./user-profile');

export default {
	component,
	path : 'user-profile',
	name : 'user-profile',
	meta : {
		title       : `${dsl('User')} Profile`,
		secure      : true,
		skipBilling : true,
	},
};
