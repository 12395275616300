import component from './registration';

export default {
	component,
	path : '/registration',
	name : 'registration',
	meta : {
		title       : 'Register',
		skipBilling : true,
	},
};
