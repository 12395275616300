const component = () => import(/* webpackChunkName: "test-pages" */'./user-agent');

export default {
	component,
	path : 'user-agent',
	name : 'user-agent-test',
	meta : {
		title  : 'User Agent Test',
		secure : false,
	},
};
