import Vue from 'vue';
import Component from 'vue-class-component';
import template from './employee.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { Spinner } from '@/base-components/spinner/spinner';
import { EmployeeDropdown } from '@/components/employee-dropdown/employee-dropdown';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { DestructoButton } from '@/buttons/destructo-button/destructo-button';

const components = {
	Spinner,
	EmployeeDropdown,
	BasicButton,
	DestructoButton,
};
const props = {
	value : Object, // dateRange object, { startDate, endDate, ... }
	show  : Boolean, // currently showing filter controls

	// employee pre-selection
	employee   : Object, // Employee record for pre-selection
	noEmployee : Boolean, // don't show the employee dropdown
};

@Component({
	template,
	components,
	props,
})
export default class WorkOrderEmployeeFilter extends Vue {
	data() {
		return {
			filters : null,
		};
	}

	@AsyncLoading
	get employees() {
		return api.employee.list();
	}

	get employeeName() {
		const { employee_id } = this.filters;

		if (!employee_id || !this.employees) return;

		const employee = this.employees.find(employee => employee.employee_id === employee_id);

		if (employee) return employee.employeeName;
	}

	@Watch.immediate('value')
	watchValue() {
		if (!this.value) return;

		this.filters = {
			employee_id : this.value.employee_id,
		};
	}

	@Watch('filters')
	watchFilters() {
		this.$emit('input', this.filters);
	}

	resetEmployee() {
		this.$emit('clear', {
			employee_id : null,
		});
	}
}
