import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { ExternalLayout } from '@/layouts/external-layout/external-layout';
import { TextInput } from '@/inputs/text-input/text-input';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { SubmitButton } from '@/base-components/submit-button/submit-button';

const components = {
	ExternalLayout,
	TextInput,
	BasicButton,
	SubmitButton,
};

@Component({
	template,
	components,
})
export default class Onboarding extends Vue {
	data() {
		return {
			companyName  : null,
			workZoneName : null,
			error        : null,
			loading      : null,
		};
	}

	@Async
	get user() {
		return api.user.get();
	}

	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get workZone() {
		return api.workZone.get();
	}

	@Watch('user')
	watchUser() {
		if (!this.user || !this.user.user_id) return this.$router.push({ name : 'login' });
	}

	@Watch('company')
	watchCompany() {
		if (!this.company) return;

		this.companyName = this.company.companyName;
	}

	@Watch('workZone')
	watchWorkZone() {
		if (!this.workZone) return;

		this.workZoneName = this.workZone.workZoneName;

		this.$router.push({ name : 'dashboard' });
	}

	skip() {
		this.loading = true;

		return this.$router.push({ name : 'user-profile' });
	}

	submit() {
		if (this.loading) return;

		const companyName = this.companyName;
		const workZoneName = this.workZoneName;

		this.loading = true;
		this.error = null;

		return Promise.resolve(this.company || api.company.create({ companyName }))
			.then(({ company_id }) => api.workZone.create({
				company_id,
				workZoneName,
			}))
			.then(() => { this.$router.push({ name : 'dashboard' }) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}
