import defaultDomain from './domains/default';
import localDomain from './domains/local';
import pluralize from 'pluralize';

const patterns = [{
	name  : 'capitalized',
	test  : (phrase, { casing : allowed }) => allowed && /^[A-Z]/.test(phrase),
	apply : phrase => phrase.replace(/^./, match => match.toUpperCase()),
}, {
	name  : 'proper cased',
	test  : (phrase, { casing : allowed }) => allowed && phrase.split(' ').every(word => /^[A-Z]/.test(word)),
	apply : phrase => phrase.replace(/(^| )./g, match => match.toUpperCase()),
}, {
	name  : 'uppercased',
	test  : (phrase, { casing : allowed }) => allowed && !/[a-z]/.test(phrase),
	apply : phrase => phrase.toUpperCase(),
}, {
	name  : 'pluralized',
	test  : (phrase, { plural : allowed }) => allowed && phrase === pluralize(phrase),
	apply : phrase => pluralize(phrase),
}];

export default function dsl(phrase) {
	if (!phrase) return phrase;

	const transcription = [
		'literal',
		'plural',
		'single',
		'lowercase literal',
		'lowercase plural',
		'lowercase single',
	]
		.map(name => Object.assign({ name }, {
			lookup : getTranscriptionLookup(name, phrase),
		}))
		.map(check => Object.assign({
			result : (process.env.DEPLOYMENT === 'local' ? localDomain : defaultDomain)[check.lookup],
		}, check))
		.find(check => typeof check.result === 'string');

	if (!transcription) return phrase;

	return applyPatterns(transcription.result, filterPatterns(phrase, getAllowedPatterns(transcription)));
}

function getTranscriptionLookup(name, phrase) {
	return {
		'literal'           : phrase,
		'plural'            : pluralize(phrase),
		'single'            : pluralize(phrase, 1),
		'lowercase literal' : phrase.toLowerCase(),
		'lowercase plural'  : pluralize(phrase).toLowerCase(),
		'lowercase single'  : pluralize(phrase, 1).toLowerCase(),
	}[name] || phrase;
}

function getAllowedPatterns({ name }) {
	return {
		'literal' : {
			casing : false,
			plural : false,
		},
		'plural' : {
			casing : false,
			plural : false,
		},
		'single' : {
			casing : false,
			plural : true,
		},
		'lowercase literal' : {
			casing : true,
			plural : false,
		},
		'lowercase plural' : {
			casing : true,
			plural : false,
		},
		'lowercase single' : {
			casing : true,
			plural : true,
		},
	}[name];
}

function filterPatterns(phrase, allowed) {
	return patterns.filter(({ test }) => test(phrase, allowed));
}

function applyPatterns(phrase, patterns) {
	return patterns.reduce((phrase, { apply }) => apply(phrase), phrase);
}
