import Vue from 'vue';
import Component from 'vue-class-component';
import template from './preload-table.html';
import data from './preload-table.json';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	Spinner,
};
const props = {};

@Component({
	template,
	components,
	props,
})
export default class PreloadTable extends Vue {
	get list() {
		return data;
	}
}
