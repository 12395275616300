import Vue from 'vue';
import moment from 'moment';

export default function formatDate(value, format = 'MMMM DD YYYY - hh:mm A') {
	if (!value) return value;

	const date = moment(value);

	if (format === 'relative')
		return date.fromNow();

	return date.format(format);
}

Vue.filter('formatDate', formatDate);
