import Vue from 'vue';
import Component from 'vue-class-component';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import avatarService from '@/services/avatar.service';
import colorService from '@/services/color.service';

const props = {
	value    : Object, // the user record - { user_id, name, email, color }
	compact  : Boolean,
	expanded : Boolean,
};

@Component({
	template : '<avatar v-model="avatar" :compact="compact" :expanded="expanded" rounded></avatar>',
	props,
})
export class UserAvatar extends Vue {
	@Async
	get user() {
		return Promise.resolve(this.value || api.user.get());
	}

	@Async(null, 'user')
	get avatar() {
		return avatarService.generateAvatar({
			color : this.user.color || colorService.generate(this.user.user_id),
			label : this.user.name || this.user.email.toUpperCase(),
		});
	}
}

Vue.component('user-avatar', UserAvatar);
