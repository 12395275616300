import api from './api';

export default {
	read,
	create,
	update,

	PasswordReset : _extend,
};

function read(request_id) {
	return api.read('password-reset', request_id)
		.then(passwordReset => _extend(passwordReset));
}

function create(passwordReset) {
	passwordReset.location = `${window.location.origin}/forgot-password`;

	return api.create('password-reset', passwordReset);
}

function update(passwordReset) {
	return api.update('password-reset', passwordReset.request_id, passwordReset);
}

function _extend(passwordReset = {}) {
	return Object.assign({

	}, passwordReset);
}
