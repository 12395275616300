import Vue from 'vue';
import Component from 'vue-class-component';
import template from './employee-input-row.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { PlusButton } from '@/buttons/plus-button/plus-button';
import { Spinner } from '@/base-components/spinner/spinner';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextInput } from '@/inputs/text-input/text-input';

const components = {
	CancelButton,
	ErrorDisplay,
	PlusButton,
	Spinner,
	SubmitButton,
	TextInput,
};
const props = {};

@Component({
	template,
	components,
	props,
})
export class EmployeeInputRow extends Vue {
	data() {
		return {
			employee : null,
			error    : null,
			loading  : null,
		};
	}

	@Watch('employee')
	watchEmployee() {
		this.error = null;
	}

	start() {
		this.employee = new api.employee.Employee();
	}

	submit() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		return api.employee.save(this.employee)
			.then(employee => { this.$emit('input', employee) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('employee-input-row', EmployeeInputRow);
