import Vue from 'vue';
import Component from 'vue-class-component';
import template from './icon-button.html';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	Spinner,
};

const delay = 250;

const props = {
	icon : {
		type     : String,
		required : true,
	},
	type : {
		type    : String,
		default : 'button',
	},
	size     : String, // Fontawsome indicated sizes, without the fa
	tooltip  : String,
	position : {
		type    : String, // positioning of the tooltip
		default : 'is-bottom',
	},
	active   : Boolean, // button is toggled 'on'
	compact  : Boolean, // deprecated; all icon buttons will be 'compact'
	danger   : Boolean,
	disabled : Boolean, // permanently disable the button; waiting for user
	loading  : Boolean, // temporarily disable the button; waiting for system
	primary  : Boolean,
	rounded  : Boolean,
	solid    : Boolean, // always use solid icons
};

@Component({
	template,
	components,
	props,
})
export class IconButton extends Vue {
	data() {
		return {
			locked : true,
		};
	}

	get classname() {
		return {
			'is-primary'  : this.primary,
			'is-text'     : this.compact,
			'is-danger'   : this.danger,
			'is-inverted' : this.danger,
			'is-rounded'  : this.rounded,
		};
	}

	get iconClass() {
		return [
			this.active || this.solid ? 'fas' : 'far',
			`fa-${this.icon}`,
			this.size
				? `fa-${this.size}`
				: null,
		].filter(Boolean);
	}

	created() {
		this.cycleLock();
	}

	click(event) {
		this.cycleLock();

		this.$emit('click', event);
	}

	cycleLock() {
		this.locked = true;

		setTimeout(() => {
			this.locked = false;
		}, delay);
	}
}
