import component from './create-work-order';

export default {
	component,
	path : '/new',
	name : 'create-work-order',
	meta : {
		title  : 'Create Work Order',
		secure : true,
	},
};
