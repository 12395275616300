import api from './api';
import storage from '@/services/storage.service';

export default {
	list,
	count,
	read,
	download,
	create,
	update,
	destroy,
	delete : _delete,
	resetQueue,
};

function list(collection, params, opts) {
	const query = buildQuery(params);

	return storage.list(collection, query)
		.then(list => list || recover(collection));

	function recover(collection) {
		return api.list(collection, params, opts)
			.then(list => storage.list(collection, query, list));
	}
}

function count(...params) {
	return api.count(...params);
}

function read(...params) {
	return api.read(...params);
}

function download(...params) {
	return api.download(...params);
}

function create(collection, record, config) {
	return api.create(collection, record, config)
		.then(record => Promise.all([record, storage.list(collection, null, null)]))
		.then(([record]) => record);
}

function update(collection, record_id, record) {
	return api.update(collection, record_id, record)
		.then(record => Promise.all([record, storage.list(collection, null, null)]))
		.then(([record]) => record);
}

function destroy(...params) {
	return api.destroy(...params);
}

function _delete(collection, record, metadata) {
	return api.delete(collection, record, metadata)
		.then(result => Promise.all([result, storage.list(collection, null, null)]))
		.then(([result]) => result);
}

function resetQueue() {
	return api.resetQueue();
}

////

function buildQuery(params) {
	return Object.keys(params || {}).sort()
		.map(key => `${key}=${encodeURIComponent(params[key])}`)
		.join('&');
}
