const component = () => import(/* webpackChunkName: "settings-pages" */'./purchase-orders');

export default {
	component,
	path : 'purchase-orders',
	name : 'settings/purchase-orders',
	meta : {
		title  : 'Purchase Orders',
		secure : true,
	},
};
