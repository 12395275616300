import Vue from 'vue';
import Component from 'vue-class-component';
import Watch from '@/plugins/watch-decorator';
import Calendar from './calendar.class';
import template from './template.html';
import service from '../../date-validation.service';

const ivalue = '#value';
const props = {
	value    : String,
	step     : [Number, String],
	min      : String,
	max      : String,
	required : Boolean,
	disabled : Boolean,
};

@Component({
	template,
	props,
})
export default class CustomDatePicker extends Vue {
	data() {
		return {
			[ivalue] : null,
			open     : false,
			calendar : new Calendar(),
			dirty    : false,
		};
	}

	mounted() {
		this.matchValue();
	}

	@Watch(['value', 'step'])
	matchValue() {
		if (this[ivalue] && this[ivalue].toISOString() === this.value) return;

		this.ivalue = this.value;
	}

	// Core Data
	// -------------------------------
	get ivalue() {
		return this[ivalue];
	}

	set ivalue(value) {
		const date = value && service.parse(value, this.istep); // ,this.imin,this.imax);

		this[ivalue] = date;
		this.calendar.setMonth(date);
		this.$emit('input', date ? date.toISOString() : null);
	}

	// Supporting Data
	// -------------------------------
	get istep() {
		return Number.parseInt(this.step, 10) || 1;
	}

	get imin() {
		return service.parse(this.min);
	}

	get imax() {
		return service.parse(this.max);
	}

	// Visuals
	get displayValue() {
		return this[ivalue]
			? this[ivalue].format('MM/DD/YYYY')
			: '';
	}

	get displayDate() {
		return this[ivalue]
			? this[ivalue].format('MMMM DD, YYYY')
			: '';
	}

	isSelected(date) {
		if (!this.ivalue) return false;

		if (this.istep === 7)
			return this.ivalue.isSame(date, 'week');

		return this.ivalue.isSame(date, 'day');
	}

	// Controls
	// ----------------

	input(date) {
		// Set value to date now
		this.ivalue = date;
	}

	onEnter(event) {
		if (this.dirty) {
			event.stopPropagation();
			event.preventDefault();
		}
	}

	onInput(event) {
		this.ivalue = event.target.value;
		this.dirty = false;
	}

	// Popup Controls
	// --------------------
	commit(value) {
		// Set queued value, close popup
		if (value || value === null)
			this.input(value);
		this.open = false;
	}

	revert() {
		// Discard queued value, close popup
		this.open = false;
	}
}
