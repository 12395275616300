const component = () => import(/* webpackChunkName: "test-pages" */'./config');

export default {
	component,
	path : 'config',
	name : 'config-test',
	meta : {
		title  : 'Config Test',
		secure : false,
	},
};
