import Vue from 'vue';
import Component from 'vue-class-component';
import template from './main-sidebar-layout.html';

import { ApiProgressBar } from '@/components/api-progress-bar/api-progress-bar';
import { BrandLogo } from '@/components/brand-logo/brand-logo';
import { CompanyMenu } from '@/components/company-menu/company-menu';
import { CompanySummary } from '@/fragments/company-summary';
import { IconButton } from '@/buttons/icon-button/icon-button';
import { ModalContainer } from '@/components/modal-container/modal-container';
import { MenuScrim } from '@/fragments/menu-scrim';
import { UserMenu } from '@/components/user-menu/user-menu';

const components = {
	ApiProgressBar,
	BrandLogo,
	CompanyMenu,
	CompanySummary,
	IconButton,
	MenuScrim,
	ModalContainer,
	UserMenu,
};

@Component({
	template,
	components,
})
export class MainSidebarLayout extends Vue {
	data() {
		return {
			open  : false,
			hooks : [],
		};
	}

	created() {
		if (this.$router)
			this.hooks = [
				this.$router.afterEach(() => {
					this.open = false;
				}),
			];
	}

	beforeDestroy() {
		this.hooks.forEach(remove => remove());
		this.hooks = [];
	}

	toggle() {
		this.open = !this.open;
	}
}
