import Vue from 'vue';
import Component from 'vue-class-component';
import template from './timesheet-table-paginated.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import modalService from '@/services/modal.service';

import BooleanDisplay from './boolean-display/boolean-display';
import HoursSummary from './hours-summary/hours-summary';
import PreloadTable from './preload-table/preload-table';
import TimePairTd from './time-pair/time-pair';
import { AsyncPagination, AsyncPaginatedTable } from '@/components/async-paginated-table';
import { EmployeeDisplay } from '@/fragments/employee-display';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';
import { WorkLocationDisplay } from '@/fragments/work-location-display';
import { CheckboxInput } from '@/inputs/checkbox-input/checkbox-input';
import { IndeterminateCheckboxInput } from '@/inputs/indeterminate-checkbox-input/indeterminate-checkbox-input';
import { DeleteButton } from '@/buttons/delete-button/delete-button';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	BooleanDisplay,
	HoursSummary,
	PreloadTable,
	TimePairTd,
	AsyncPaginatedTable,
	EmployeeDisplay,
	Spinner,
	StatusMessage,
	WorkLocationDisplay,
	CheckboxInput,
	IndeterminateCheckboxInput,
	DeleteButton,
	BasicButton,
};
const props = {
	enableMultiDelete : {
		type : Boolean,
		default() {
			return true;
		},
	},
};

@Component({
	template,
	components,
	props,
})
export class TimesheetTablePaginated extends Vue {
	data() {
		return {
			filters   : {},
			hours     : null,
			timecards : new AsyncPagination({
				page      : 1,
				firstPage : 1,
				preload   : false,
				fetch     : page => this.fetchPage(page),
				key(timecard) {
					return timecard.timecard_id;
				},
			}),
			multiDelete : false,
			toRemove    : [],
		};
	}

	@Watch('filters')
	onFiltersChange() {
		this.timecards
			.fetch(1)
			.catch(() => null);
		this.cancelDelete();
	}

	fetchPage(page) {
		return api.timeCard.paginate(Object.assign({}, this.filters, { page }))
			.then(results => {
				this.hours = results.summary;

				return results;
			});
	}

	get tcol() {
		const len = this.multiDelete ? 20 : 19;

		if (!this.filters) return len;

		let rem = [
			'employee_id',
			'workCode_id',
			// 'workLocation_id',
		].map(key => this.filters[key] ? 1 : 0).reduce((a, b) => a + b);

		if (!this.showWorkLocation)
			rem -= 1;

		return len - rem;
	}

	get showWorkLocation() {
		// Fallback, too early
		if (!this.filters) return true;
		// 0 single
		if (!this.filters.workLocation_id) return true;

		const isArray = Array.isArray(this.filters.workLocation_id);
		const len = isArray ? this.filters.workLocation_id.length : 1;

		if (isArray && len > 1) return true; // many (array)

		return false; // 1 (array), 1 other
	}
	get multiLocation() {
		// Fallback, too early
		if (!this.filters) return false;
		// 0 single
		if (!this.filters.workLocation_id) return false;

		const isArray = Array.isArray(this.filters.workLocation_id);
		const len = isArray ? this.filters.workLocation_id.length : 1;

		if (isArray && len > 1) return true; // many (array)

		return false; // 1 (array), 1 other
	}

	emit(event, value) {
		return this.$emit(event, value);
	}

	isQueuedForDelete(timecard) {
		const timecard_id = timecard.timecard_id;
		const index = this.toRemove.indexOf(timecard_id);

		return index > -1;
	}
	get pageDeleteStatus() {
		if (!this.timecards) return false;

		if (!this.toRemove.length) return 'none';
		if (this.toRemove.length >= this.timecards.total) return 'all';

		return 'some';
	}
	set pageDeleteStatus(value) { // eslint-disable-line complexity
		if (!this.timecards) return;

		if (value === true || value === 'all') {
			const page = this.timecards.list.map(card => card.timecard_id);

			if (intersection(this.toRemove, page).length === page.length)
				this.toRemove = difference(this.toRemove, page);
			else
				this.toRemove = union(this.toRemove, page);
		}

		else if (value === false || value === 'none')
			this.toRemove = [];
	}
	async toggleDelete(timecard) {
		if (this.isQueuedForDelete(timecard))
			return this.unqueueForDelete(timecard);

		return this.queueForDelete(timecard);
	}
	async queueForDelete(timecard) {
		const timecard_id = timecard.timecard_id;

		this.toRemove.push(timecard_id);
	}
	async unqueueForDelete(timecard) {
		const timecard_id = timecard.timecard_id;
		const index = this.toRemove.indexOf(timecard_id);

		if (index > -1)
			this.toRemove.splice(index, 1);
	}
	async onDelete() {
		try {
			const list = this.toRemove;

			if (!list.length) return;

			await modalService.launchModal('verify-deletion', {
				name    : `${this.$dsl('Time Cards')}`,
				message : `Are you sure you want to delete ${list.length} ${this.$dsl('Time Cards')}?`,
			});

			for (const timecard_id of list)
				await api.timeCard.destroy(timecard_id);

			await this.timecards
				.fetch(1)
				.catch(() => null);
		}
		catch (error) {
			if (error)
				this.error = error;
		}
		finally {
			this.toRemove = [];
			this.multiDelete = false;
		}
	}
	async cancelDelete() {
		this.toRemove = [];
		this.multiDelete = false;
	}
}

Vue.component('timesheet-table-paginated', TimesheetTablePaginated);

function union(leftArray, rightArray) {
	const missing = rightArray.filter(item => leftArray.indexOf(item) < 0);

	return leftArray.concat(missing);
}

function intersection(leftArray, rightArray) {
	return rightArray.filter(item => leftArray.indexOf(item) > -1);
}

function difference(leftArray, rightArray) {
	return leftArray.filter(item => rightArray.indexOf(item) < 0);
}
