import Vue from 'vue';
import Component from 'vue-class-component';
import template from './modal-container.html';
import Async from '@/plugins/async-decorator';
import storage from '@/services/storage.service';

@Component({ template })
export class ModalContainer extends Vue {
	@Async
	get config() {
		return storage.get('modal')
			.catch(() => null);
	}

	submit(payload = null) {
		return this.config.submit(payload);
	}

	cancel(payload = null) {
		return this.config.cancel(payload);
	}

	error(error) {
		return this.config.cancel(error);
	}
}

Vue.component('modal-container', ModalContainer);
