import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-status-dropdown.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

import { BasicSelect } from '@/base-components/basic-select/basic-select';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	BasicSelect,
	Spinner,
};

const props = {
	value    : null,
	allowAll : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class WorkOrderStatusDropdown extends Vue {
	@Async
	get statuses() {
		return api.workOrderStatus.list();
	}

	get closedStatus() {
		if (!this.statuses) return;

		return this.statuses
			.filter(status => status.closes)
			.map(status => status.value);
	}

	get list() {
		if (!this.statuses) return;

		const list = this.statuses.map(status => status);

		return list;
	}

	get placeholder() {
		return this.allowAll
			? 'Any Statuses'
			: 'Status';
	}

	select(status) {
		this.$emit('input', status);

		if (this.closedStatus.includes(status)) {
			const date = new Date();

			date.setHours(0, 0, 0, 0);

			this.$emit('date', date);
		}
	}
}

Vue.component('work-order-status-dropdown', WorkOrderStatusDropdown);
