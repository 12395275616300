import api from './api';
import company from './company';
import workZone from './work-zone';

export default {
	list,
	paginate,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	WorkOrder : _extend,
};

function list(filters) {
	return filters
		? api.list('work-order', filters, { sortBy : 'date' })
			.then(list => list.map(workOrder => _extend(workOrder)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function paginate(filters) {
	return filters
		? api.noCacheList('work-order/p', filters)
			.then(results => {
				results.list = results.list.map(workOrder => _extend(workOrder));

				return results;
			})
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? paginate({ company_id }) : []);
}

function count(filters) {
	return filters
		? api.read('work-order/c', '', filters)
			.then(({ count }) => {
				const value = Number.parseInt(count, 10);

				return Number.isNaN(value) ? 0 : value;
			})
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? count({ company_id }) : []);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(workOrder_id) {
	return api.read('work-order', workOrder_id)
		.then(workOrder => _extend(workOrder));
}

function create(workOrder) {
	return Promise.all([
		company.get(),
		workZone.get(),
	])
		.then(([{
			company_id,
		}, {
			workZone_id,
		}]) => Object.assign({
			company_id,
		}, workOrder, workOrder.workZone_id ? null : {
			workZone_id,
		}))
		.then(workOrder => api.create('work-order', workOrder));
}

function update(workOrder) {
	return api.update('work-order', workOrder.workOrder_id, workOrder);
}

function save(workOrder) {
	return workOrder.workOrder_id ? update(workOrder) : create(workOrder);
}

function _delete(workOrder_id) {
	return api.delete('work-order', workOrder_id);
}

function _extend(workOrder = {}) {
	const hours = workOrder.quantityUnits === 'hours'
		? workOrder.quantity
		: null;
	const acres = workOrder.quantityUnits === 'acres'
		? workOrder.quantity
		: null;

	const date = new Date();

	date.setHours(0, 0, 0, 0);

	return Object.assign({
		date,
		description     : null,
		employee_id     : null,
		workLocation_id : null,
		workCode_id     : null,
		workOrder_id    : null,
		hours,
		acres,
		status          : 'open',
		recommendation  : '',
	}, workOrder);
}
