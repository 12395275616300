import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-menu-trigger-button.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { InnerEllipsis } from '@/components/inner-ellipsis/inner-ellipsis';

const components = {
	BasicButton,
	InnerEllipsis,
};
const props = {
	show     : Boolean, // whether the menu is open
	disabled : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class CompanyMenuTriggerButton extends Vue {
	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get workZone() {
		return api.workZone.get();
	}
}
