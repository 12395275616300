export default {
	namespaced : true,

	state : {
		apiProgress : 0,
	},

	getters : {
		apiProgress(state) {
			return state.apiProgress || 0;
		},
	},

	mutations : {
		increment(state) {
			state.apiProgress++;
		},

		decrement(state) {
			state.apiProgress--;
		},
	},

	actions : {
		increment({ commit }) {
			return commit('increment');
		},

		decrement({ commit }) {
			return commit('decrement');
		},
	},
};
