import Vue from 'vue';
import Component from 'vue-class-component';
import template from './login.html';
import api from '@/api';
import storage from '@/services/storage.service';

import { ExternalLayout } from '@/layouts/external-layout/external-layout';

const components = {
	ExternalLayout,
};

@Component({
	template,
	components,
})
export default class Login extends Vue {
	created() {
		storage.reset();
	}

	mounted() {
		api.session.logout();
	}

	get isDemo() {
		return /^demo\./i.test(location.hostname);
	}

	get email() {
		return this.$route.query.email;
	}

	login() {
		return this.$router.push({ name : 'dashboard' });
	}
}
