import api from './api';
import company from './company';
import workZone from './work-zone';

export default {
	list,
	paginate,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	PurchaseOrder : _extend,
};


function list(filters) {
	return filters
		? api.list('purchase-order', filters, { sortBy : 'date' })
			.then(list => list.map(purchaseOrder => _extend(purchaseOrder)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function paginate(filters) {
	return filters
		? api.noCacheList('purchase-order/p', filters)
			.then(results => {
				results.list = results.list.map(purchaseOrder => _extend(purchaseOrder));

				return results;
			})
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? paginate({ company_id }) : []);
}

function count(filters) {
	return filters
		? api.read('purchase-order/c', '', filters)
			.then(({ count }) => {
				const value = Number.parseInt(count, 10);

				return Number.isNaN(value) ? 0 : value;
			})
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? count({ company_id }) : []);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(purchaseOrder_id) {
	return api.read('purchase-order', purchaseOrder_id)
		.then(purchaseOrder => _extend(purchaseOrder));
}

function create(purchaseOrder) {
	return Promise.all([
		company.get(),
		workZone.get(),
		filter(purchaseOrder),
	])
		.then(([
			{ company_id },
			{ workZone_id },
			record,
		]) => Object.assign({
			company_id,
			workZone_id,
		}, record))
		.then(purchaseOrder => api.create('purchase-order', purchaseOrder));
}


function update(purchaseOrder) {
	return api.update('purchase-order', purchaseOrder.purchaseOrder_id, purchaseOrder);
}

function save(purchaseOrder) {
	return purchaseOrder.purchaseOrder_id ? update(purchaseOrder) : create(purchaseOrder);
}

function _delete(purchaseOrder_id) {
	return api.delete('purchase-order', purchaseOrder_id);
}

function _extend(purchaseOrder = {}) {
	return Object.assign({
		date             : zeroHour(),
		type             : 'material',
		display_id       : null,
		vendor           : null,
		recommendation   : null,
		remarks          : null,
		materials        : null,
		purchaseOrder_id : null,
	}, purchaseOrder);
}

function zeroHour(date = new Date()) {
	date.setHours(0, 0, 0, 0);

	return date;
}

function filter(record) {
	return Object.keys(record)
		.filter(key => record[key] !== null && record[key] !== undefined) // eslint-disable-line no-undefined
		.reduce((next, key) => Object.assign(next, { [key] : record[key] }), {});
}
