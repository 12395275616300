import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-details.html';
import api from '@/api';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import colorService from '@/services/color.service';

import { ColorPickerDropdown } from '@/components/color-picker-dropdown/color-picker-dropdown';
import { EmailInput } from '@/inputs/email-input/email-input';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { TextInput } from '@/inputs/text-input/text-input';
import { Spinner } from '@/base-components/spinner/spinner';
import { SaveButton } from '@/buttons/save-button/save-button';
import { UserAvatar } from '@/fragments/user-avatar';

const components = {
	ColorPickerDropdown,
	EmailInput,
	ErrorDisplay,
	TextInput,
	Spinner,
	SaveButton,
	UserAvatar,
};

const props = {
	readonly : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class UserDetails extends Vue {
	data() {
		return {
			disabled : true,
			loading  : false,
			error    : null,
		};
	}

	get extraColor() {
		return colorService.generate(this.user.user_id);
	}

	@Async()
	get user() {
		return api.user.get();
	}

	@Watch('user')
	setDirty(_, old) {
		if (old) this.disabled = false;
	}

	clearError() {
		this.error = null;
	}

	submit() {
		this.loading = true;
		this.error = null;

		return api.user.update(this.user)
			.then(() => { this.disabled = true })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('user-details', UserDetails);
