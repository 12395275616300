import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-pages" */'./company-details');

export default {
	component,
	path : 'company-details',
	name : 'company-details',
	meta : {
		title          : `${dsl('Company')} Details`,
		secure         : true,
		requireCompany : true,
		skipBilling    : true,
	},
};
