import localStorage from '@/services/local-storage.service';
import sessionStorage from '@/services/session-storage.service';

export default {
	namespaced : true,

	state : {
		auth : null,
	},

	getters : {
		get(state) {
			if (!state.auth) state.auth = localStorage.get('auth');

			return state.auth;
		},
	},

	mutations : {
		set(state, { auth }) {
			state.auth = auth || null;

			localStorage.set('auth', state.auth);
			sessionStorage.set('auth', state.auth);

			return state.auth;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},
	},

	modules : {
	},
};
