import moment from 'moment';

export default {
	getDiff, // Get the difference in minutes between two hour intervals
	getWeek, // Get the ISO 8601 week, use locale to change
	parseWeek, // Parse an ISO 8601 week, a locale is used if it proceeds the date
	getWeekRange,

	getTotalMinutes, // Get the total working minutes in a timecard
	getTotalHours, // Get the total working hours in a timecard
	getUsage, // Get REG, OT, DT breakdown of a single timecard
};

function getDiff(ltime, rtime) {
	if (typeof ltime !== 'string' || typeof rtime !== 'string')
		return 0;

	const [lhr, lmin] = ltime.split(':').map(part => Number.parseInt(part, 10));
	const [rhr, rmin] = rtime.split(':').map(part => Number.parseInt(part, 10));
	const hour = 60;

	return ((rhr - lhr) * hour) + rmin - lmin;
}

function getTotalMinutes(timecard) {
	return [
		getDiff(timecard.timeIn, timecard.timeOut),
		getDiff(timecard.firstMealIn, timecard.firstMealOut),
		getDiff(timecard.secondMealIn, timecard.secondMealOut),
		getDiff(timecard.thirdMealIn, timecard.thirdMealOut),
	].reduce((a, b) => a - b);
}

function getTotalHours(timecard) {
	const hour = 60;

	return [
		getDiff(timecard.timeIn, timecard.timeOut),
		getDiff(timecard.firstMealIn, timecard.firstMealOut),
		getDiff(timecard.secondMealIn, timecard.secondMealOut),
		getDiff(timecard.thirdMealIn, timecard.thirdMealOut),
	].reduce((a, b) => a - b) / hour;
}

function getUsage(hours) {
	const regularMax = 9;
	const overtimeMax = 3;
	const [regular, overtime, doubletime] = calculateUsage(hours, regularMax, overtimeMax);

	return {
		regular,
		overtime,
		doubletime,
	};
}

function calculateUsage(total = 0, low = 8, med = 4) { // eslint-disable-line max-statements
	return [
		total < low
			? total
			: low,
		total > (low + med)
			? med
			: Math.max(0, total - low),
		Math.max(0, total - low - med),
	];
}

function getWeek(date, locale) {
	if (!date || typeof date !== 'string') return null;

	if (locale && typeof locale === 'string')
		return moment(date, moment.ISO_8601, locale)
			.format(`[${locale}] gggg-[W]ww`);

	return moment(date, moment.ISO_8601)
		.format('GGGG-[W]WW');
}

function parseWeek(weekStr) {
	if (/^\d{4}-W\d{2}$/.test(weekStr))
		return moment(weekStr, 'GGGG-[W]WW');

	if (/^[\w-]+ \d{4}-W\d{2}$/.test(weekStr)) {
		const [locale, fmt] = weekStr.split(' ');

		return moment(fmt, 'gggg-[W]ww', locale);
	}

	return null;
}

function getWeekRange(weekStr, locale = 'en-mon') {
	const startDate = parseWeek(weekStr).toISOString();
	const endDate = moment(startDate, moment.ISO_8601).locale(locale)
		.endOf('week')
		.startOf('day')
		.toISOString();

	return {
		startDate,
		endDate,
	};
}
