import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-form.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { Spinner } from '@/base-components/spinner/spinner';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextInput } from '@/inputs/text-input/text-input';

const components = {
	CancelButton,
	ErrorDisplay,
	Spinner,
	SubmitButton,
	TextInput,
};

const props = {
	value : Object, // Company record
};

@Component({
	template,
	components,
	props,
})
export class CompanyForm extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			company : null,
		};
	}

	@Watch.immediate('value')
	watchValue() {
		Promise.resolve(this.value || api.company.get())
			.then(company => { this.company = Object.assign({}, company) });
	}

	submit() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		return api.company.update(this.company)
			.then(company => this.$emit('input', company))
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	cancel() {
		this.watchValue();
		this.$emit('cancel');
	}
}

Vue.component('company-form', CompanyForm);
