import Vue from 'vue';
import Component from 'vue-class-component';
import template from './checkbox-input.html';

const props = {
	value       : Boolean,
	label       : String,
	message     : String,
	messageType : String, // ['is-danger', 'is-info', etc]
};

@Component({
	template,
	props,
})
export class CheckboxInput extends Vue {
	mounted() {
		fixBuefyCheckboxTabIndex(this.$el);
	}
}

Vue.component('checkbox-input', CheckboxInput);


function fixBuefyCheckboxTabIndex(template) {
	return template.querySelectorAll('label.checkbox input[type=checkbox]')
		.forEach(input => { input.tabIndex = -1 });
}
