import * as Sentry from '@sentry/browser';
import packageJson from '../../../../package.json';

export default {
	reset,
	identify,
};

if (process.env.SENTRY_PUBLIC_DSN) Sentry.init({
	dsn          : process.env.SENTRY_PUBLIC_DSN,
	integrations : [
		new Sentry.Integrations.Vue(),
	],
	release          : packageJson.version,
	environment      : process.env.DEPLOYMENT ? `${process.env.NODE_ENV}-${process.env.DEPLOYMENT}` : process.env.NODE_ENV,
	attachStacktrace : true,
});

function reset() {
	if (!process.env.SENTRY_PUBLIC_DSN) return;

	Sentry.configureScope(scope => {
		scope.setUser(null);
	});
}

function identify(user = {}) {
	if (!process.env.SENTRY_PUBLIC_DSN) return;

	Sentry.configureScope(scope => {
		scope.setUser({
			id       : user.user_id,
			username : user.name,
			email    : user.email,
		});
	});
}
