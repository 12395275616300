import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-table.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

import { CompanyInputRow } from '@/components/company-input-row/company-input-row';
import { DeleteIconButton } from '@/buttons/delete-icon-button/delete-icon-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { SortableTable } from '@/components/sortable-table/sortable-table';
import { Spinner } from '@/base-components/spinner/spinner';
import { TableColumn } from '@/components/table-column/table-column';
import { TableStatusMessage } from '@/components/table-status-message/table-status-message';

const components = {
	CompanyInputRow,
	DeleteIconButton,
	ErrorDisplay,
	SortableTable,
	Spinner,
	TableColumn,
	TableStatusMessage,
};
const props = {};

@Component({
	template,
	components,
	props,
})
export class CompanyTable extends Vue {
	data() {
		return {
			error    : null,
			loading  : null,
			selected : null,
		};
	}

	@Async
	get list() {
		return api.company.list();
	}

	editing({ company_id }) {
		return this.selected && this.selected.company_id === company_id;
	}

	deleteCompany({ company_id }) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;
		this.selected = null;

		const index = this.list.findIndex(company => company_id === company.company_id);

		return api.company.delete(company_id)
			.then(() => { this.list.splice(index, 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	submit(company) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		const index = this.list.indexOf(this.selected);

		return api.company.save(company)
			.then(company => { this.list.splice(index, 1, company) })
			.then(() => { this.selected = null })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('company-table', CompanyTable);
