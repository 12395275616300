import Vue from 'vue';
import Component from 'vue-class-component';
import template from './brand-logo.html';

const props = {
	linkTo : {
		type    : String, // route to link to
		default : () => 'dashboard',
	},
};

@Component({
	template,
	props,
})
export class BrandLogo extends Vue {}

Vue.component('brand-logo', BrandLogo);
