const symbols = {
	data    : '#data',
	promise : '#promise',
	resolve : '#resolve',
	reject  : '#reject',
};

export class LoadingSignal {
	constructor() {
		this.loading = true;
		this.count = 0;
		this.total = null;
		this.cancel = null;
		this[symbols.resolve] = null;
		this[symbols.promise] = new Promise(resolve => {
			this[symbols.resolve] = resolve;
		});
	}

	get indeterminate() {
		return this.total === null;
	}

	get promise() {
		return this[symbols.promise];
	}

	resolve(value) {
		return this[symbols.resolve](value);
	}

	mark(count, total) {
		this.count += count;
		if (typeof total === 'number')
			this.total = total;
	}

	get percentage() {
		if (!this.indeterminate)
			return this.count / this.total;

		return null;
	}
}

/*
fetch: function, required
	params: page (Number), query (Object, shallow)
	returns: Promise resolving to object containing
		list  : Array, Current page of resource
		total : Number, Total number of resources for query
		pages : Number, Total pages for query
key: function, optional
	params: resource, index, list
	returns: Stringlike unique representation for resource
queryBuilder: function, optional
	params: options, default options
	return: Object representing query string
*/

export class AsyncPagination {
	constructor(configuration = {}) {
		this[symbols.data] = {
			queryParams  : configuration.query,
			queryBuilder : configuration.queryBuilder,
			searchFn     : configuration.fetch,
			keyFn        : configuration.key,
			classFn      : configuration.classlist,
			list         : null,
			currentPage  : configuration.page,
			firstPage    : nullish(configuration.firstPage, configuration.page),
			lastPage     : null,
			totalResults : null,
			error        : null,
			loading      : null,
		};

		if (configuration.preload !== false)
			this.fetch().catch(() => null);
	}

	get loading() {
		return this[symbols.data].loading;
	}

	get error() {
		return this[symbols.data].error;
	}

	get page() {
		return this[symbols.data].currentPage;
	}
	set page(value) {
		this.fetch(value).catch(() => null);
	}

	get firstPage() {
		return this[symbols.data].firstPage;
	}

	get lastPage() {
		return this[symbols.data].lastPage;
	}

	get list() {
		return this[symbols.data].list;
	}
	get length() {
		if (this[symbols.data].list)
			return this[symbols.data].list.length;

		return null;
	}
	get total() {
		return this[symbols.data].totalResults;
	}

	async fetch(page, options) { // eslint-disable-line max-statements
		const config = this[symbols.data];
		const query = this.queryBuilder(options);

		if (!page) page = config.firstPage;
		if (config.loading) return;

		try {
			config.loading = true;
			config.error = null;

			const {
				list,
				total,
				pages,
			} = await config.searchFn(page, query);

			config.list = list;
			config.currentPage = Number.parseInt(page, 10);
			config.totalResults = Number.parseInt(total, 10);
			config.lastPage = Number.parseInt(pages, 10);

			return config.list;
		}
		catch (error) {
			config.error = error;

			throw error;
		}
		finally {
			config.loading = false;
		}
	}

	async next() {
		if (this.page >= this.lastPage) return;

		return this.fetch(this.page + 1);
	}
	async previous() {
		if (this.page <= this.firstPage) return;

		return this.fetch(this.page - 1);
	}

	queryBuilder(options = {}) {
		const config = this[symbols.data];

		if (typeof config.queryBuilder === 'function')
			return config.queryBuilder(options, config.queryParams);

		return Object.assign({}, config.queryParams, options);
	}

	key(item, index, list) {
		const config = this[symbols.data];

		if (typeof config.keyFn !== 'function') return item;

		return config.keyFn(item, index, list);
	}

	classlist(item, index, list) {
		const config = this[symbols.data];

		if (typeof config.classFn !== 'function') return null;

		return config.classFn(item, index, list);
	}
}

function nullish(value, fallback) {
	if (typeof value === 'undefined' || value === null) return fallback;

	return value;
}
