import Vue from 'vue';
import Component from 'vue-class-component';
import template from './location.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	BasicButton,
};
const props = {
	value : Object, // filters object
};

@Component({
	template,
	components,
	props,
})
export default class LocationFilter extends Vue {
	@AsyncLoading
	get locations() {
		const where = this.value && this.value.workZone_id ? { workZone_id : this.value.workZone_id } : null;

		return api.workLocation.list(where)
			.then(list => {
				this.ready();

				return list;
			});
	}

	async ready() {
		await this.$nextTick();
		this.$emit('ready');
	}

	get locationName() {
		if (!this.value || !this.value.workLocation_id || !this.locations) return;

		const { workLocation_id } = this.value;

		const location = this.locations.find(location => location.workLocation_id === workLocation_id);

		if (location) return location.workLocationName;
	}
}
