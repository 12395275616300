import dsl from '@/services/dsl.service';
const component = () => import(/* webpackChunkName: "settings-pages" */'./work-zones');

export default {
	component,
	path : 'work-zones',
	name : 'work-zones',
	meta : {
		title          : `${dsl('Work Zones')}`,
		secure         : true,
		requireCompany : true,
	},
};
