const component = () => import(/* webpackChunkName: "test-pages" */'./weekly-overtime-calculator');

export default {
	component,
	path : 'weekly-overtime-calculator',
	name : 'weekly-overtime-calculator-test',
	meta : {
		title  : 'Weekly Overtime Calculator Test',
		secure : false,
	},
};
