import component from './work-order-reports';

export default {
	component,
	path : '/work-order-reports',
	name : 'work-order-reports',
	meta : {
		title  : 'Work Order Reports',
		secure : true,
	},
};
