import Vue from 'vue';
import Component from 'vue-class-component';
import template from './basic-button.html';

const delay = 250;
const longDelay = 2000;

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
	fast     : Boolean, // don't lock the button, so the user can click faster
	longLock : Boolean, // lock out for a longer period
};

@Component({
	template,
	props,
})
export class BasicButton extends Vue {
	data() {
		return {
			locked : !this.fast,
		};
	}

	created() {
		if (!this.fast)
			this.cycleLock(this.longLock ? longDelay : delay);
	}

	click(event) {
		if (!this.fast) this.cycleLock(delay);

		this.$emit('click', event);
	}

	cycleLock(delay) {
		this.locked = true;

		setTimeout(() => {
			this.locked = false;
		}, delay);
	}
}
