import Vue from 'vue';
import Component from 'vue-class-component';
import template from './error-display.html';
import Watch from '@/plugins/watch-decorator';
import sentry from '@/services/sentry.service';

import { MessageDisplay } from '@/base-components/message-display/message-display';

const props = {
	error : [Error, Object, String],
};

const components = {
	MessageDisplay,
};

@Component({
	template,
	props,
	components,
})
export class ErrorDisplay extends Vue {
	get message() {
		return typeof this.error === 'string' ? this.error : this.statusMessage || this.contextMessage || this.genericMessage;
	}

	get details() {
		return this.error && typeof this.error === 'object' ? this.error.details || this.error.stack : null;
	}

	get statusMessage() {
		const { context, message } = this.error;
		const devMessage = typeof context === 'string' ? `${message} - ${context}` : message;

		return process.env.NODE_ENV === 'production' ? {
			'-1' : 'Could not connect to the network.', // network error

			'400' : '', // bad request
			'401' : '', // not authorized
			'402' : '', // payment required
			'403' : 'You don\'t have permission to do that.',
			'404' : 'Not found.',
			'409' : '', // conflict
			'429' : '', // too many requests

			'500' : '', // server error
			'501' : '', // not implemented
			'503' : 'Temporarily Unavailable. Please reload the page and try again.', // service unavailable
			'504' : 'Gateway Timeout. Please reload the page and try again.', // gateway timeout; e.g. stripe api

			'' : message,
		}[this.error.status || ''] : devMessage;
	}

	get contextMessage() {
		const { context } = this.error;

		return !context || typeof context === 'string' ? context : context.message || context.reason;
	}

	get genericMessage() {
		return 'An unknown error occurred. Please try again later.';
	}

	@Watch('error')
	watchError() {
		if (this.error && typeof this.error === 'object') sentry.captureException(this.error);
	}
}

Vue.component('error-display', ErrorDisplay);
