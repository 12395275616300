import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-menu.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import service from '@/services/modal.service';
import { isDemoUser } from '@/services/permission.service';

import { DropdownLayout } from '@/layouts/dropdown-layout/dropdown-layout';
import { CompanyMenuTriggerButton } from '@/buttons/company-menu-trigger-button/company-menu-trigger-button';
import { CompanyMenuHeader } from '@/components/company-menu-header/company-menu-header';
import { WorkZoneList } from '@/components/work-zone-list/work-zone-list';
import { AddCompanyButton } from '@/buttons/add-company-button/add-company-button';
import { AddWorkZoneButton } from '@/buttons/add-work-zone-button/add-work-zone-button';

const components = {
	DropdownLayout,
	CompanyMenuTriggerButton,
	CompanyMenuHeader,
	WorkZoneList,
	AddCompanyButton,
	AddWorkZoneButton,
};

const props = {
	disabled : Boolean,
	readonly : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class CompanyMenu extends Vue {
	data() {
		return {
			show : null,
		};
	}

	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}

	get canCreate() {
		return !this.readonly;
	}

	get canSelect() {
		return !this.disabled;
	}

	addCompany() {
		this.$emit('error');

		return service.launchModal('add-company')
			.then(company => Promise.all([
				api.company.create(company),
				company,
			]))
			.then(([{ company_id }, workZone]) => api.workZone.create(Object.assign(workZone, { company_id })))
			.catch(error => { this.$emit('error', error) });
	}

	addWorkZone() {
		this.$emit('error');

		return service.launchModal('add-work-zone')
			.then(workZone => api.workZone.create(workZone))
			.catch(error => { this.$emit('error', error) });
	}

	selectWorkZone(workZone) {
		return api.company.set(this.company)
			.then(() => api.workZone.set(workZone));
	}
}

Vue.component('company-menu', CompanyMenu);
