import api from './api';
import company from './company';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	WorkCode : _extend,
};

function list(filters) {
	return filters
		? api.list('work-code', filters)
			.then(list => list.map(workCode => _extend(workCode)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(workCode_id) {
	return api.read('work-code', workCode_id)
		.then(workCode => _extend(workCode));
}

function create(workCode) {
	return company.get()
		.then(({ company_id }) => Object.assign({ company_id }, workCode))
		.then(workCode => api.create('work-code', workCode));
}

function update(workCode) {
	return api.update('work-code', workCode.workCode_id, workCode);
}

function save(workCode) {
	return workCode.workCode_id ? update(workCode) : create(workCode);
}

function _delete(workCode_id) {
	return api.delete('work-code', workCode_id);
}

function _extend(workCode = {}) {
	return Object.assign({
		code  : null,
		label : null,
	}, workCode);
}
