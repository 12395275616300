import Vue from 'vue';
import Component from 'vue-class-component';
import template from './narrow-table.html';
import sort from '@/services/sorting.service';

const props = {
	value : Object, // the selected row, if any
	list  : Array, // table body data
};

@Component({
	template,
	props,
})
export class NarrowTable extends Vue {
	get rows() {
		const rows = this.list.map(value => value);
		const column = this.column;

		if (column) {
			const order = this.column_id === column.column_id ? 1 : -1;

			rows.sort((one, two) => order * sort(one, two, column.sortable));
		}

		return rows;
	}
}

Vue.component('narrow-table', NarrowTable);
