import Vue from 'vue';
import Component from 'vue-class-component';
import template from './timecard-form.html';
import api from '@/api';
import Async from '@/plugins/async-decorator';
import overtime from '@/services/overtime-calculator.service';

import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { CheckboxInput } from '@/inputs/checkbox-input/checkbox-input';
import { DateInput } from '@/inputs/date-input/date-input';
import { DeleteButton } from '@/buttons/delete-button/delete-button';
import { EmployeeDropdown } from '@/components/employee-dropdown/employee-dropdown';
import { NumericInput } from '@/inputs/numeric-input/numeric-input';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextareaInput } from '@/inputs/textarea-input/textarea-input';
import { TextInput } from '@/inputs/text-input/text-input';
import { TimeInput } from '@/inputs/time-input/time-input';
import { WorkCodeDropdown } from '@/components/work-code-dropdown/work-code-dropdown';
import { WorkLocationDropdown } from '@/components/work-location-dropdown/work-location-dropdown';

const ivalue = '#value';
const components = {
	CancelButton,
	CheckboxInput,
	DateInput,
	DeleteButton,
	EmployeeDropdown,
	NumericInput,
	SubmitButton,
	TextareaInput,
	TextInput,
	TimeInput,
	WorkCodeDropdown,
	WorkLocationDropdown,
};
const props = {
	value : Object,
};

@Component({
	template,
	components,
	props,
})
export class TimecardForm extends Vue {
	data() {
		return {
			[ivalue]  : new api.timeCard.TimeCard(),
			locations : null,
			loading   : false,
		};
	}

	mounted() {
		const value = this.value || {};
		const {
			workLocations : locations,
		} = value;

		this.locations = getEmbeddedIds(locations, 'workLocation_id');

		const workZone_id = value.workZone && value.workZone.workZone_id;
		const workCode_id = value.workCode && value.workCode.workCode_id;
		const employee_id = value.employee && value.employee.employee_id;

		Object.assign(this[ivalue], value, {
			workZone_id,
			employee_id,
			workCode_id,
		});
	}

	@Async
	get overtimeConfig() {
		return api.overtimeConfig.find()
			.catch(error => { this.error = error });
	}

	get record() {
		return this[ivalue];
	}

	set record(value) {
		this[ivalue] = value;
	}

	get key() {
		return this[ivalue].timecard_id || null;
	}

	inputTime(key, time) {
		this[ivalue][key] = time || null;

		if (this.overtimeConfig)
			overtime.getTimecardHours(this[ivalue], this.overtimeConfig);
	}

	cancel() {
		this.$emit('error');
		this.$emit('input', null);
	}

	submit() {
		if (this.loading) return;

		this.loading = true;
		this.$emit('error');

		if (this.locations)
			this[ivalue].workLocations = this.locations.map(workLocation_id => ({ workLocation_id }));

		fixWorkCodeList(this[ivalue]);

		return api.timeCard.save(this[ivalue])
			.then(({ timecard_id }) => api.timeCard.read(timecard_id))
			.then(timeCard => { this.$emit('input', timeCard) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}

	del() {
		if (this.loading || !this.key) return;

		this.loading = true;
		this.$emit('error');

		return api.timeCard.delete(this.key)
			.then(() => { this.$emit('input', null) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}
}

Vue.component('timecard-form', TimecardForm);


function getEmbeddedIds(list, keyProp) {
	if (!list)
		return null;

	return list.map(record => record[keyProp]);
}

function fixWorkCodeList(record) {
	const key = 'timecard__work_codes';
	const timecard_id = record.timecard_id;
	const workCode_id = record.workCode_id;

	if (!timecard_id) return record;

	if (record.workCode)
		record[key] = [Object.assign({ timecard_id }, record.workCode)];

	record.workCode = null;
	if (workCode_id)
		record.workCode = { workCode_id };
	else
		record.workCodes = [];

	return record;
}
