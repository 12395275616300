import service from '../../date-validation.service';

const today = service.today();
const month = '#month';

export default class Calendar {
	constructor() {
		this[month] = getMonth();
	}

	// Core Info
	// --------------------

	get calendar() {
		return getCalendar(this[month]);
	}

	get localeData() {
		return this[month].localeData();
	}

	// Display
	// --------------------

	get weekdays() {
		const dow = this.localeData.firstDayOfWeek();
		const week = this.localeData.weekdaysMin();

		return week.slice(dow).concat(week.slice(0, dow));
	}

	get displayMonth() {
		return this[month].format('MMMM');
	}

	get displayYear() {
		return this[month].format('YYYY');
	}

	// Query
	// --------------------

	isMonth(date) {
		return this[month].isSame(date, 'month');
	}

	isToday(date) {
		return today.isSame(date, 'day');
	}

	// Controls
	// --------------------
	setMonth(date) {
		this[month] = getMonth(date);
	}

	nextMonth() {
		this[month] = this[month].clone().add(1, 'month');
	}

	lastMonth() {
		this[month] = this[month].clone().subtract(1, 'month');
	}
}

function getMonth(date) {
	return service.toMoment(date).startOf('month');
}

function getCalendar(date) {
	const month = date.clone().startOf('month')
		.startOf('week');

	return new Array(6).fill(0)
		.map((_, i) => {
			const week = month.clone().add(i, 'week')
				.startOf('week');

			return new Array(7).fill(0)
				.map((_, j) => week.clone().add(j, 'day')
					.startOf('day'));
		});
}
