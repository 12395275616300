const component = () => import(/* webpackChunkName: "tc-pages" */'./update-timecard');

export default {
	component,
	path  : '/update-timecard/:id',
	name  : 'update-timecard',
	props : true,
	meta  : {
		title  : 'Update Timecard',
		secure : true,
	},
};
