import Vue from 'vue';
import Component from 'vue-class-component';
import template from './time-pair.html';

const props = {
	start    : [String],
	end      : [String],
	progress : Boolean,
};

@Component({
	template,
	props,
})
export default class TimePair extends Vue {}
