import Vue from 'vue';
import Component from 'vue-class-component';
import template from './close-button.html';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	BasicButton,
};
const props = {
	white : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class CloseButton extends Vue {}
