import api from './api';
import company from './company';
import configurations from '@/config/overtime-configurations.json';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	OvertimeConfig : _extend,
};

function list(filters) {
	return filters
		? api.list('overtime-config', filters)
			.then(list => list.map(overtimeConfig => _extend(overtimeConfig)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function find(filters) {
	return list(filters)
		.then(list => list.length === 0 ? [_extend()] : list)
		.then(list => list.length === 1 ? list[0] : null);
}

function read(overtimeConfig_id) {
	return api.read('overtime-config', overtimeConfig_id)
		.then(overtimeConfig => _extend(overtimeConfig));
}

function create(overtimeConfig) {
	return company.get()
		.then(({ company_id }) => company_id)
		.then(company_id => api.create('overtime-config', Object.assign(overtimeConfig, { company_id })));
}

function update(overtimeConfig) {
	return api.update('overtime-config', overtimeConfig.overtimeConfig_id, overtimeConfig);
}

function save(overtimeConfig) {
	return overtimeConfig.overtimeConfig_id ? update(overtimeConfig) : create(overtimeConfig);
}

function _delete(overtimeConfig_id) {
	return api.delete('overtime-config', overtimeConfig_id);
}

function _extend(overtimeConfig = {}) {
	const defaults = getDefaultConfiguration();

	const DAILY_OT_THRESHOLD   = Number.parseFloat(overtimeConfig.DAILY_OT_THRESHOLD, 10);
	const DAILY_DT_THRESHOLD   = Number.parseFloat(overtimeConfig.DAILY_DT_THRESHOLD, 10);
	const WEEKLY_OT_THRESHOLD  = Number.parseFloat(overtimeConfig.WEEKLY_OT_THRESHOLD, 10);
	const SEVENTH_DT_THRESHOLD = Number.parseFloat(overtimeConfig.SEVENTH_DT_THRESHOLD, 10);

	return Object.assign({
		overtimeConfig_id    : null,
		DAILY_OT_THRESHOLD   : null,
		DAILY_DT_THRESHOLD   : null,
		WEEKLY_OT_THRESHOLD  : null,
		SEVENTH_DT_THRESHOLD : null,
	}, overtimeConfig, {
		DAILY_OT_THRESHOLD   : Number.isNaN(DAILY_OT_THRESHOLD) ? defaults.DAILY_OT_THRESHOLD : DAILY_OT_THRESHOLD,
		DAILY_DT_THRESHOLD   : Number.isNaN(DAILY_DT_THRESHOLD) ? defaults.DAILY_DT_THRESHOLD : DAILY_DT_THRESHOLD,
		WEEKLY_OT_THRESHOLD  : Number.isNaN(WEEKLY_OT_THRESHOLD) ? defaults.WEEKLY_OT_THRESHOLD : WEEKLY_OT_THRESHOLD,
		SEVENTH_DT_THRESHOLD : Number.isNaN(SEVENTH_DT_THRESHOLD) ? defaults.SEVENTH_DT_THRESHOLD : SEVENTH_DT_THRESHOLD,
	});
}

function getDefaultConfiguration() {
	const {
		DAILY_OT_THRESHOLD,
		DAILY_DT_THRESHOLD,
		WEEKLY_OT_THRESHOLD,
		SEVENTH_DT_THRESHOLD,
	} = configurations.find(fig => fig.year === new Date().getFullYear()) || {
		// Wage 14, 2022
		DAILY_OT_THRESHOLD   : 8,
		DAILY_DT_THRESHOLD   : 12,
		WEEKLY_OT_THRESHOLD  : 40,
		SEVENTH_DT_THRESHOLD : 8,
	};

	return {
		DAILY_OT_THRESHOLD,
		DAILY_DT_THRESHOLD,
		WEEKLY_OT_THRESHOLD,
		SEVENTH_DT_THRESHOLD,
	};
}
