import Vue from 'vue';
import Component from 'vue-class-component';
import template from './cancellable-spinner.html';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	CancelButton,
	Spinner,
};
const props = {
	delay : {
		type    : [Number, String], // how long to wait before showing cancel button
		default : 3000,
	},
};

@Component({
	template,
	components,
	props,
})
export class CancellableSpinner extends Vue {
	data() {
		return {
			cancellable : null,
		};
	}

	mounted() {
		setTimeout(() => {
			this.cancellable = true;
		}, this.delay);
	}
}

Vue.component('cancellable-spinner', CancellableSpinner);
