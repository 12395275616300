const component = () => import(/* webpackChunkName: "test-pages" */'./color-picker-test');

export default {
	component,
	path : 'color-picker',
	name : 'test_color-picker-test',
	meta : {
		title  : 'Color Picker Test',
		secure : false,
	},
};
