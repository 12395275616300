import component from './create-purchase-order';

export default {
	component,
	path  : '/new-purchase-order/:type?',
	name  : 'create-purchase-order',
	props : true,
	meta  : {
		title  : 'Create Purchase Order',
		secure : true,
	},
};
