import api from './api';
import company from './company';

export default {
	read,
	create,
	delete   : _delete,
	manage   : getManageUrl,
	checkout : getCheckoutUrl,

	Billing : _extend,
};

function read(filters) {
	return filters
		? api.get('billing', filters)
			.then(billing => _extend(billing))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? read({ company_id }) : null);
}

function create(record) {
	return record && record.company_id
		? api.create('billing', record)
			.then(billing => _extend(billing))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? create(Object.assign({}, record, { company_id })) : null);
}

function _delete(company_id) {
	if (!company_id)
		return company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? _delete(company_id) : null);

	return api.post('billing/remove-account', { company_id });
}

function getManageUrl(company_id) {
	if (!company_id)
		return company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? getManageUrl(company_id) : null);

	return api.resolveUrl('billing/billing-portal', { company_id });
}

function getCheckoutUrl(company_id) {
	if (!company_id)
		return company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? getCheckoutUrl(company_id) : null);

	return api.resolveUrl('billing/start-checkout', { company_id });
}

function _extend(billing = {}) {
	const needs = billing.accountType === 'paid';
	const has = Boolean(billing.stripe_customer_id && billing.stripe_subscription_id);

	return Object.assign({
		ok : needs ? has : true,
	}, billing);
}
