import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-input-row.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { AddCompanyButton } from '@/buttons/add-company-button/add-company-button';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { Spinner } from '@/base-components/spinner/spinner';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextInput } from '@/inputs/text-input/text-input';

const components = {
	AddCompanyButton,
	CancelButton,
	ErrorDisplay,
	Spinner,
	SubmitButton,
	TextInput,
};
const props = {
};

@Component({
	template,
	components,
	props,
})
export class CompanyInputRow extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			company : null,
		};
	}

	@Watch('company')
	watchCompany() {
		this.error = null;
	}

	start() {
		this.company = Object.assign(
			{},
			new api.company.Company(),
			new api.workZone.WorkZone()
		);
	}

	submit() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		return Promise.resolve(this.company)
			.then(company => Promise.all([
				api.company.create(company),
				company,
			]))
			.then(([company, workZone]) => Promise.all([
				company,
				api.workZone.create(Object.assign(workZone, {
					company_id : company.company_id,
				})),
			]))
			.then(([company]) => company)
			.then(company => { this.$emit('input', company) })
			.then(() => { this.company = null })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('company-input-row', CompanyInputRow);
