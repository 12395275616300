import Vue from 'vue';
import Component from 'vue-class-component';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

const props = {
	value     : [Object, Array, String],
	showZero  : Boolean,
	zeroLabel : String,
};

@Component({
	template : '<span>{{ display }}</span>',
	props,
})
export class EmployeeDisplay extends Vue {
	get length() {
		if (!this.value) return 0;

		return Array.isArray(this.value)
			? this.value.length
			: 1;
	}

	@AsyncLoading
	get record() {
		const employee = Array.isArray(this.value)
			? this.value[0]
			: this.value;

		if (typeof employee !== 'string' || !employee)
			return employee;

		return api.employee.read(employee)
			.catch(() => null);
	}

	get name() {
		return this.record && this.record.employeeName;
	}

	get display() {
		const length = this.length;

		if (this.loading) return '';

		if (length === 0) return this.showZero
			? this.zeroLabel || `No ${this.$dsl('Employees')}`
			: '';

		if (length > 1) return `${length} ${this.$dsl('Employees')}`;

		return this.name;
	}
}

Vue.component('employee-display', EmployeeDisplay);
