const component = () => import(/* webpackChunkName: "test-pages" */'./purchaseorder-generator');

export default {
	component,
	path : 'purchaseorder-generator',
	name : 'purchaseorder-generator-test',
	meta : {
		title  : 'Purchaseorder Generator Test',
		secure : false,
	},
};
