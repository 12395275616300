import Vue from 'vue';
import Component from 'vue-class-component';
import template from './dropdown-input.html';

const props = {
	value : Object, // the currently selected item.
	list  : Array, // the list of dropdown items. { icon, color, [label] }
	type  : String, // what to show when nothing is selected

	label : { // which field stores the label to use in the dropdown
		type    : String,
		default : 'label',
	},

	id : { // which field represents the id of the item
		type : String,
		default() {
			return this.label;
		},
	},

	mobileIcon : String, // what to show when collapsed for mobile

	loading  : Boolean,
	primary  : Boolean,
	readonly : Boolean,
	required : Boolean,
};

@Component({
	template,
	props,
})
export class DropdownInput extends Vue {
	get placeholder() {
		return this.value ? this.value[this.label] : 'No selection';
	}

	get icon() {
		return this.value ? this.value.icon : null;
	}

	get selection() {
		return this.value ? this.value[this.id] : null;
	}

	set selection(selection) {
		const input = this.list.find(item => item[this.id] === selection);

		this.$emit('input', input);
	}

	get staticSelection() {
		return this.value && this.list.every(item => item[this.id] !== this.value[this.id]);
	}
}

Vue.component('dropdown-input', DropdownInput);
