import component from './dashboard';

export default {
	component,
	path : '/dashboard',
	name : 'dashboard',
	meta : {
		title  : 'Dashboard',
		secure : true,
	},
};
