import Vue from 'vue';
import Component from 'vue-class-component';
import template from './hours-summary.html';

const props = {
	list    : Array,
	filters : Object,
};

@Component({
	template,
	props,
})
export default class HoursSummary extends Vue {
	get reg() {
		return collect(this.list, 'regularHours');
	}

	get ot() {
		return collect(this.list, 'overtimeHours');
	}

	get dt() {
		return collect(this.list, 'doubletimeHours');
	}

	get holiday() {
		return collect(this.list, 'holidayHours');
	}

	get sick() {
		return collect(this.list, 'sickHours');
	}

	get quarantine() {
		return collect(this.list, 'quarantineHours');
	}

	get cols() {
		// Columns before hours begin
		const len = 13;

		const rem = this.filters ? [
			'employee_id',
			'workCode_id',
			'workLocation_id',
		].map(key => this.filters[key] ? 1 : 0).reduce((a, b) => a + b)
			: 0;

		return len - rem;
	}
}

function collect(list, keyprop) {
	return list && list.reduce((acc, next) => acc + (next[keyprop] || 0), 0);
}
