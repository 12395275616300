const component = () => import(/* webpackChunkName: "test-pages" */'./buttons');

export default {
	component,
	path : 'buttons',
	name : 'buttons-test',
	meta : {
		title  : 'Buttons',
		secure : false,
	},
};
