const component = () => import(/* webpackChunkName: "settings-pages" */'./users');

export default {
	component,
	path : 'users',
	name : 'users',
	meta : {
		title  : 'Users',
		secure : true,
	},
};
