import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-form.html';
import api from '@/api';

import { BasicInput } from '@/base-components/basic-input/basic-input';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { DateInput } from '@/inputs/date-input/date-input';
import { DeleteButton } from '@/buttons/delete-button/delete-button';
import { EmployeeDropdown } from '@/components/employee-dropdown/employee-dropdown';
import { NumericInput } from '@/inputs/numeric-input/numeric-input';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextareaInput } from '@/inputs/textarea-input/textarea-input';
import { WorkCodeDropdown } from '@/components/work-code-dropdown/work-code-dropdown';
import { WorkLocationDropdown } from '@/components/work-location-dropdown/work-location-dropdown';
import { WorkOrderStatusDropdown } from '@/components/work-order-status-dropdown/work-order-status-dropdown';

const components = {
	BasicInput,
	CancelButton,
	DateInput,
	DeleteButton,
	EmployeeDropdown,
	NumericInput,
	SubmitButton,
	TextareaInput,
	WorkCodeDropdown,
	WorkLocationDropdown,
	WorkOrderStatusDropdown,
};
const props = {
	workOrder : Object, // Work Order record
};

@Component({
	template,
	components,
	props,
})
export class WorkOrderForm extends Vue {
	data() {
		return {
			value     : new api.workOrder.WorkOrder(),
			locations : null,
			loading   : false,
		};
	}

	mounted() {
		this.locations = getLocationId(this.workOrder && this.workOrder.workLocations);

		Object.assign(this.value, this.workOrder, this.workOrder && {
			workZone_id : this.workOrder.workZone && this.workOrder.workZone.workZone_id,
			workCode_id : this.workOrder.workCode && this.workOrder.workCode.workCode_id,
			employee_id : this.workOrder.employee && this.workOrder.employee.employee_id,
		});
	}

	get workOrder_id() {
		return this.value.workOrder_id || null;
	}

	deleteWorkOrder() {
		if (!this.workOrder_id) return;
		if (this.loading) return;

		this.loading = true;
		this.$emit('error');

		const workOrder_id = this.workOrder_id;

		return api.workOrder.delete(workOrder_id)
			.then(() => { this.$emit('input', null) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}

	saveWorkOrder() {
		if (this.loading) return;

		this.loading = true;
		this.$emit('error');

		if (this.locations)
			this.value.workLocations = this.locations.map(workLocation_id => ({ workLocation_id }));

		return api.workOrder.save(this.value)
			.then(({ workOrder_id }) => api.workOrder.read(workOrder_id))
			.then(workOrder => { this.$emit('input', workOrder) })
			.catch(error => { this.$emit('error', error) })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-order-form', WorkOrderForm);


function getLocationId(workLocation) {
	if (!workLocation)
		return null;

	return workLocation.map(location => location.workLocation_id);
}
