// import api from './api';

export default {
	list,
	listSync,
	// find,
	// read,
	// create,
	// update,
	// save,
	// delete : _delete,

	// WorkOrderStatus : _extend,
};

function listSync() {
	return [{
		label : 'Planned',
		value : 'open',
	}, {
		label : 'Suspended',
		value : 'suspended',
	}, {
		label : 'In Progress',
		value : 'in progress',
	}, {
		label : 'Billed',
		value : 'billed',
	}, {
		label  : 'Complete',
		value  : 'complete',
		closes : true,
	}];
}

function list() {
	return Promise.resolve(listSync());
	// return api.list('work-order-status', filters)
	//	 .then(list => list.map(workOrderStatus => _extend(workOrderStatus)));
}

// function find(filters) {
// 	return list(filters).then(list => list.length === 1 ? list[0] : null);
// }

// function read(workOrderStatus_id) {
// 	return api.read('work-order-status', workOrderStatus_id)
// 		.then(workOrderStatus => _extend(workOrderStatus));
// }

// function create(workOrderStatus) {
// 	return api.create('work-order-status', workOrderStatus);
// }

// function update(workOrderStatus) {
// 	return api.update('work-order-status', workOrderStatus.workOrderStatus_id, workOrderStatus);
// }

// function save(workOrderStatus) {
// 	return workOrderStatus.workOrderStatus_id ? update(workOrderStatus) : create(workOrderStatus);
// }

// function _delete(workOrderStatus_id) {
// 	return api.delete('work-order-status', workOrderStatus_id);
// }

// function _extend(workOrderStatus = {}) {
// 	return Object.assign({

// 	}, workOrderStatus);
// }
