import Vue from 'vue';
import Component from 'vue-class-component';
import template from './modal-verification.html';

const props = {
	title  : String,
	submit : {
		type     : Function,
		required : true,
	},
	cancel : {
		type     : Function,
		required : true,
	},
	primaryButton : {
		type      : Object,
		validator : value => typeof value.type === 'string',
		default() {
			return {
				type  : 'is-primary',
				label : 'Yes',
			};
		},
		structure : {
			type : {
				type     : String,
				required : true,
			},
			label : {
				type    : String,
				default : 'Yes',
			},
		},
	},
	content : {
		type     : String,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class ModalVerification extends Vue {
	get type() {
		return this.primaryButton.type;
	}

	get label() {
		return this.primaryButton.label || props.primaryButton.structure.label.default;
	}
}
