import Vue from 'vue';
import Component from 'vue-class-component';
import template from './company-menu-header.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import service from '@/services/modal.service';
import { isDemoUser } from '@/services/permission.service';

@Component({ template })
export class CompanyMenuHeader extends Vue {
	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get companyCount() {
		return api.company.count()
			.then(({ count }) => count);
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}

	switchCompany() {
		this.$emit('close');

		return service.launchModal('select-company')
			.catch(() => null);
	}
}

Vue.component('company-menu-header', CompanyMenuHeader);
