import deltaTime from './delta-time.service';

const defaultConfiguration = {
	DAILY_OT_THRESHOLD   : 8,
	DAILY_DT_THRESHOLD   : 12,
	SEVENTH_DT_THRESHOLD : 8,
	WEEKLY_OT_THRESHOLD  : 40,
};

export default {
	// Weekly Calcuations
	getWeekly(weeklyHours, configuration) {
		return calculateWeekly(weeklyHours, parseConfig(configuration));
	},
	getTimesheetHours(timesheet, configuration) {
		const timecards = Array.isArray(timesheet) ? timesheet : timesheet.timeCards;
		const hoursWorked = timecards.map(card => deltaTime.getTotalHours(card));
		const calculatedHours = calculateWeekly(hoursWorked, parseConfig(configuration));

		return timecards.map((card, index) => Object.assign(card, {
			regularHours    : calculatedHours[index].regular,
			overtimeHours   : calculatedHours[index].overtime,
			doubletimeHours : calculatedHours[index].double,
		}));
	},

	// Daily Calculations
	getDaily(dailyHours, configuration) {
		const {
			DAILY_OT_THRESHOLD,
			DAILY_DT_THRESHOLD,
		} = parseConfig(configuration);

		return calculateDaily(dailyHours, DAILY_OT_THRESHOLD, DAILY_DT_THRESHOLD);
	},
	getTimecardHours(timecard, configuration) {
		const {
			DAILY_OT_THRESHOLD,
			DAILY_DT_THRESHOLD,
		} = parseConfig(configuration);
		const hours = deltaTime.getTotalHours(timecard);
		const [
			regularHours,
			overtimeHours,
			doubletimeHours,
		] = calculateDaily(hours, DAILY_OT_THRESHOLD, DAILY_DT_THRESHOLD);

		return Object.assign(timecard, {
			regularHours,
			overtimeHours,
			doubletimeHours,
		});
	},
};

function parseConfig(configuration) {
	if (typeof configuration !== 'object')
		return Object.assign({}, defaultConfiguration);

	return Object.assign({}, defaultConfiguration, configuration);
}

function usage(total = 0, low = 8, med = 4) { // eslint-disable-line max-statements
	return [
		total < low
			? total
			: low,
		total > (low + med)
			? med
			: Math.max(0, total - low),
		Math.max(0, total - low - med),
	];
}

function calculateDaily(hours, thm, thh) {
	const low = thm;
	const med = thh === null
		? Infinity
		: thh - thm;

	return usage(hours, low, med);
}

function calculateWeekly(week, config) {
	const {
		DAILY_OT_THRESHOLD,
		DAILY_DT_THRESHOLD,
		SEVENTH_DT_THRESHOLD,
		WEEKLY_OT_THRESHOLD,
	} = config;

	let totalRegularHours = 0;
	let totalHours = 0;
	let daysWorked = 0;
	let daysMoreThanSix = 0;

	return week.map(hours => { // eslint-disable-line complexity
		if (hours > 0)
			daysWorked++;

		if (hours > 6)
			daysMoreThanSix++;

		totalHours += hours;

		const isSeventh = daysWorked === 7 && (daysMoreThanSix !== 0 || totalHours > 30);
		const [regular, overtime, double] = isSeventh
			? calculateDaily(hours, 0, SEVENTH_DT_THRESHOLD)
			: calculateDaily(hours, DAILY_OT_THRESHOLD, DAILY_DT_THRESHOLD);

		let adjustedOvertime = 0;

		if (regular + totalRegularHours > WEEKLY_OT_THRESHOLD)
			adjustedOvertime = regular + totalRegularHours - WEEKLY_OT_THRESHOLD;


		totalRegularHours += regular - adjustedOvertime;

		return {
			worked   : hours,
			regular  : regular - adjustedOvertime,
			overtime : overtime + adjustedOvertime,
			double,
		};
	});
}
