/* eslint-disable max-lines */

import Vue from 'vue';
import Component from 'vue-class-component';
import template from './date-input-modal.html';

const daysPerWeek = 7; // In case it ever changes again (thanks a lot, Constantine).

import { ModalLayout } from '@/layouts/modal-layout/modal-layout';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { SubmitButton } from '@/base-components/submit-button/submit-button';

const components = {
	ModalLayout,
	BasicButton,
	CancelButton,
	SubmitButton,
};

const props = {
	value : [Date, String],
	label : String,
	min   : Date,
	max   : Date,

	position : {
		type    : String, // ['left', 'right'], which side to appear on
		default : 'left',
	},
};

@Component({
	template,
	components,
	props,
})
export class DateInputModal extends Vue {
	data() {
		const date = this.value ? new Date(this.value) : new Date();

		date.setHours(0, 0, 0, 0);

		return {
			calendar : timestamp(this.value),
			date,
		};
	}


	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	get displayValue() {
		if (!this.date) return this.date;

		const date = typeof this.date === 'string' ? new Date(this.date) : this.date;

		return date.toLocaleDateString();
	}

	get element() {
		return this.$refs.input;
	}

	get calendarDate() {
		const date = new Date(this.calendar);

		return date;
	}

	get day() {
		return this.calendarDate.getDate();
	}

	get month() {
		return this.calendarDate.getMonth();
	}

	get monthName() {
		const language = navigator.userLanguage || navigator.language;

		return this.calendarDate.toLocaleString(language, { month : 'long' });
	}

	get year() {
		return this.calendarDate.getFullYear();
	}

	get firstCalendarDay() {
		const date = new Date(this.calendar);

		date.setDate(1);

		return date.getDay();
	}

	get lastCalendarDay() {
		const date = new Date(this.calendar);

		date.setMonth(date.getMonth() + 1, 0);

		return date.getDate();
	}

	get prevMonth() {
		const date = new Date(this.calendar);

		date.setMonth(date.getMonth() - 1, 1);

		return date;
	}

	get prevMonthEnd() {
		const date = new Date(this.calendar);

		date.setDate(0);

		return date;
	}

	get nextMonth() {
		const date = new Date(this.calendar);

		date.setMonth(date.getMonth() + 1, 1);

		return date;
	}

	get weeks() {
		const weekRange = [
			daysPerWeek - this.firstCalendarDay,
			this.lastCalendarDay - this.nextMonth.getDay(),
		];

		const weekCount = (weekRange[1] - weekRange[0]) / daysPerWeek;

		const weeks = new Array(weekCount).fill()
			.map((_, index) => new Array(daysPerWeek).fill(index * daysPerWeek)
				.map((week, index) => ({
					day   : week + index + 1 + weekRange[0],
					month : this.month,
					year  : this.year,
				})));

		return [
			this.firstCalendarWeek,
			...weeks,
			this.lastCalendarWeek,
		];
	}

	get firstCalendarWeek() {
		const prefix = new Array(this.firstCalendarDay).fill()
			.map((_, index) => ({
				day   : this.prevMonthEnd.getDate() + index + 1 - this.firstCalendarDay,
				month : this.prevMonthEnd.getMonth(),
				year  : this.prevMonthEnd.getFullYear(),
			}));

		const firstWeek = new Array(daysPerWeek - this.firstCalendarDay).fill()
			.map((_, index) => ({
				day   : index + 1,
				month : this.month,
				year  : this.year,
			}));

		return [].concat(prefix, firstWeek);
	}

	get lastCalendarWeek() {
		const lastDay = this.nextMonth.getDay();

		const lastWeek = new Array(lastDay).fill()
			.map((_, index) => ({
				day   : this.lastCalendarDay + index + 1 - lastDay,
				month : this.month,
				year  : this.year,
			}));

		const suffix = lastDay ? new Array(daysPerWeek - lastDay).fill()
			.map((_, index) => ({
				day   : index + 1,
				month : this.nextMonth.getMonth(),
				year  : this.nextMonth.getFullYear(),
			})) : [];

		return [].concat(lastWeek, suffix);
	}

	dateClass(date) {
		const { day, month, year } = this.date ? {
			day   : this.date.getDate(),
			month : this.date.getMonth(),
			year  : this.date.getFullYear(),
		} : {};

		return {
			dim    : date.month !== this.month,
			active : [
				date.day === day,
				date.month === month,
				date.year === year,
			].every(value => value),
		};
	}

	input(value) {
		const date = parse(value);

		if (isNaN(date)) return;

		this.calendar = timestamp(date);
		this.date = date;

		this.date = parse(value);
	}

	change(value) {
		this.date = parse(value);
	}

	select({ year, month, day }) {
		this.date = new Date(year, month, day);
	}

	showPrevMonth() {
		this.calendar = this.prevMonth.getTime();

		this.element.focus();
	}

	showNextMonth() {
		this.calendar = this.nextMonth.getTime();

		this.element.focus();
	}
}

////////

function parse(value) {
	let date = new Date(value);

	// try a little bit to recover date strings
	if (isNaN(date))
		date = new Date(value.replace(/-/g, '/'));

	if (isNaN(date))
		date = new Date(value.replace(/\D+/g, '/'));

	return date;
}

function timestamp(value) {
	const date = value ? new Date(value) : new Date();

	date.setHours(0, 0, 0, 0);

	return date.getTime();
}
