export default {
	'company'       : 'region',
	'work zone'     : 'farm',
	'work location' : 'field',

	'team' : 'ERROR-T',
	'user' : null,

	'email address' : 'email',
	'phone number'  : 'phone',

	'open-menu-icon'  : 'fas fa-chevron-down',
	'close-menu-icon' : 'fal fa-lg fa-times',

	'email-placeholder' : 'your@email.com',

	'benefits-connection-keyword-1' : 'ERROR-4',
	'benefits-connection-keyword-2' : 'ERROR-5',
};
