import Vue from 'vue';
import Component from 'vue-class-component';
import template from './delete-account-form.html';
import api from '@/api';
import { minPasswordLength } from '@/services/password.service.js';

import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { PasswordInput } from '@/inputs/password-input/password-input';
import { SubmitButton } from '@/base-components/submit-button/submit-button';

const action = 'delete';
const components = {
	CancelButton,
	ErrorDisplay,
	PasswordInput,
	SubmitButton,
};

@Component({
	template,
	components,
})
export class DeleteAccountForm extends Vue {
	data() {
		return {
			form : {
				currentPassword : null,
			},
			verifyDelete : false,
			error        : null,
			loading      : null,
			minLength    : minPasswordLength,
		};
	}

	reset() {
		this.form.currentPassword = null;
		this.verifyDelete = false;
		this.error = null;
	}

	submit() {
		if (this.loading) return;

		const password = this.form.currentPassword;

		this.loading = true;
		this.error = null;

		return api.user.get()
			.then(({ user_id }) => api.user.update({
				user_id,
				password,
				action,
			}))
			.then(() => this.reset())
			.then(() => this.$emit('submitted'))
			.catch(error => { this.error = error })
			.finally(() => {
				this.loading = false;
				this.verifyDelete = false;
			});
	}
}

Vue.component('delete-account-form', DeleteAccountForm);
