import Vue from 'vue';
import Component from 'vue-class-component';
import template from './select-company-modal.html';
import api from '@/api';

import { ModalLayout } from '@/layouts/modal-layout/modal-layout';

const components = {
	ModalLayout,
};

@Component({
	template,
	components,
})
export class SelectCompanyModal extends Vue {
	data() {
		return {
			company      : null,
			workZone     : null,
			loading      : null,
			shouldCreate : null,
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	select({ company, workZone }) {
		this.company = company;
		this.workZone = workZone;
	}

	submit() {
		const company = this.company;
		const workZone = this.workZone;

		if (!company || !workZone || this.loading) return;

		this.loading = true;

		return api.company.set(company)
			.then(() => this.shouldCreate
				? api.workZone.create(workZone)
				: api.workZone.set(workZone))
			.then(() => { this.$emit('submit') })
			.finally(() => { this.loading = false });
	}
}
