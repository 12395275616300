import Vue from 'vue';
import Component from 'vue-class-component';
import template from './color-input.html';

const props = {
	value : String,
};

@Component({
	template,
	props,
})
export class ColorInput extends Vue {}

Vue.component('color-input', ColorInput);
