const component = () => import(/* webpackChunkName: "tc-pages" */'./timesheet-reports');

export default {
	component,
	path : '/timesheet-reports',
	name : 'timesheet-reports',
	meta : {
		title  : 'Timesheet Reports',
		secure : true,
	},
};
