import Vue from 'vue';
import Component from 'vue-class-component';
import template from './color-well.html';

const props = {
	value : String, // CSS3 color
};

@Component({
	template,
	props,
})
export class ColorWell extends Vue {
	get style() {
		return {
			background : this.value || 'transparent',
		};
	}
}

Vue.component('color-well', ColorWell);
