import localStorage from '@/services/local-storage.service';

export default {
	namespaced : true,

	state : {
		config : null,
	},

	getters : {
		config(state) {
			if (!state.config)
				state.config = localStorage.get('config');

			return state.config;
		},
	},

	mutations : {
		set(state, { config }) {
			state.config = config || null;

			localStorage.set('config', state.config);

			return state.config;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},
	},
};
