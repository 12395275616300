import Vue from 'vue';
import Component from 'vue-class-component';
import template from './create-purchase-order.html';

import { MainSidebarLayout } from '@/layouts/main-sidebar-layout/main-sidebar-layout';

const components = {
	MainSidebarLayout,
};

const props = {
	type : String,
};

@Component({
	template,
	components,
	props,
})
export default class CreatePurchaseOrder extends Vue {
	data() {
		return {
			error : null,
		};
	}

	resetForm() {
		return this.$router.push({ name : 'purchase-orders-dashboard' });
	}
}
