import api from './api';
import storage from '@/services/storage.service';

const delay = 100;

export default {
	read,
	save,
	set,
};

////////
// EXPORTED FUNCTIONS
//

function read() {
	return defer('read', () => storage.get('config')
		.catch(() => api.read('config')
			.then(config => save(config))));
}

function save(config) {
	Object.assign(save.config, config);

	return defer('save', () => {
		const config = save.config;

		save.config = {};

		return api.create('config', config)
			.then(config => storage.set('config', config));
	});
}
save.config = {};

function set(key, value) {
	return read()
		.then(config => Object.assign(config, {
			[key] : value,
		}))
		.then(config => save(config));
}

////////
// LOCAL FUNCTIONS
//

function defer(name, generator) {
	const cache = defer.cache[name];

	const promise = new Promise(resolve => cache.queue.push({ resolve }));

	if (!cache.timer) cache.timer = setTimeout(() => {
		const queue = cache.queue;

		cache.queue = [];
		cache.timer = null;

		generator()
			.then(config => {
				queue.forEach(({ resolve }) => resolve(config));
			});
	}, delay);

	return promise;
}
defer.cache = {
	read : {
		queue : [],
		timer : null,
	},
	save : {
		queue : [],
		timer : null,
	},
};
