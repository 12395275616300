const component = () => import(/* webpackChunkName: "settings-pages" */'./timesheet-export');

export default {
	component,
	path : 'timesheet-export',
	name : 'timesheet-export',
	meta : {
		title  : 'Timesheet Export',
		secure : true,
	},
};
