import Vue from 'vue';
import Component from 'vue-class-component';
import template from './create-work-order.html';

import { MainSidebarLayout } from '@/layouts/main-sidebar-layout/main-sidebar-layout';

const components = {
	MainSidebarLayout,
};

@Component({
	template,
	components,
})
export default class CreateWorkOrder extends Vue {
	data() {
		return {
			error : null,
		};
	}

	resetForm() {
		return this.$router.push({ name : 'dashboard' });
	}
}
