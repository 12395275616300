const component = () => import(/* webpackChunkName: "tc-pages" */'./timesheet-dashboard');

export default {
	component,
	path : '/timesheet-dashboard',
	name : 'timesheet-dashboard',
	meta : {
		title  : 'Timesheet Dashboard',
		secure : true,
	},
};
