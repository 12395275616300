import Vue from 'vue';
import Component from 'vue-class-component';
import template from './table-column.html';

const props = {
	label    : String, // the column heading
	sortable : String, // what property of the row to sort by
	visible  : { // informs the table if this column should be shown.
		type    : Boolean,
		default : true,
	},
};

@Component({
	template,
	props,
})
export class TableColumn extends Vue {
	created() {
		if (!this.$parent.columns) {
			this.$destroy();
			throw new Error('[table-column] Table-column should be an immediate child of sortable-table');
		}

		const registered = this.$parent.columns.some(({ column_id }) => column_id === this.column_id);

		if (!registered)
			this.$parent.columns.push(this);
	}

	beforeDestroy() {
		if (this.$parent.columns) {
			const index = this.$parent.columns.indexOf(this);

			if (index >= 0)
				this.$parent.columns.splice(index, 1);
		}
	}

	get column_id() {
		return `${this.label}:${this.sortable}`;
	}
}

Vue.component('table-column', TableColumn);
