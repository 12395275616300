import Vue from 'vue';
import Component from 'vue-class-component';
import template from './select-company-input.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { checkCompanyPrivilege } from '@/services/permission.service';

import { BasicSelect } from '@/base-components/basic-select/basic-select';
import { InnerEllipsis } from '@/components/inner-ellipsis/inner-ellipsis';
import { StatusMessage } from '@/components/status-message/status-message';
import { TextInput } from '@/inputs/text-input/text-input';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	BasicSelect,
	InnerEllipsis,
	StatusMessage,
	TextInput,
	Spinner,
};
const props = {
	disabled : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class SelectCompanyInput extends Vue {
	data() {
		return {
			creating : null,
		};
	}

	@Async
	get company() {
		return api.company.get();
	}

	@Async
	get companies() {
		return api.company.list();
	}

	@Async
	get workZone() {
		return api.workZone.get();
	}

	@Async
	get workZones() {
		if (!this.company) return;

		const { company_id } = this.company;

		this.workZones = null;

		return api.workZone.list({ company_id });
	}

	@Async(false)
	get canCreate() {
		if (!this.company) return false;

		return checkCompanyPrivilege('create', this.company);
	}

	get companyList() {
		if (!this.companies) return;

		return this.companies.map(company => ({
			label : company.companyName,
			value : company.company_id,
			company,
		}));
	}

	get workZoneList() {
		if (!this.workZones) return;

		return this.workZones.map(workZone => ({
			label : workZone.workZoneName,
			value : workZone.workZone_id,
			workZone,
		}));
	}

	get company_id() {
		if (!this.company) return null;

		return this.company.company_id;
	}

	set company_id(company_id) {
		if (!this.companyList) return;

		const item = this.companyList.find(({ value }) => value === company_id);

		this.company = item ? item.company : null;
	}

	get workZone_id() {
		if (!this.workZone) return null;

		return this.workZone.workZone_id;
	}

	set workZone_id(workZone_id) {
		if (!this.workZoneList) return;

		const item = this.workZoneList.find(({ value }) => value === workZone_id);

		this.workZone = item ? item.workZone : null;
	}

	@Watch(['company', 'companies'])
	watchCompany() {
		if (!this.company || !this.companies) return;

		this.creating = false;
		this.company = this.companies.find(({ company_id }) => company_id === this.company.company_id) || null;
	}

	@Watch(['workZone', 'workZones'])
	watchWorkZone() {
		if (!this.workZones || this.creating) return;

		const selected_id = this.workZone && this.workZone.workZone_id;

		this.workZone = this.workZones.length === 1
			? this.workZones[0]
			: this.workZones.find(({ workZone_id }) => workZone_id === selected_id) || null;
	}

	@Watch(['company', 'workZone'])
	input() {
		const company = this.company;
		const workZone = this.workZone;
		const payload = {
			company,
			workZone,
		};

		this.$emit('input', payload);
	}

	@Watch('creating')
	watchCreating() {
		this.$emit('create-work-zone', this.creating);
	}

	addWorkZone() {
		this.workZone = new api.workZone.WorkZone();
		this.creating = true;
	}
}

Vue.component('select-company-input', SelectCompanyInput);
