import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-menu.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';
import { isDemoUser } from '@/services/permission.service';
import packageJson from '../../../package.json';

import { DropdownLayout } from '@/layouts/dropdown-layout/dropdown-layout';

const components = {
	DropdownLayout,
};

@Component({
	template,
	components,
})
export class UserMenu extends Vue {
	data() {
		return {
			show : null,
		};
	}

	@Async
	get user() {
		return api.user.get();
	}

	@Async
	get isDemo() {
		return isDemoUser();
	}

	get build() {
		return process.env.DEPLOYMENT;
	}

	get version() {
		return packageJson.version;
	}
}

Vue.component('user-menu', UserMenu);
