import Vue from 'vue';
import Component from 'vue-class-component';
import template from './inner-ellipsis.html';
import Watch from '@/plugins/watch-decorator';

const props = {
	value : {
		type    : String,
		default : '',
	},
	byCharacter : {
		type    : Boolean,
		default : false,
	},
};

@Component({
	template,
	props,
})
export class InnerEllipsis extends Vue {
	data() {
		return {
			prefixArray : [],
			suffixArray : [],
		};
	}

	mounted() {
		this.watchValue();
	}

	get joiner() {
		return this.byCharacter ? '' : ' ';
	}

	get prefix() {
		return this.prefixArray.join(this.joiner);
	}

	get suffix() {
		return this.suffixArray.join(this.joiner);
	}

	@Watch('value')
	watchValue() {
		this.suffixArray = this.value.split(this.joiner);
		this.prefixArray = this.suffixArray.splice(0, 1);
	}

	@Watch('suffix')
	watchSuffix() {
		const container = this.$el.querySelectorAll('.hidden')[0];
		const content = container.children[0];
		const suffixArray = Array.from(this.suffixArray);

		content.innerText = suffixArray.join(this.joiner);

		while (suffixArray.length && content.offsetWidth - container.offsetWidth > 1) {
			this.prefixArray.push(suffixArray.shift());
			content.innerText = suffixArray.join(this.joiner);
		}

		if (this.suffixArray.length !== suffixArray.length) this.suffixArray = suffixArray;
	}
}

Vue.component('inner-ellipsis', InnerEllipsis);
