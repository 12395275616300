const component = () => import(/* webpackChunkName: "settings-pages" */'./overtime-hours');

export default {
	component,
	path : 'overtime-hours',
	name : 'settings/overtime-hours',
	meta : {
		title  : 'Overtime Hours',
		secure : true,
	},
};
