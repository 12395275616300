import Vue from 'vue';
import Component from 'vue-class-component';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

const props = {
	value     : [Object, Array, String],
	showZero  : Boolean,
	zeroLabel : String,
	nameAll   : Boolean,
};

@Component({
	template : '<span>{{ display }}</span>',
	props,
})
export class WorkLocationDisplay extends Vue {
	get length() {
		if (!this.value) return 0;

		return Array.isArray(this.value)
			? this.value.length
			: 1;
	}

	@AsyncLoading
	get record() {
		const location = Array.isArray(this.value)
			? this.value[0]
			: this.value;

		if (typeof location !== 'string' || !location)
			return location;

		if (this.length > 1) return null;

		return api.workLocation.read(location)
			.catch(() => null);
	}

	get name() {
		return this.record && this.record.workLocationName;
	}
	get names() {
		return this.length > 1
			? this.value.map(r => r.workLocationName).join(', ')
			: this.name;
	}

	get display() { // eslint-disable-line 
		const length = this.length;

		if (this.loading) return '';

		if (length === 0) return this.showZero
			? this.zeroLabel || `No ${this.$dsl('Locations')}`
			: '';

		if (length > 1 && this.nameAll) return this.names;
		if (length > 1) return `${length} ${this.$dsl('Locations')}`;

		return this.name;
	}
}

Vue.component('work-location-display', WorkLocationDisplay);
