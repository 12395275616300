import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-work-zone-modal.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

import { ModalLayout } from '@/layouts/modal-layout/modal-layout';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextInput } from '@/inputs/text-input/text-input';
import { CancellableSpinner } from '@/components/cancellable-spinner/cancellable-spinner';

const components = {
	ModalLayout,
	CancelButton,
	SubmitButton,
	TextInput,
	CancellableSpinner,
};

@Component({
	template,
	components,
})
export class AddWorkZoneModal extends Vue {
	data() {
		return {
			loading  : null,
			workZone : {
				workZoneName : '',
			},
			messages : {
				noSlashes : `${this.$dsl('Work Zone')} name cannot contain slashes.`,
				duplicate : `A ${this.$dsl('workZone')} with that name already exists.`,
			},
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}

	@Async([])
	get workZones() {
		this.loading = true;

		return api.workZone.list()
			.finally(() => { this.loading = false });
	}

	get names() {
		return this.workZones.map(({ workZoneName }) => workZoneName);
	}

	validate(value) {
		if (/[/\\]/.test(value)) return this.messages.noSlashes;

		if (this.names.some(name => name.toLowerCase() === value.toLowerCase()))
			return this.messages.duplicate;
	}
}
