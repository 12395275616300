import Vue from 'vue';
import Component from 'vue-class-component';
import template from './accordion-header.html';

const props = {
	value : Boolean, // whether accordion is open
};

@Component({
	template,
	props,
})
export default class AccordionHeader extends Vue {}
