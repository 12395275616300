import Vue from 'vue';
import Component from 'vue-class-component';
import api from '@/api';
import AsyncLoading from '@/plugins/async-loading-decorator';

const props = {
	value : Object,
};
const template = `<div class="company-summary">
	<h2 style="font-size: 1rem; font-weight:bold;">{{ companyLine }}</h2>
	<h3 style="font-size: 1.5rem; font-weight:bold;">{{ locationLine }}</h3>
</div>`;

@Component({
	template,
	props,
})
export class CompanySummary extends Vue {
	@AsyncLoading
	get company() {
		return api.company.get();
	}

	@AsyncLoading
	get workZone() {
		return api.workZone.get();
	}

	get companyLine() {
		return this.company && this.company.companyName;
	}

	get locationLine() {
		return this.workZone && this.workZone.workZoneName;
	}
}

Vue.component('company-summary', CompanySummary);
