const component = () => import(/* webpackChunkName: "test-pages" */'./basic-select');

export default {
	component,
	path : 'basic-select',
	name : 'basic-select-test',
	meta : {
		title  : 'Basic Select Test',
		secure : false,
	},
};
