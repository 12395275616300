import Vue from 'vue';
import Component from 'vue-class-component';
import template from './location.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { DestructoButton } from '@/buttons/destructo-button/destructo-button';
import { Spinner } from '@/base-components/spinner/spinner';
import { WorkLocationDropdown } from '@/components/work-location-dropdown/work-location-dropdown';

const components = {
	BasicButton,
	DestructoButton,
	Spinner,
	WorkLocationDropdown,
};
const props = {
	value : Object, // filters object
	show  : Boolean, // currently showing filter controls
};

@Component({
	template,
	components,
	props,
})
export default class LocationFilter extends Vue {
	data() {
		return {
			filters : null,
		};
	}

	@AsyncLoading
	get locations() {
		return api.workLocation.list();
	}

	get locationName() {
		const { workLocation_id } = this.filters;

		if (!workLocation_id || !this.locations) return;

		const location = this.locations.find(location => location.workLocation_id === workLocation_id);

		if (location) return location.workLocationName;
	}

	@Watch.immediate('value')
	watchValue() {
		if (!this.value) return;

		this.filters = {
			workLocation_id : this.value.workLocation_id,
		};
	}

	@Watch('filters')
	watchFilters() {
		this.$emit('input', this.filters);
	}

	resetLocation() {
		this.$emit('clear', {
			workLocation_id : null,
		});
	}
}
