const card = {
	namespaced : true,

	state : {
		stack   : [],
		visible : true,
		resolve : [],
	},

	getters : {
		current({ stack }) {
			return stack[0] || null;
		},

		visible({ visible, resolve }) {
			return Promise.all(resolve.splice(0).map(resolve => resolve && resolve())).then(() => visible);
		},
	},

	mutations : {
		toggle(state, { resolve }) {
			state.visible = !state.visible;

			state.resolve.push(resolve);
		},

		show(state, { resolve }) {
			state.visible = true;

			state.resolve.push(resolve);
		},

		hide(state, { resolve }) {
			state.visible = false;

			state.resolve.push(resolve);
		},

		push({ stack }, { card }) {
			return stack.unshift(card);
		},

		pop({ stack }) {
			const card = stack.shift() || {};

			if (card.resolve) card.resolve(card.data);

			return stack.length;
		},

		resolve({ stack }, { value }) {
			const card = stack.shift() || {};

			if (card.resolve) card.resolve(value);
		},

		reject({ stack }, { value }) {
			const card = stack.shift() || {};

			if (card.reject) card.reject(value);
		},
	},

	actions : [
		'toggle',
		'show',
		'hide',
		'push',
		'pop',
		'resolve',
		'reject',
	].reduce((actions, action) => Object.assign(actions, {
		[action] : ({ commit }, payload) => Promise.resolve(commit(action, payload)),
	}), {}),
};

export default card;
