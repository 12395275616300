const component = () => import(/* webpackChunkName: "settings-pages" */'./companies');

export default {
	component,
	path : 'companies',
	name : 'companies',
	meta : {
		title       : 'Companies',
		secure      : true,
		skipBilling : true,
	},
};
