import Vue from 'vue';
import Component from 'vue-class-component';
import template from './employee-dropdown.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

const iSelectAll = 'ALL EMPLOYEES';
const iSelectNone = 'DESELECT ALL EMPLOYEES';

import { BasicSelect } from '@/base-components/basic-select/basic-select';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';

const components = {
	BasicSelect,
	Spinner,
	StatusMessage,
};

const props = {
	value     : [String, Array],
	multiple  : Boolean,
	allowAll  : Boolean,
	selectAll : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class EmployeeDropdown extends Vue {
	@AsyncLoading
	get employees() {
		return api.employee.list();
	}

	get additionalOptions() {
		if (!this.employees) return null;
		if (!this.multiple || !this.selectAll) return null;

		const allSelected = this.value && (this.value.length >= this.employees.length);

		return [
			allSelected
				? {
					value : iSelectNone,
					label : 'Deselect All',
				}
				: {
					value : iSelectAll,
					label : 'Select All',
				},
		];
	}

	get list() {
		if (!this.employees) return null;

		this.validateValue();

		const employees = this.employees.map(({
			employeeNumber : id,
			employeeName : name,
			employee_id : value,
		}) => ({
			value,
			label : id
				? `(${id}) ${name}`
				: name,
		}));

		return []
			.concat(this.additionalOptions, employees)
			.filter(Boolean);
	}

	get placeholder() {
		return this.allowAll
			? `All ${this.$dsl('Employees')}`
			: `${this.$dsl('Employee')}`;
	}

	validateValue() {
		const selected = this.value;

		if (!selected) return;

		const employees = this.employees || [];
		const hasValue = this.multiple && Array.isArray(selected)
			? arrayIncludes(selected, employees, (employee_id, employee) => employee.employee_id === employee_id)
			: employees.some(employee => employee.employee_id === selected);

		if (!hasValue)
			this.$emit('input', null);
	}

	select(employee_id, rerun) { // eslint-disable-line complexity
		if (!this.employees) return;

		const specialSelect = !rerun && this.selectAll && Array.isArray(employee_id);

		if (specialSelect && employee_id.includes(iSelectAll))
			return this.select(this.employees.map(employee => employee.employee_id), true);
		if (specialSelect && employee_id.includes(iSelectNone))
			return this.select([], true);


		return this.$emit('input', employee_id);
	}
}

Vue.component('employee-dropdown', EmployeeDropdown);

function arrayIncludes(must, possible, equality = null) {
	const test = equality ? equality : (a, b) => a === b;

	return must.every(item => possible.some(candidate => test(item, candidate)));
}
