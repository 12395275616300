export default {
	namespaced : true,

	state : {
		modal : null,
		queue : [],
	},

	getters : {
		modal(state) {
			return state.modal;
		},
	},

	mutations : {
		push(state, { modal }) {
			state.queue.push(modal);

			const { resolve, reject } = modal;

			delete modal.resolve;
			delete modal.reject;

			modal.submit = value => {
				resolve(value);
				state.modal = state.queue.shift() || null;
			};

			modal.cancel = value => {
				reject(value);
				state.modal = state.queue.shift() || null;
			};

			if (!state.modal) state.modal = state.queue.shift() || null;

			return state.modal;
		},
	},

	actions : {
		push({ commit }, payload) {
			return Promise.resolve(commit('push', payload));
		},
	},
};
