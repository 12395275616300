import Vue from 'vue';
import Component from 'vue-class-component';
import template from './purchase-order-table-paginated.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import modalService from '@/services/modal.service';

import { AsyncPagination, AsyncPaginatedTable } from '@/components/async-paginated-table';
import { EmployeeDisplay } from '@/fragments/employee-display';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';
import { WorkLocationDisplay } from '@/fragments/work-location-display';
import { TableFilters } from '@/components/table-filters/table-filters';
import { CheckboxInput } from '@/inputs/checkbox-input/checkbox-input';
import { IndeterminateCheckboxInput } from '@/inputs/indeterminate-checkbox-input/indeterminate-checkbox-input';
import { DeleteButton } from '@/buttons/delete-button/delete-button';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	AsyncPaginatedTable,
	EmployeeDisplay,
	Spinner,
	StatusMessage,
	WorkLocationDisplay,
	TableFilters,
	CheckboxInput,
	IndeterminateCheckboxInput,
	DeleteButton,
	BasicButton,
};
const props = {
	enableMultiDelete : {
		type : Boolean,
		default() {
			return false;
		},
	},
};

@Component({
	template,
	props,
	components,

})
export class PurchaseOrderTablePaginated extends Vue {
	data() {
		return {
			filters        : {},
			purchaseOrders : new AsyncPagination({
				page      : 1,
				firstPage : 1,
				preload   : false,
				fetch     : page => this.fetchPage(page),
				key(purchaseOrder) {
					return purchaseOrder.purchaseOrder_id;
				},
			}),
			multiDelete : false,
			toRemove    : [],
		};
	}

	@Watch('filters')
	onFiltersChange() {
		this.purchaseOrders
			.fetch(1)
			.catch(() => null);
		this.cancelDelete();
	}

	fetchPage(page) {
		return api.purchaseOrder.paginate(Object.assign({}, this.filters, { page }));
	}

	get tcol() {
		const len = this.multiDelete ? 6 : 5;

		if (!this.filters) return len;

		let rem = this.filters.employee_id && this.filters.type === 'labor' ? 1 : 0;

		if (!this.showWorkLocation)
			rem -= 1;

		return len - rem;
	}
	get showWorkLocation() {
		// Fallback, too early
		if (!this.filters) return true;
		// 0 single
		if (!this.filters.workLocation_id) return true;

		const isArray = Array.isArray(this.filters.workLocation_id);
		const len = isArray ? this.filters.workLocation_id.length : 1;

		if (isArray && len > 1) return true; // many (array)

		return false; // 1 (array), 1 other
	}
	get multiLocation() {
		// Fallback, too early
		if (!this.filters) return false;
		// 0 single
		if (!this.filters.workLocation_id) return false;

		const isArray = Array.isArray(this.filters.workLocation_id);
		const len = isArray ? this.filters.workLocation_id.length : 1;

		if (isArray && len > 1) return true; // many (array)

		return false; // 1 (array), 1 other
	}

	emit(event, value) {
		return this.$emit(event, value);
	}


	isQueuedForDelete(purchaseorder) {
		const purchaseOrder_id = purchaseorder.purchaseOrder_id;
		const index = this.toRemove.indexOf(purchaseOrder_id);

		return index > -1;
	}
	get pageDeleteStatus() {
		if (!this.purchaseOrders) return false;

		if (!this.toRemove.length) return 'none';
		if (this.toRemove.length >= this.purchaseOrders.total) return 'all';

		return 'some';
	}
	set pageDeleteStatus(value) {
		if (!this.purchaseOrders) return;

		if (value === true || value === 'all') {
			const page = this.purchaseOrders.list.map(order => order.purchaseOrder_id);

			this.toRemove = union(this.toRemove, page);
		}

		else if (value === false || value === 'none')
			this.toRemove = [];
	}
	async toggleDelete(purchaseorder) {
		if (this.isQueuedForDelete(purchaseorder))
			return this.unqueueForDelete(purchaseorder);

		return this.queueForDelete(purchaseorder);
	}
	async queueForDelete(purchaseorder) {
		const purchaseOrder_id = purchaseorder.purchaseOrder_id;

		this.toRemove.push(purchaseOrder_id);
	}
	async unqueueForDelete(purchaseorder) {
		const purchaseOrder_id = purchaseorder.purchaseOrder_id;
		const index = this.toRemove.indexOf(purchaseOrder_id);

		if (index > -1)
			this.toRemove.splice(index, 1);
	}
	async onDelete() {
		try {
			const list = this.toRemove;

			if (!list.length) return;

			await modalService.launchModal('verify-deletion', {
				name    : `${this.$dsl('Purchase Orders')}`,
				message : `Are you sure you want to delete ${list.length} ${this.$dsl('Purchase Orders')}?`,
			});

			for (const purchaseOrder_id of list)
				await api.purchaseOrder.destroy(purchaseOrder_id);

			await this.purchaseOrders
				.fetch(1)
				.catch(() => null);
		}
		catch (error) {
			if (error)
				this.error = error;
		}
		finally {
			this.toRemove = [];
			this.multiDelete = false;
		}
	}
	async cancelDelete() {
		this.toRemove = [];
		this.multiDelete = false;
	}
}

Vue.component('purchase-order-table-paginated', PurchaseOrderTablePaginated);

function union(leftArray, rightArray) {
	const missing = rightArray.filter(item => leftArray.indexOf(item) < 0);

	return leftArray.concat(missing);
}
