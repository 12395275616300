const component = () => import(/* webpackChunkName: "settings-pages" */'./settings');

const redirect = { name : 'user-profile' };

import billingSettings from './billing-settings/billing-settings.route';
import changePassword from './change-password/change-password.route';
import companies from './companies/companies.route';
import companyDetails from './company-details/company-details.route';
import deleteAccount from './delete-account/delete-account.route';
import employees from './employees/employees.route';
import exportAccount from './export-account/export-account.route';
import legalConsent from './legal-consent/legal-consent.route';
import overtimeHours from './overtime-hours/overtime-hours.route';
import purchaseOrders from './purchase-orders/purchase-orders.route';
import timesheetExport from './timesheet-export/timesheet-export.route';
import userProfile from './user-profile/user-profile.route';
import users from './users/users.route';
import workCodes from './work-codes/work-codes.route';
import workLocations from './work-locations/work-locations.route';
import workZones from './work-zones/work-zones.route';

const children = [
	billingSettings,
	changePassword,
	companies,
	companyDetails,
	deleteAccount,
	employees,
	exportAccount,
	legalConsent,
	overtimeHours,
	purchaseOrders,
	timesheetExport,
	userProfile,
	users,
	workCodes,
	workLocations,
	workZones,
	{
		path : '*',
		redirect,
	},
];

export const com = {
	route : null,
	title : null,
	push(name, title) {
		this.route = name || null;
		this.title = title || null;
	},
	pull() {
		const name = this.route || null;

		this.route = null;
		this.title = null;

		return name;
	},
};

export default {
	children,
	component,
	redirect,
	path : '/settings',
	name : 'settings',
	meta : {
		title  : 'Settings',
		secure : true,
		demo   : false,
	},
	beforeEnter(to, from, next) {
		com.push(from.name, from.meta.title);

		next();
	},
};
