import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-table-paginated.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import modalService from '@/services/modal.service';

import { AsyncPagination, AsyncPaginatedTable } from '@/components/async-paginated-table';
import { WorkOrderFilters } from '@/components/work-order-filters/work-order-filters';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';
import { WorkLocationDisplay } from '@/fragments/work-location-display';
import { EmployeeDisplay } from '@/fragments/employee-display';
import { StatusDisplay } from '@/fragments/status-display';
import { CheckboxInput } from '@/inputs/checkbox-input/checkbox-input';
import { IndeterminateCheckboxInput } from '@/inputs/indeterminate-checkbox-input/indeterminate-checkbox-input';
import { DeleteButton } from '@/buttons/delete-button/delete-button';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	AsyncPaginatedTable,
	WorkOrderFilters,
	Spinner,
	StatusMessage,
	WorkLocationDisplay,
	EmployeeDisplay,
	StatusDisplay,
	CheckboxInput,
	IndeterminateCheckboxInput,
	DeleteButton,
	BasicButton,
};
const props = {
	enableMultiDelete : {
		type : Boolean,
		default() {
			return false;
		},
	},
};

@Component({
	template,
	components,
	props,
})
export class WorkOrderTablePaginated extends Vue {
	data() {
		return {
			filters    : {},
			workOrders : new AsyncPagination({
				page      : 1,
				firstPage : 1,
				preload   : false,
				fetch     : page => this.fetchPage(page),
				key(workOrder) {
					return workOrder.workOrder_id;
				},
				classlist(workOrder) {
					const statusKey = `wo-status-${dashcase(workOrder.status)}`;

					return [statusKey];
				},
			}),
			multiDelete : false,
			toRemove    : [],
		};
	}

	@Watch('filters')
	onFiltersChange() {
		this.workOrders
			.fetch(1)
			.catch(() => null);
		this.cancelDelete();
	}

	fetchPage(page) {
		return api.workOrder.paginate(Object.assign({}, this.filters, { page }));
	}

	get tcol() {
		const len = this.multiDelete ? 10 : 9;

		if (!this.filters) return len;

		let rem = [
			'employee_id',
			'workCode_id',
			'status',
		].map(key => this.filters[key] ? 1 : 0).reduce((a, b) => a + b);

		if (!this.showWorkLocation)
			rem -= 1;

		return len - rem;
	}

	get classlist() {
		if (!this.filters) return {};

		return {
			'highlight-not-billed' : this.filters.highlightNotBilled,
		};
	}

	get showWorkLocation() {
		// Fallback, too early
		if (!this.filters) return true;
		// 0 single
		if (!this.filters.workLocation_id) return true;

		const isArray = Array.isArray(this.filters.workLocation_id);
		const len = isArray ? this.filters.workLocation_id.length : 1;

		if (isArray && len > 1) return true; // many (array)

		return false; // 1 (array), 1 other
	}
	get multiLocation() {
		// Fallback, too early
		if (!this.filters) return false;
		// 0 single
		if (!this.filters.workLocation_id) return false;

		const isArray = Array.isArray(this.filters.workLocation_id);
		const len = isArray ? this.filters.workLocation_id.length : 1;

		if (isArray && len > 1) return true; // many (array)

		return false; // 1 (array), 1 other
	}

	emit(event, value) {
		return this.$emit(event, value);
	}

	isQueuedForDelete(workorder) {
		const workOrder_id = workorder.workOrder_id;
		const index = this.toRemove.indexOf(workOrder_id);

		return index > -1;
	}
	get pageDeleteStatus() {
		if (!this.workOrders) return false;

		if (!this.toRemove.length) return 'none';
		if (this.toRemove.length >= this.workOrders.total) return 'all';

		return 'some';
	}
	set pageDeleteStatus(value) {
		if (!this.workOrders) return;

		if (value === true || value === 'all') {
			const page = this.workOrders.list.map(order => order.workOrder_id);

			this.toRemove = union(this.toRemove, page);
		}

		else if (value === false || value === 'none')
			this.toRemove = [];
	}
	async toggleDelete(workorder) {
		if (this.isQueuedForDelete(workorder))
			return this.unqueueForDelete(workorder);

		return this.queueForDelete(workorder);
	}
	async queueForDelete(workorder) {
		const workOrder_id = workorder.workOrder_id;

		this.toRemove.push(workOrder_id);
	}
	async unqueueForDelete(workorder) {
		const workOrder_id = workorder.workOrder_id;
		const index = this.toRemove.indexOf(workOrder_id);

		if (index > -1)
			this.toRemove.splice(index, 1);
	}
	async onDelete() {
		try {
			const list = this.toRemove;

			if (!list.length) return;

			await modalService.launchModal('verify-deletion', {
				name    : `${this.$dsl('Work Orders')}`,
				message : `Are you sure you want to delete ${list.length} ${this.$dsl('Work Orders')}?`,
			});

			for (const workOrder_id of list)
				await api.workOrder.destroy(workOrder_id);

			await this.workOrders
				.fetch(1)
				.catch(() => null);
		}
		catch (error) {
			if (error)
				this.error = error;
		}
		finally {
			this.toRemove = [];
			this.multiDelete = false;
		}
	}
	async cancelDelete() {
		this.toRemove = [];
		this.multiDelete = false;
	}
}

Vue.component('work-order-table-paginated', WorkOrderTablePaginated);

function dashcase(value) {
	if (!value) return value;

	return value.replace(/[\W]+/gi, '-');
}

function union(leftArray, rightArray) {
	const missing = rightArray.filter(item => leftArray.indexOf(item) < 0);

	return leftArray.concat(missing);
}
