import Vue from 'vue';
import Component from 'vue-class-component';
import template from './employee-table.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

import { DeleteIconButton } from '@/buttons/delete-icon-button/delete-icon-button';
import { EmployeeInputRow } from '@/components/employee-input-row/employee-input-row';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { SortableTable } from '@/components/sortable-table/sortable-table';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';
import { TableColumn } from '@/components/table-column/table-column';
import { TableStatusMessage } from '@/components/table-status-message/table-status-message';

const components = {
	DeleteIconButton,
	EmployeeInputRow,
	ErrorDisplay,
	SortableTable,
	Spinner,
	StatusMessage,
	TableColumn,
	TableStatusMessage,
};
const props = {};

@Component({
	template,
	components,
	props,
})
export class EmployeeTable extends Vue {
	data() {
		return {
			error : null,
		};
	}

	@AsyncLoading
	get list() {
		return api.employee.list();
	}

	deleteEmployee({ employee_id }) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		const index = this.list.findIndex(employee => employee_id === employee.employee_id);

		return api.employee.delete(employee_id)
			.then(() => { this.list.splice(index, 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('employee-table', EmployeeTable);
