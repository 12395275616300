import localStorage from '@/services/local-storage.service';

export default {
	namespaced : true,

	state : {
		test : null,

		list : null,
		flag : null,
	},

	getters : {
		test(state) {
			if (!state.test) {
				console.log('[test.storage] restoring stored test from localstorage'); // eslint-disable-line no-console

				state.test = localStorage.get('test');
			}

			console.log('[test.storage]', state.test ? 'using stored test' : 'no stored test'); // eslint-disable-line no-console

			return state.test;
		},

		list(state) {
			console.log('[test.storage]', state.flag, state.list ? 'using stored list' : 'no stored list'); // eslint-disable-line no-console

			return flag => !state.flag || state.flag === flag ? state.list : null;
		},
	},

	mutations : {
		set(state, { test }) {
			console.log('[test.storage] storing test in localstorage'); // eslint-disable-line no-console

			state.test = test || null;

			localStorage.set('test', state.test);

			if (!state.test) {
				console.log('[test.storage] resetting list'); // eslint-disable-line no-console

				state.list = null;
				state.flag = null;
			}

			return state.test;
		},

		list(state, { list, flag }) {
			console.log('[test.storage] storing list'); // eslint-disable-line no-console

			state.list = list || null;
			state.flag = flag || null;

			return state.list;
		},

		clear(state) {
			console.log('[test.storage] clearing storage'); // eslint-disable-line no-console

			state.test = null;
			state.list = null;
			state.flag = null;

			localStorage.set('test', null);

			return null;
		},
	},

	actions : {
		set({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('set', payload));
		},

		list({ commit }, payload) {
			return Promise.resolve(payload)
				.then(payload => commit('list', payload));
		},

		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};
