const component = () => import(/* webpackChunkName: "test-pages" */'./test');

import Basicselect from './basic-select/basic-select.route';
import asyncPaginatedTable from './async-paginated-table/async-paginated-table.route';
import avatar from './avatar/avatar.route';
import buttons from './buttons/buttons.route';
import colorPickerTest from './color-picker-test/color-picker-test.route';
import config from './config/config.route';
import dateInput from './date-input/date-input.route';
import datePicker from './date-picker/date-picker.route';
import debounce from './debounce/debounce.route';
import dropdown from './dropdown/dropdown.route';
import errorDisplay from './error-display/error-display.route';
import iconButton from './icon-button/icon-button.route';
import indeterminateCheckboxInput from './indeterminate-checkbox-input/indeterminate-checkbox-input.route';
import leftRightDropdown from './left-right-dropdown/left-right-dropdown.route';
import modals from './modals/modals.route';
import numericInput from './numeric-input/numeric-input.route';
import paginatedTable from './paginated-table/paginated-table.route';
import purchaseorderGenerator from './purchaseorder-generator/purchaseorder-generator.route';
import spinner from './spinner/spinner.route';
import table from './table/table.route';
import timeInput from './time-input/time-input.route';
import timecardGenerator from './timecard-generator/timecard-generator.route';
import userAgent from './user-agent/user-agent.route';
import weeklyCalendar from './weekly-calendar/weekly-calendar.route';
import weeklyOvertimeCalculator from './weekly-overtime-calculator/weekly-overtime-calculator.route';
import workorderGenerator from './workorder-generator/workorder-generator.route';

const children = [
	Basicselect,
	asyncPaginatedTable,
	avatar,
	buttons,
	colorPickerTest,
	config,
	dateInput,
	datePicker,
	debounce,
	dropdown,
	errorDisplay,
	iconButton,
	indeterminateCheckboxInput,
	leftRightDropdown,
	modals,
	numericInput,
	paginatedTable,
	purchaseorderGenerator,
	spinner,
	table,
	timeInput,
	timecardGenerator,
	userAgent,
	weeklyCalendar,
	weeklyOvertimeCalculator,
	workorderGenerator,
];

export default {
	children,
	component,
	path : '/test',
	name : 'test',
	meta : {
		title       : 'Test',
		secure      : false,
		skipBilling : true,
		routes      : children.map(({
			path,
			name,
			meta,
		}) => name && {
			name,
			path,
			title : meta.title,
		})
			.filter(name => name),
	},
};
