import Vue from 'vue';

export default function properCase(value) {
	if (!value) return value;

	return `${value}`
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
		.replace(/([^\w-])([\w-])/g, '$1 $2')
		.replace(/([\w-])([^\w-])/g, '$1 $2')
		.replace(/(?:[A-Z])\w+/g, match => match.toLowerCase())
		.replace(/((^|\s)\w|_id$)/g, match => match.toUpperCase())
		.replace(/[ _]+/g, ' ');
}

Vue.filter('proper-case', properCase);
