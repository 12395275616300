import api from './api';
import company from './company';
import workZone from './work-zone';

export default {
	list,
	paginate,
	listSlow,
	count,
	find,
	read,
	create,
	update,
	save,
	destroy,
	delete : _delete,

	TimeCard : _extend,
};

function list(filters) {
	return filters
		? api.list('timecard', filters, { sortBy : 'date' })
			.then(list => list.map(timeCard => _extend(timeCard)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function paginate(filters) {
	return filters
		? api.noCacheList('timecard/p', filters)
			.then(results => {
				results.list = results.list.map(timeCard => _extend(timeCard));

				return results;
			})
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? paginate({ company_id }) : []);
}

async function listSlow(filters, onWatermark) { // eslint-disable-line
	const results = [];
	const handleMark = typeof onWatermark === 'function' ? onWatermark : async () => null;
	const firstResults = await paginate(Object.assign({}, filters, { page : 1 }));

	results.push(...firstResults.list);
	const pages = firstResults.pages;

	try {
		for (let page = 2; page <= pages; page++) {
			await handleMark();
			const res = await paginate(Object.assign({}, filters, { page }));

			results.push(...res.list);
		}

		return results;
	}
	catch (error) {
		if (error)
			throw error;
	}
}

function count(filters) {
	return filters
		? api.read('timecard/c', '', filters)
			.then(({ count }) => {
				const value = Number.parseInt(count, 10);

				return Number.isNaN(value) ? 0 : value;
			})
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? count({ company_id }) : []);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(timecard_id) {
	return api.read('timecard', timecard_id)
		.then(timeCard => _extend(timeCard));
}

function create(timeCard) {
	return Promise.all([
		company.get(),
		workZone.get(),
		filter(timeCard),
	])
		.then(([
			{ company_id },
			{ workZone_id },
			record,
		]) => Object.assign({
			company_id,
			workZone_id,
		}, record))
		.then(timeCard => api.create('timecard', timeCard));
}

function update(timeCard) {
	return api.update('timecard', timeCard.timecard_id, timeCard);
}

function save(timeCard) {
	return timeCard.timecard_id ? update(timeCard) : create(timeCard);
}

function _delete(timecard_id) {
	return api.delete('timecard', timecard_id);
}

function destroy(timecard_id) {
	return api.destroy('timecard', timecard_id);
}

function _extend(timeCard = {}) { // eslint-disable-line complexity
	return Object.assign({
		// Joins
		employee_id   : null,
		workCode_id   : null,
		workLocations : null,
		workZone_id   : null,

		// Data
		timecard_id   : null,
		date          : zeroHour(),
		timeIn        : null,
		timeOut       : null,
		firstMealIn   : null,
		firstMealOut  : null,
		secondMealIn  : null,
		secondMealOut : null,
		thirdMealIn   : null,
		thirdMealOut  : null,
		firstBreak    : false,
		secondBreak   : false,
		thirdBreak    : false,
		fourthBreak   : false,
		description   : null,
		updatedSig    : null,
	}, timeCard, {
		regularHours    : Number.parseFloat(timeCard.regularHours, 10) || null,
		overtimeHours   : Number.parseFloat(timeCard.overtimeHours, 10) || null,
		doubletimeHours : Number.parseFloat(timeCard.doubletimeHours, 10) || null,
		sickHours       : Number.parseFloat(timeCard.sickHours, 10) || null,
		holidayHours    : Number.parseFloat(timeCard.holidayHours, 10) || null,
		quarantineHours : Number.parseFloat(timeCard.quarantineHours, 10) || null,

	});
}

function zeroHour(date = new Date()) {
	date.setHours(0, 0, 0, 0);

	return date;
}

function filter(record) {
	return Object.keys(record)
		.filter(key => record[key] !== null && record[key] !== undefined) // eslint-disable-line no-undefined
		.reduce((next, key) => Object.assign(next, { [key] : record[key] }), {});
}
