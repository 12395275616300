import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-company-modal.html';

import { ModalLayout } from '@/layouts/modal-layout/modal-layout';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextInput } from '@/inputs/text-input/text-input';

const components = {
	ModalLayout,
	CancelButton,
	SubmitButton,
	TextInput,
};

@Component({
	template,
	components,
})
export class AddCompanyModal extends Vue {
	data() {
		return {
			form : {
				companyName  : '',
				workZoneName : '',
			},
		};
	}

	get listeners() {
		return Object.assign({}, this.$listeners, {
			submit : () => null,
		});
	}
}
