import Vue from 'vue';
import plural from 'pluralize';

export default function pluralize(value, param) {
	const noun = typeof param === 'string' ? param : value;
	const quantity = typeof value === 'number' ? value : param;

	return noun && plural(String(noun), Number(quantity), typeof value === 'number');
}

Vue.filter('pluralize', pluralize);
