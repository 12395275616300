const component = () => import(/* webpackChunkName: "test-pages" */'./modals');

export default {
	component,
	path : 'modals',
	name : 'modals-test',
	meta : {
		title  : 'Modals Test',
		secure : false,
	},
};
