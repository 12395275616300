import Vue from 'vue';
import Component from 'vue-class-component';
import template from './text-input.html';

const props = {
	value       : String,
	label       : String,
	message     : String,
	messageType : String, // ['is-danger', 'is-info', etc]
	validate    : Function,
};

@Component({
	template,
	props,
})
export class TextInput extends Vue {
	get element() {
		return this.$refs.input.$refs.input;
	}

	mounted() {
		if (this.$attrs.hasOwnProperty('autofocus'))
			this.focus();
	}

	focus() {
		this.element.focus();
	}

	blur() {
		this.element.blur();
	}

	setCustomValidity(message) {
		this.element.setCustomValidity(message);
	}

	checkValidity(value) {
		if (this.validate)
			this.setCustomValidity(this.validate(value) || '');
	}
}

Vue.component('text-input', TextInput);
