import Vue from 'vue';
import Component from 'vue-class-component';
import template from './registration-form.html';
import api from '@/api';
import sentry from '@/services/sentry.service';

import { EmailInput } from '@/inputs/email-input/email-input';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { LegalConsentList } from '@/components/legal-consent-list/legal-consent-list';
import { PasswordInput } from '@/inputs/password-input/password-input';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextInput } from '@/inputs/text-input/text-input';

const components = {
	ErrorDisplay,
	TextInput,
	EmailInput,
	PasswordInput,
	LegalConsentList,
	SubmitButton,
};
const props = {
	email    : String,
	readonly : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class RegistrationForm extends Vue {
	data() {
		return {
			alreadyRegistered : null,
			error             : null,
			loading           : false,
			registration      : {
				name           : null,
				email          : this.email || null,
				password       : null,
				legalAgreement : null,
			},
		};
	}

	created() {
		const { email, name } = this.$route.query;
		const form = this.registration;

		if (name) form.name = name;
		if (email && !form.email) form.email = email;
	}

	submit() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;
		this.alreadyRegistered = false;

		return api.session.register(this.registration)
			.then(() => api.legalConsent.create())
			.then(() => this.$emit('login'))
			.catch(error => {
				const badRequest = 400;
				const conflict = 409;
				const customStatus = 1183;
				const status = error.status || customStatus;

				const handler = {
					[badRequest] : () => {
						this.error = `Registration failed. Please check your ${this.$dsl('email address')} and try again.`;
					},
					[conflict] : () => {
						this.alreadyRegistered = true;
					},
				}[status];

				if (handler) return handler();

				this.error = `An unknown error has occurred. Please try again later. [Code: RF.SF-92${status}]`;

				sentry.captureException(error);
			})
			.then(() => { this.loading = false });
	}
}

Vue.component('registration-form', RegistrationForm);
