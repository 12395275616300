import Vue from 'vue';
import Component from 'vue-class-component';
import template from './table-row.html';

const props = {
	label : String,
};

@Component({
	template,
	props,
})
export class TableRow extends Vue {
}

Vue.component('table-row', TableRow);
