import * as Sentry from '@sentry/browser';

export default {
	captureException : capture,
	captureMessage   : capture,
};

function capture(error) {
	if (!error || error.status === -1) return;

	const isError = error instanceof Error || error instanceof ErrorEvent;

	return isError ? captureError(error) : Sentry.captureMessage(getMessage(error));
}

function captureError(error) {
	const ignoredMessages = [
		'No results found', // from parcel search
	];

	if (ignoredMessages.some(message => message === error.message)) return;

	Sentry.captureException(error);
}

function getMessage(error) {
	try {
		return error.message || JSON.stringify(error);
	}

	catch (_) {
		return Object.entries(error)
			.map(([key, value]) => [key, /password/i.test(key) ? '********' : value])
			.map(([key, value]) => `${key}: ${value}`)
			.join(', ');
	}
}
