import Vue from 'vue';
import Component from 'vue-class-component';
import template from './purchase-order-table.html';
import Watch from '@/plugins/watch-decorator';
import { LoadingSignal } from '@/components/async-paginated-table';
import api from '@/api';

import { ErrorDisplay } from '@/components/error-display/error-display';
import { SortableTable } from '@/components/sortable-table/sortable-table';
import { Spinner } from '@/base-components/spinner/spinner';
import { TableColumn } from '@/components/table-column/table-column';
import { TableFilters } from '@/components/table-filters/table-filters';
import { TableStatusMessage } from '@/components/table-status-message/table-status-message';

const components = {
	ErrorDisplay,
	SortableTable,
	Spinner,
	TableColumn,
	TableFilters,
	TableStatusMessage,
};
const props = {};
const filters = {
	perc(value) {
		if (typeof value !== 'number') return value;

		return new Intl.NumberFormat('en-US', {
			style                 : 'percent',
			minimumFractionDigits : 0,
			maximumFractionDigits : 1,
		}).format(value);
	},
};

@Component({
	template,
	components,
	props,
	filters,
})
export class PurchaseOrderTable extends Vue {
	data() {
		return {
			error   : null,
			filters : null, // { company_id, employee_id, startDate, endDate, etc }
			signal  : null,
			list    : null,
		};
	}

	beforeDestroy() {
		this.cancel();
	}

	@Watch('filters')
	async onFiltersChange() {
		try {
			this.error = null;
			await this.fetchAll();
		}
		catch (error) {
			this.error = error;
		}
	}

	async cancel() {
		if (this.signal) {
			this.signal.cancel = true;

			await this.signal.promise
				.catch(() => null);

			this.signal = null;
		}
	}

	async fetchAll() {// eslint-disable-line
		await this.cancel();

		const results = [];
		const signal = new LoadingSignal();

		let page = null;
		let pages = 1;

		this.signal = signal;
		this.list = null;

		try {
			[page, pages] = await this.fetchFirstPage(signal);
			results.push(...page);

			for (let current = 2; current <= pages; current++) {
				page = await this.fetchSeqPage(current, signal);

				if (signal.cancel) throw null; // eslint-disable-line no-throw-literal
				results.push(...page);
			}

			if (signal.cancel) throw null; // eslint-disable-line no-throw-literal

			this.signal = null;
			this.list = results;

			return results;
		}
		catch (error) {
			if (error)
				this.error = null;
		}
		finally {
			signal.resolve();
		}
	}

	async fetchFirstPage(signal) {
		const where = this.createQuery(1, signal);

		return api.purchaseOrder.paginate(where)
			.then(results => {
				if (this.signal)
					this.signal.mark(results.list.length, Number.parseInt(results.total, 10));

				this.$forceUpdate();

				return [results.list, results.pages];
			});
	}

	async fetchSeqPage(page, signal) {
		const where = this.createQuery(page, signal);

		return api.purchaseOrder.paginate(where)
			.then(results => {
				if (this.signal)
					this.signal.mark(results.list.length);

				this.$forceUpdate();

				return results.list;
			});
	}

	createQuery(page, signal) {
		if (!this.filters) return null;

		return Object.assign({}, this.filters, {
			page,
			signal,
			limit : 100,
		});
	}
}

Vue.component('purchase-order-table', PurchaseOrderTable);
