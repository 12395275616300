import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-code-input-row.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { PlusButton } from '@/buttons/plus-button/plus-button';
import { Spinner } from '@/base-components/spinner/spinner';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextInput } from '@/inputs/text-input/text-input';

const components = {
	CancelButton,
	ErrorDisplay,
	PlusButton,
	Spinner,
	SubmitButton,
	TextInput,
};
const props = {};

@Component({
	template,
	components,
	props,
})
export class WorkCodeInputRow extends Vue {
	data() {
		return {
			error    : null,
			loading  : null,
			workCode : null,
		};
	}

	@Watch('workCode')
	watchWorkCode() {
		this.error = null;
	}

	start() {
		this.workCode = new api.workCode.WorkCode();
	}

	submit() {
		if (this.loading) return;

		this.loading = true;
		this.error = false;

		return api.workCode.save(this.workCode)
			.then(workCode => { this.$emit('input', workCode) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-code-input-row', WorkCodeInputRow);
