import { createDecorator } from 'vue-class-component';

export default WatchDecorator;

const timeoutDelay = 125;

/*
Usage:
class MyComponent extends Vue {
	@Watch('property')
	method(value, old) {
		// this.method() will be executed any time 'property' changes
	}

	@Watch(['prop1', 'prop2'])
	method(value, old) {
		// this.method() will be executed any time 'prop1' or 'prop2' changes
	}

	@Watch('property', { immediate : true })
	method(value, old) {
		// this.method() will be executed immediately, and then again any time 'property' changes
	}

	@Watch(['prop1', 'prop2'], { immediate : true })
	method(value, old) {
		// this.method() will be executed immediately, and then again any time 'prop1' or 'prop2' changes
	}

	@Watch.once('property')
	method(value, old) {
		// this.method() will be executed the first time 'property' changes
		// If multiple properties are specified, then this.method() may fire more than once.
	}
}
*/

function WatchDecorator(properties, { deep = true, immediate = false, once = false } = {}) {
	if (!Array.isArray(properties)) properties = [properties];

	if (!properties || !properties.length) throw new Error('@Watch() requires a property to watch');

	const decorator = createDecorator((options, name) => {
		const optionsCreated = options.created;

		options.created = function created() {
			if (optionsCreated) optionsCreated.call(this);

			const unwatch = {};

			let timeout = null;

			const watchProperty = property => {
				unwatch[property] = this.$watch(property, (newVal, oldVal) => {
					clearTimeout(timeout);

					timeout = setTimeout(() => {
						if (once) unwatch[property]();

						options.methods[name].call(this, newVal, oldVal);
					}, timeoutDelay);
				}, {
					deep,
					immediate,
				});
			};

			properties.forEach(property => watchProperty(property));
		};
	});

	return decorator;
}

WatchDecorator.immediate = (properties, config) => new WatchDecorator(properties, Object.assign({ immediate : true }, config));
WatchDecorator.once = (properties, config) => new WatchDecorator(properties, Object.assign({ once : true }, config));
