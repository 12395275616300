import Vue from 'vue';
import Component from 'vue-class-component';
import template from './async-paginated-table.html';
import { AsyncPagination } from './async-pagination.class';

import { Spinner } from '@/base-components/spinner/spinner';
import { IconButton } from '@/buttons/icon-button/icon-button';
import { ErrorDisplay } from '@/components/error-display/error-display';

const props = {
	config : AsyncPagination,
	events : {
		type : [Boolean, Array],
		default() {
			return null;
		},
	},
};

const components = {
	Spinner,
	IconButton,
	ErrorDisplay,
};

@Component({
	template,
	props,
	components,
})
export class AsyncPaginatedTable extends Vue {
	get disabled() {
		if (!this.config) return true;

		return false;
	}
	get loading() {
		if (this.disabled) return;

		return this.config.loading;
	}
	get error() {
		if (this.disabled) return;

		return this.config.error;
	}
	get list() {
		if (this.disabled) return;

		return this.config.list;
	}

	async fetch(page) {
		if (this.disabled) return;

		return this.config.fetch(page).catch(() => null);
	}
	async nextPage() {
		if (this.disabled) return;

		return this.config.next().catch(() => null);
	}
	async previousPage() {
		if (this.disabled) return;

		return this.config.previous().catch(() => null);
	}

	isEventOk(event) {
		if (typeof this.events === 'boolean')
			return this.events;

		const isRequested = this.events && anyValuePresent(this.events, event);
		const isBound = anyKeyPresent(this.$listeners, event);

		return isRequested || isBound;
	}

	get isSelectable() {
		return this.isEventOk('click');
	}

	onEvent(event, value) {
		if (this.isEventOk(event))
			this.$emit(event, value);
	}
}

Vue.component('async-paginated-table', AsyncPaginatedTable);

function anyKeyPresent(source, ...keys) {
	return keys.some(key => key in source);
}
function anyValuePresent(source, ...keys) {
	return keys.some(key => source.includes(key));
}
