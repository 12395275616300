import Vue from 'vue';
import Component from 'vue-class-component';
import template from './paginated-table.html';

const props = {
	list : {
		type     : Array,
		required : true,
	},
	keyProp : String,
};

@Component({
	template,
	props,
})
export class PaginatedTable extends Vue {
	data() {
		return {
			currentPage : 0,
			pageSize    : 10,
			columns     : [],
		};
	}

	updated() {
		this.setPage(this.currentPage);
	}

	get page() {
		return this.list.slice(this.beginIndex, this.endIndex);
	}

	get beginIndex() {
		return this.currentPage * this.pageSize;
	}

	get endIndex() {
		return Math.min(this.beginIndex + this.pageSize, this.totalRecords);
	}

	get totalPages() {
		return Math.ceil(this.totalRecords / this.pageSize);
	}

	get totalRecords() {
		return this.list.length;
	}

	nextPage() {
		this.setPage(this.currentPage + 1);
	}

	previousPage() {
		this.setPage(this.currentPage - 1);
	}

	setPage(index) {
		this.currentPage = clamp(Number(index) || 0, 0, this.totalPages - 1);
	}

	setPageSize(count) {
		this.pageSize = Number(count) || 10;
	}

	key(row) {
		return row[this.keyProp] || null;
	}
}

Vue.component('paginated-table', PaginatedTable);

function clamp(value, min, max) {
	return Math.max(Math.min(value, max), min);
}
