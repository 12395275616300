import api from './api';

export default {
	list,
	create,
	delete : _delete,

	LegalConsent : _extend,
};

function list() {
	return Promise.resolve(api.list('legal-consent'))
		.then(list => list.map(legalConsent => _extend(legalConsent)));
}

function create(legalConsent = '') {
	const legalConsentType = typeof legalConsent === 'string' ? legalConsent : legalConsent.legalConsentType;

	return api.create(`legal-consent/${legalConsentType}`);
}

function _delete(legalConsent_id) {
	return api.destroy('legal-consent', legalConsent_id);
}

function _extend(legalConsent = {}) {
	return Object.assign({

	}, legalConsent);
}
