import api from './api';
import workZone from './work-zone';

export default {
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	WorkLocation : _extend,
};

function list(filters) {
	return filters
		? api.list('work-location', filters)
			.then(list => list.map(workLocation => _extend(workLocation)))
		: workZone.get()
			.then(workZone => workZone ? workZone.workZone_id : null)
			.then(workZone_id => workZone_id ? list({ workZone_id }) : []);
}

function count(filters) {
	return filters
		? api.count('work-location', filters)
		: workZone.get()
			.then(workZone => workZone ? workZone.workZone_id : null)
			.then(workZone_id => workZone_id ? count({ workZone_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(workLocation_id) {
	return api.read('work-location', workLocation_id)
		.then(workLocation => _extend(workLocation));
}

function create(workLocation) {
	return workZone.get()
		.then(({ workZone_id }) => Object.assign({ workZone_id }, workLocation))
		.then(workLocation => api.create('work-location', workLocation));
}

function update(workLocation) {
	return api.update('work-location', workLocation.workLocation_id, workLocation);
}

function save(workLocation) {
	return workLocation.workLocation_id ? update(workLocation) : create(workLocation);
}

function _delete(workLocation_id) {
	return api.delete('work-location', workLocation_id);
}

function _extend(workLocation = {}) {
	return Object.assign({
		workLocationName : '',
		acres            : 0,
	}, workLocation);
}
