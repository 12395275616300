import Vue from 'vue';
import Component from 'vue-class-component';
import template from './email-input.html';
import { BasicInput } from '@/base-components/basic-input/basic-input';
const components = {
	BasicInput,
};

const props = {
	value : String,
};

@Component({
	template,
	components,
	props,
})
export class EmailInput extends Vue {}

Vue.component('email-input', EmailInput);
