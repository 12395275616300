import moment from 'moment';

const locale = 'en-mon';

export default {
	today() {
		return moment().locale(locale);
	},
	toMoment,
	parse,
	validate,
	ymdString,
};

function toMoment(value) {
	if (!value) return moment().locale(locale);

	return moment(value, ['MM/DD/YYYYY', 'YYYY-MM-DD', moment.ISO_8601]).locale(locale);
}

function parse(value, step) {
	const date = toMoment(value);

	return date.isValid()
		? validate(date, step)
		: null;
}

function validate(date, step) {
	if (Number.parseInt(step, 10) === 7)
		return date.startOf('week');

	return date;
}

function ymdString(date) {
	if (!date) return '';
	if (typeof date === 'string')
		return ymdString(toMoment(date));
	if (moment.isMoment(date) && date.isValid())
		return date.format('YYYY-MM-DD');

	return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`;
}

function pad(number) {
	return number.toString(10)
		.padStart(2, '0');
}
