import Vue from 'vue';
import Component from 'vue-class-component';
import template from './primary-navigation.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';
import { isDemoUser, checkCompanyPrivilege, checkWorkZonePrivilege } from '@/services/permission.service';

@Component({
	template,
})
export class PrimaryNavigation extends Vue {
	@AsyncLoading
	get isDemo() {
		return isDemoUser();
	}

	@AsyncLoading
	get company() {
		return api.company.get();
	}

	@AsyncLoading
	get workZone() {
		return api.workZone.get();
	}

	@AsyncLoading(false)
	get showCompanySettings() {
		return checkCompanyPrivilege('update');
	}

	@AsyncLoading(false)
	get showWorkZoneSettings() {
		return checkWorkZonePrivilege('update');
	}
}

Vue.component('primary-navigation', PrimaryNavigation);
