import store from '@/services/storage.service';

export default {
	lock,
	unlock,
	isLocked,
	clear,
};

function lock() {
	return store.set('route/lock');
}

function unlock() {
	return store.set('route/unlock');
}

function isLocked() {
	return store.get('route/isLocked')
		.catch(() => null);
}

function clear() {
	return store.clear('route');
}
