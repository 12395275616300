/* eslint-disable no-magic-numbers */

import md5 from 'md5';

export default {
	generate,
	lighten,
	migrate,
};

function generate(string) {
	const hexLength = 6;
	const maxHex = 0xffffff;
	const degrees = 360;
	const hash = md5(string || '');
	const hex = hash.substr(0, hexLength);
	const angle = parseInt(hex, 16) * degrees / maxHex;

	return `hsl(${angle}, 70%, 50%)`;
}

function lighten(color) {
	if (/^#/.test(color)) return lighten.hex(color);
	if (/^rgb/.test(color)) return lighten.rgb(color);
	if (/^hsl/.test(color)) return lighten.hsl(color);

	return color;
}

lighten.hex = color => {
	const long = color.length > 5;

	const [
		rr,
		gg,
		bb,
		aa,
	] = color.match(long ? /^#(..)(..)(..)(..)?/ : /^#(.)(.)(.)(.)?/)
		.slice(1, 5)
		.map(value => parseInt(value, 16) / (long ? 255 : 15));

	return lighten.hsl(toHsl(rr, gg, bb, aa));
};

lighten.rgb = color => {
	const [
		rr,
		gg,
		bb,
		aa,
	] = color.split(/[(), /]+/)
		.slice(1, 5)
		.map(value => /%/.test(value)
			? parseFloat(value, 10) / 100
			: parseFloat(value, 16) / 255);

	return lighten.hsl(toHsl(rr, gg, bb, aa));
};

lighten.hsl = color => {
	const [
		hh,
		ss,
		ll,
		aa,
	] = color.split(/[(), /]+/)
		.slice(1, 5);

	const lighter = (parseFloat(ll, 10) / (/%/.test(ll) ? 200 : 2)) + 0.5;

	return aa.length
		? `hsl(${hh}, ${ss}, ${lighter.toFixed(4) * 100}%, ${aa})`
		: `hsl(${hh}, ${ss}, ${lighter.toFixed(4) * 100}%)`;
};

function migrate(color = '') {
	return {
		'#A7CC14' : '#A8CA2E',
		'#5DA642' : '#A8CA2E',
		'#30BFA3' : '#39BEA3',
		'#4292A6' : '#39BEA3',
		'#148FCC' : '#2090C9',
		'#1358BF' : '#2090C9',
		'#1313BF' : '#2090C9',
		'#990F90' : '#BC4474',
		'#BF4D7A' : '#BC4474',
		'#992636' : '#DB4B4B',
		'#CC2D14' : '#DB4B4B',
		'#8C410E' : '#FBB03B',
		'#BF6F13' : '#FBB03B',
		'#B28F47' : '#FBB03B',
	}[color && color.toUpperCase()] || color;
}

//////

function toHsl(rr, gg, bb, aa) { // eslint-disable-line complexity, max-params
	// expects params to be in range [0..1] or NaN

	const min = Math.min(rr, gg, bb);
	const max = Math.max(rr, gg, bb);

	const delta = max - min;
	const ll = (min + max) / 2;

	const ss = delta && delta / (1 - Math.abs(1 - (2 * ll)));

	let hh = 0;

	if (delta > 0)
		switch (max) {
			default:
			case rr:
				hh = ((gg - bb) / delta) + (gg < bb ? 6 : 0);
				break;

			case gg:
				hh = ((bb - rr) / delta) + 2;
				break;

			case bb:
				hh = ((rr - gg) / delta) + 4;
				break;
		}

	hh *= 60;

	return isNaN(aa)
		? `hsl(${Math.round(hh)}, ${ss.toFixed(4) * 100}%, ${ll.toFixed(4) * 100}%)`
		: `hsl(${Math.round(hh)}, ${ss.toFixed(4) * 100}%, ${ll.toFixed(4) * 100}%, ${aa.toFixed(2)})`;
}
