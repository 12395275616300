const component = () => import(/* webpackChunkName: "test-pages" */'./debounce');

export default {
	component,
	path : 'debounce',
	name : 'debounce-test',
	meta : {
		title  : 'Debounce Test',
		secure : false,
	},
};
