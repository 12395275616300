import Vue from 'vue';
import Component from 'vue-class-component';
import template from './external-layout.html';

import { ModalContainer } from '@/components/modal-container/modal-container';
import { BrandLogo } from '@/components/brand-logo/brand-logo';

const components = {
	BrandLogo,
	ModalContainer,
};

const props = {
	noLink : {
		type    : Boolean, // do not link the Logo to the Login page.
		default : () => false,
	},
};

@Component({
	template,
	components,
	props,
})
export class ExternalLayout extends Vue {}
