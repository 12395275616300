import Vue from 'vue';
import Component from 'vue-class-component';
import template from './reset-password.html';
import Async from '@/plugins/async-decorator';
import api from '@/api';

import { ExternalLayout } from '@/layouts/external-layout/external-layout';

const components = {
	ExternalLayout,
};

@Component({
	template,
	components,
})
export default class ResetPassword extends Vue {
	data() {
		return {
			error    : null,
			disabled : null,
			reset    : {
				password : null,
			},
		};
	}

	get request_id() {
		return this.$route.params.id;
	}

	@Async()
	get status() {
		return api.passwordReset.read(this.request_id)
			.then(reset => reset.status);
	}

	submit() {
		const request_id = this.request_id;

		return api.passwordReset.update(Object.assign({
			request_id,
		}, this.reset))
			.then(() => this.$router.push({ name : 'login' }))
			.catch(error => { this.error = error });
	}
}
