import Vue from 'vue';
import Component from 'vue-class-component';
import template from './timesheet-table-display.html';
import HoursSummary from './hours-summary/hours-summary';
import TableFiltersDisplay from './table-filters-display/table-filters-display';

const props = {
	reporting : Boolean,
	list      : Array,
	filters   : Object,
};
const components = {
	HoursSummary,
	TableFiltersDisplay,
};

@Component({
	template,
	props,
	components,
})
export class TimesheetTableDisplay extends Vue {
	data() {
		return {
			error : null,
		};
	}
}

Vue.component('timesheet-table-display', TimesheetTableDisplay);
