import Vue from 'vue';
import Component from 'vue-class-component';
import template from './date-input.html';
import Watch from '@/plugins/watch-decorator';
import modalService from '@/services/modal.service';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	BasicButton,
};
const props = {
	value : [Date, String],
	label : String,
	min   : Date,
	max   : Date,
	eod   : Boolean,

	placeholder : {
		type    : String,
		default : 'No date',
	},
};

@Component({
	template,
	components,
	props,
})
export class DateInput extends Vue {
	data() {
		return {
			date : zeroHourDate(this.value, this.eod),
		};
	}

	launchModal() {
		modalService.launchModal('date-input', {
			value : this.date,
			min   : this.min,
			max   : this.max,
		})
			.then(date => {
				this.date = date;

				this.$emit('input', parse(date, this.eod));
			})
			.catch(() => null);
	}

	mounted() {
		if (typeof this.value === 'string' || notZeroHour(this.value, this.eod)) {
			this.date = zeroHourDate(this.value, this.eod);

			this.$emit('input', this.date);
		}
	}

	get displayValue() {
		if (!this.date) return this.date;

		const date = typeof this.date === 'string' ? new Date(this.date) : this.date;

		return date.toLocaleDateString();
	}

	@Watch('value')
	watchValue() {
		if (!isNaN(this.value))
			this.date = zeroHourDate(this.value, this.eod);
	}
}

Vue.component('date-input', DateInput);

////////

function parse(value, eod = false) {
	let date = zeroHourDate(value, eod);

	// try a little bit to recover date strings
	if (isNaN(date))
		date = new Date(value.replace(/-/g, '/'));

	if (isNaN(date))
		date = new Date(value.replace(/\D+/g, '/'));

	return date;
}

function zeroHourDate(string, eod = false) {
	const date = string ? new Date(string) : new Date();

	if (eod)
		date.setHours(23, 59, 59, 999);
	else
		date.setHours(0, 0, 0, 0);

	return date;
}

function notZeroHour(date, eod = false) {
	if (eod)
		return date && date.getHours() !== 23;

	return date && date.getHours() !== 0;
}
