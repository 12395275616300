import Vue from 'vue';
import Component from 'vue-class-component';
import template from './legal-consent-list.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { ErrorDisplay } from '@/components/error-display/error-display';
import { Spinner } from '@/base-components/spinner/spinner';
import { CheckboxInput } from '@/inputs/checkbox-input/checkbox-input';
import { ExternalLink } from '@/base-components/external-link/external-link';

const components = {
	ErrorDisplay,
	Spinner,
	CheckboxInput,
	ExternalLink,
};

@Component({
	template,
	components,
})
export class LegalConsentList extends Vue {
	data() {
		return {
			error : null,
		};
	}

	get allGranted() {
		return this.list && this.list.every(({ legalConsentGranted }) => legalConsentGranted);
	}

	@Async
	get user() {
		return api.user.get();
	}

	@Async
	get list() {
		return api.legalConsent.list()
			.catch(error => { this.error = error });
	}

	@Watch('list')
	watchList() {
		this.$emit('input', this.allGranted);
	}

	saveChange(consent) {
		if (!this.user.user_id) return;

		if (consent.legalConsentGranted)
			this.grant(consent);

		else
			this.revoke(consent);
	}

	grant(consent) {
		this.error = null;

		return api.legalConsent.create(consent)
			.then(record => { Object.assign(consent, record) })
			.catch(error => {
				this.error = error;

				consent.legalConsentGranted = false;
			});
	}

	revoke(consent) {
		this.error = null;

		return api.legalConsent.delete(consent.legalConsent_id)
			.catch(error => {
				this.error = error;

				consent.legalConsentGranted = true;
			});
	}
}

Vue.component('legal-consent-list', LegalConsentList);
