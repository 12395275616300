import Vue from 'vue';
import Component from 'vue-class-component';
import Watch from '@/plugins/watch-decorator';
import template from './template.html';
import service from '../../date-validation.service';

const ivalue = '#value';
const props = {
	value    : String,
	step     : [Number, String],
	min      : String,
	max      : String,
	required : Boolean,
};

@Component({
	template,
	props,
})
export default class NativeDatePicker extends Vue {
	data() {
		return {
			[ivalue] : null,
		};
	}

	mounted() {
		this.matchValue();
	}

	@Watch(['value', 'step'])
	matchValue() {
		if (this[ivalue] && this[ivalue].toISOString() === this.value) return;

		this[ivalue] = service.parse(this.value, this.istep);
	}

	get ivalue() {
		if (!this[ivalue]) return '';

		return service.ymdString(this[ivalue]);
	}

	set ivalue(value) {
		this[ivalue] = service.parse(value, this.istep);
		this.$emit('input', this[ivalue]
			? this[ivalue].toISOString()
			: null);
	}

	get istep() {
		return Number.parseInt(this.step, 10) || 1;
	}

	get imin() {
		return service.ymdString(this.min);
	}

	get imax() {
		return service.ymdString(this.max);
	}

	input(event) {
		this.ivalue = event.target.value;
	}
}
