import Vue from 'vue';
import Component from 'vue-class-component';
import template from './save-button.html';

const delay = 250;

import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { PrimaryButton } from '@/buttons/primary-button/primary-button';

const components = {
	SubmitButton,
	PrimaryButton,
};

const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
	inverted : Boolean,
	type     : {
		type    : String,
		default : 'submit',
	},
};

@Component({
	template,
	components,
	props,
})
export class SaveButton extends Vue {
	data() {
		return {
			locked : true,
		};
	}

	created() {
		this.cycleLock();
	}

	get label() {
		return [
			this.disabled ? 'Save' : null,
			this.loading ? 'Saving' : null,
		].find(label => label) || 'Save';
	}

	click(event) {
		this.cycleLock();

		this.$emit('click', event);
	}

	cycleLock() {
		this.locked = true;

		setTimeout(() => {
			this.locked = false;
		}, delay);
	}
}
