import dsl from '@/services/dsl.service';

export const minPasswordLength = 8;

export default {
	validatePassword,
};

export function validatePassword(password = '') {
	return password.length >= minPasswordLength ? null : getPasswordLengthMessage();
}

export function getPasswordLengthMessage() {
	return `${dsl('Password')} must be at least ${minPasswordLength} characters.`;
}
