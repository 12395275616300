import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-filters.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';
import storage from '@/services/session-storage.service';

import FilterButton from './buttons/filter-button';
import WorkOrderDateRangeFilter from './filters/date-range/date-range';
import WorkOrderEmployeeFilter from './filters/employee/employee';
import WorkOrderLocationFilter from './filters/location/location';
import WorkOrderStatusFilter from './filters/status/status';
import WorkOrderWorkCodeFilter from './filters/work-code/work-code';
import WorkOrderHighlightFilter from './filters/highlight-not-billed/highlight-not-billed';
import { BasicButton } from '@/base-components/basic-button/basic-button';
import { SubmitButton } from '@/base-components/submit-button/submit-button';

const components = {
	FilterButton,
	WorkOrderDateRangeFilter,
	WorkOrderEmployeeFilter,
	WorkOrderLocationFilter,
	WorkOrderStatusFilter,
	WorkOrderWorkCodeFilter,
	WorkOrderHighlightFilter,
	BasicButton,
	SubmitButton,
};

const props = {
	// employee pre-selection
	employee   : Object, // Employee record for pre-selection
	noEmployee : Boolean, // don't show the employee dropdown
};

@Component({
	template,
	components,
	props,
})
export class WorkOrderFilters extends Vue {
	data() {
		return {
			show    : null, // whether to show the filters section
			filters : this.getDefaultFilters(),
		};
	}

	mounted() {
		this.submit();
	}

	@Async
	get workZone() {
		return api.workZone.get();
	}

	@Watch('workZone')
	watchWorkZone() {
		const { workZone_id } = this.workZone;

		this.updateFilter({ workZone_id });
		this.submit();
	}

	getDefaultFilters() {
		const twoWeeks = 14;
		const endDate = new Date();
		const startDate = new Date();
		const filters = this.loadFilters();

		endDate.setHours(0, 0, 0, 0);
		startDate.setHours(0, 0, 0, 0);
		startDate.setDate(endDate.getDate() - twoWeeks);

		return Object.assign({
			employee_id        : this.employee ? this.employee.employee_id : null,
			workZone_id        : this.workZone && this.workZone.workZone_id,
			workLocation_id    : null,
			workCode_id        : null,
			status             : null,
			highlightNotBilled : null,
			startDate,
			endDate,
		}, filters);
	}

	reset() {
		storage.set('work-order-filters', null);

		this.filters = this.getDefaultFilters();

		this.submit();
	}

	submit() {
		this.show = false;

		if (!this.filters.workZone_id) return;

		this.$emit('filter', this.filters);

		this.saveFilters();
	}

	updateFilter(filter) {
		Object.assign(this.filters, filter);
	}

	clearFilter(filter) {
		Object.assign(this.filters, filter);

		this.submit();
	}

	saveFilters() {
		storage.set('work-order-filters', JSON.stringify({
			startDate          : this.filters.startDate,
			endDate            : this.filters.endDate,
			highlightNotBilled : this.filters.highlightNotBilled,
		}));
	}

	loadFilters() {
		const filters = JSON.parse(storage.get('work-order-filters'));

		if (!filters) return filters;

		if (filters.startDate) filters.startDate = new Date(filters.startDate);
		if (filters.endDate) filters.endDate = new Date(filters.endDate);

		return filters;
	}
}

Vue.component('work-order-filters', WorkOrderFilters);
