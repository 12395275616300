import Vue from 'vue';

export default function camelCase(value) {
	if (!value) return value;

	return `${value}`
		.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
		.replace(/([a-z])([A-Z])/g, '$1 $2')
		.replace(/(?:^|[A-Z])[A-Z]+/g, match => match.toLowerCase())
		.replace(/[\W_]\w/g, match => match.toUpperCase())
		.replace(/[\W_]/g, '');
}

Vue.filter('camel-case', camelCase);
