const component = () => import(/* webpackChunkName: "test-pages" */'./timecard-generator');

export default {
	component,
	path : 'timecard-generator',
	name : 'timecard-generator-test',
	meta : {
		title  : 'Timecard Generator Test',
		secure : false,
	},
};
