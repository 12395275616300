import Vue from 'vue';
import Component from 'vue-class-component';
import template from './onboarding-header.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

@Component({ template })
export class OnboardingHeader extends Vue {
	@AsyncLoading
	get workZone() {
		return api.workZone.get();
	}
}

Vue.component('onboarding-header', OnboardingHeader);
