import Vue from 'vue';
import Component from 'vue-class-component';
import template from './radio-input.html';

const props = {
	value : {
		type     : String,
		required : true,
	},
};

@Component({
	template,
	props,
})
export class RadioInput extends Vue {
	get checked() {
		return this.$parent.value === this.value;
	}
}

Vue.component('radio-input', RadioInput);
