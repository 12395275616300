const component = () => import(/* webpackChunkName: "test-pages" */'./async-paginated-table');

export default {
	component,
	path : 'async-paginated-table',
	name : 'async-paginated-table-test',
	meta : {
		title  : 'Async Paginated Table Test',
		secure : false,
	},
};
