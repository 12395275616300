import api from '@/api';

export default {
	isDemoUser,
	checkPrivilege,
	checkWorkZonePrivilege,
	checkCompanyPrivilege,
};

export function isDemoUser() {
	return api.user.get()
		.then(user => user || Promise.reject())
		.then(user => /demo@montage\.ag$/.test(user.email));
}

export function checkPrivilege(action, record) {
	return checkPermissions(action, record, checkWorkZonePrivilege);
}

export function checkWorkZonePrivilege(action, workZone = null) {
	return checkPermissions(action, workZone || api.workZone.get(), checkCompanyPrivilege);
}

export function checkCompanyPrivilege(action, company = null) {
	return checkPermissions(action, company || api.company.get());
}

function checkPermissions(action, record, escalate = false) {
	return Promise.resolve(record)
		.then(record => record || {})
		.then(({ permissions }) => permissions || [])
		.then(permissions => permissions.length
			? permissions.some(({ privileges }) => privileges[action])
			: escalate && escalate(action));
}
