import Vue from 'vue';
import Component from 'vue-class-component';
import template from './date-range.html';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	BasicButton,
};
const props = {
	value : Object, // filters object, { startDate, endDate, ... }
};

@Component({
	template,
	components,
	props,
})
export default class DateRangeFilter extends Vue {
	get dateRangeLabel() { // eslint-disable-line complexity
		if (!this.value) return '';

		const { startDate, endDate } = this.value;

		const language = navigator.userLanguage || navigator.language;

		const formattedStartDate = new Date(startDate).toLocaleDateString(language, { dateStyle : 'medium' });
		const formattedEndDate = new Date(endDate).toLocaleDateString(language, { dateStyle : 'medium' });

		if (startDate && endDate)
			return `Between ${formattedStartDate} and ${formattedEndDate}`;

		if (startDate)
			return `Since ${formattedStartDate}`;

		if (endDate)
			return `Before ${formattedEndDate}`;
	}
}
