import Vue from 'vue';
import Component from 'vue-class-component';
import template from './verify-deletion-modal.html';

import { ModalLayout } from '@/layouts/modal-layout/modal-layout';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { DeleteButton } from '@/buttons/delete-button/delete-button';

const components = {
	ModalLayout,
	CancelButton,
	DeleteButton,
};

const props = {
	collection : { // what type of record is getting deleted
		type     : String,
		requried : true,
	},
	name    : String, // the name of the record, e.g. work zone name, user email
	message : String,
};

@Component({
	template,
	components,
	props,
})
export class VerifyDeletionModal extends Vue {}
