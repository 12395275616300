import Vue from 'vue';
import Component from 'vue-class-component';

const props = {
	value : Boolean,
};

@Component({
	template : `<span>{{ display }}</span>`,
	props,
})
export default class BooleanDisplay extends Vue {
	get display() {
		if (!this.value) return;

		return 'YES';
	}
}
