import Vue from 'vue';
import Component from 'vue-class-component';
import template from './add-work-zone-button.html';
import Async from '@/plugins/async-decorator';
import { checkCompanyPrivilege } from '@/services/permission.service';

import { PlusButton } from '@/buttons/plus-button/plus-button';

const components = {
	PlusButton,
};
const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	components,
	props,
})
export class AddWorkZoneButton extends Vue {
	@Async(false)
	get allowed() {
		return checkCompanyPrivilege('create');
	}
}
