import Vue from 'vue';
import Component from 'vue-class-component';
import template from './filter-button.html';
import { BasicButton } from '@/base-components/basic-button/basic-button';

const components = {
	BasicButton,
};
const props = {
	loading  : Boolean, // temporarily disable the button; waiting for system
	disabled : Boolean, // permanently disable the button; waiting for user
};

@Component({
	template,
	components,
	props,
})
export default class FilterButton extends Vue {}
