import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-code-dropdown.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

import { BasicSelect } from '@/base-components/basic-select/basic-select';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';

const components = {
	BasicSelect,
	Spinner,
	StatusMessage,
};

const props = {
	value    : [String, Array],
	allowAll : Boolean,
	multiple : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class WorkCodeDropdown extends Vue {
	@AsyncLoading
	get workCodes() {
		return api.workCode.list();
	}

	get list() {
		if (!this.workCodes) return;

		this.validateValue();

		return this.workCodes.map(({
			code,
			label,
			workCode_id,
		}) => ({
			label : code
				? `(${code}) ${label}`
				: label,
			value : workCode_id,
		}));
	}

	get placeholder() {
		return this.allowAll
			? `All ${this.$dsl('Work Codes')}`
			: `${this.$dsl('Work Code')}`;
	}

	validateValue() {
		const selected_id = this.value;

		if (!selected_id) return;

		const list = this.workCodes || [];
		const hasValue = this.multiple && Array.isArray(selected_id)
			? arrayIncludes(selected_id, list, (key, workCode) => workCode.workCode_id === key)
			: list.some(code => code.workCode_id === selected_id);

		if (!hasValue)
			this.$emit('input', null);
	}

	select(workCode_id) {
		if (!this.workCodes) return;

		this.$emit('input', workCode_id);
	}
}

Vue.component('work-code-dropdown', WorkCodeDropdown);

function arrayIncludes(must, possible, equality = null) {
	const test = equality ? equality : (a, b) => a === b;

	return must.every(item => possible.some(candidate => test(item, candidate)));
}
