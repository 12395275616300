import Vue from 'vue';
import Component from 'vue-class-component';
import template from './type.html';
import Watch from '@/plugins/watch-decorator';
import { DestructoButton } from '@/buttons/destructo-button/destructo-button';
import { Spinner } from '@/base-components/spinner/spinner';
import { BasicSelect } from '@/base-components/basic-select/basic-select';

const components = {
	DestructoButton,
	Spinner,
	BasicSelect,
};
const props = {
	value : Object, // filters object
	show  : Boolean, // currently showing filter controls
};

@Component({
	template,
	components,
	props,
})
export default class PurchaseOrderTypeFilter extends Vue {
	data() {
		return {
			filters : null,
		};
	}

	get types() {
		return [
			{
				value : 'material',
				label : 'Material',
			},
			{
				value : 'labor',
				label : 'Labor',
			},
		];
	}

	get type() {
		const { type } = this.filters;
		const selected = type && this.types.find(({ value }) => value === type);

		return selected
			? selected.value
			: null;
	}

	get label() {
		const { type } = this.filters;
		const selected = type && this.types.find(({ value }) => value === type);

		return selected
			? selected.label
			: null;
	}

	@Watch.immediate('value')
	watchValue() {
		if (!this.value) return;

		this.filters = {
			type : this.value.type,
		};
	}

	@Watch('filters')
	watchFilters() {
		this.$emit('input', this.filters);
	}

	reset() {
		this.$emit('clear', {
			type : null,
		});
	}
}
