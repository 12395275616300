import Vue from 'vue';
import Component from 'vue-class-component';
import template from './purchase-orders-dashboard.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { MainSidebarLayout } from '@/layouts/main-sidebar-layout/main-sidebar-layout';

const components = {
	MainSidebarLayout,
};

@Component({
	template,
	components,
})
export default class PurchaseOrdersDashboard extends Vue {
	data() {
		return {
			selected : null,
			error    : null,
		};
	}

	@Async
	get workZone() {
		return api.workZone.get();
	}

	@Watch('workZone')
	reset() {
		this.selected = null;
	}

	@Async
	get isCreateDisabled() {
		return this.$billing.shouldBeDisabled();
	}
}
