import Vue from 'vue';
import Component from 'vue-class-component';
import template from './user-table.html';
import Async from '@/plugins/async-decorator';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { DeleteIconButton } from '@/buttons/delete-icon-button/delete-icon-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { SortableTable } from '@/components/sortable-table/sortable-table';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusMessage } from '@/components/status-message/status-message';
import { TableColumn } from '@/components/table-column/table-column';
import { UserInputRow } from '@/components/user-input-row/user-input-row';

const components = {
	DeleteIconButton,
	ErrorDisplay,
	SortableTable,
	Spinner,
	StatusMessage,
	TableColumn,
	UserInputRow,
};

const props = {
	company : Object,
};

@Component({
	template,
	components,
	props,
})
export class UserTable extends Vue {
	data() {
		return {
			error   : null,
			loading : null,
			list    : null,
		};
	}

	@Watch.immediate('company')
	watchCompany() {
		if (this.loading) return;

		this.loading = true;
		this.error = null;
		this.list = null;

		const { company_id } = this.company || {};

		return api.user.list(this.company && { company_id })
			.then(list => { this.list = list })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	@Async
	get user() {
		return api.user.get();
	}

	locked({ user_id }) {
		return this.user.user_id === user_id;
	}

	deleteUser({ user_id }) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		const index = this.list.findIndex(user => user_id === user.user_id);

		return api.user.delete(user_id)
			.then(() => { this.list.splice(index, 1) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}

	submit(user) {
		if (this.loading) return;

		this.loading = true;
		this.error = null;

		const index = this.list.indexOf(this.selected);

		return api.user.save(user)
			.then(user => { this.list.splice(index, 1, user) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('user-table', UserTable);
