import Vue from 'vue';
import Component from 'vue-class-component';
import template from './modal-layout.html';

import { CloseButton } from '@/buttons/close-button/close-button';
import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { SubmitButton } from '@/base-components/submit-button/submit-button';

const components = {
	CloseButton,
	CancelButton,
	SubmitButton,
};

const props = {
	cancellable : Boolean,
	noHeader    : Boolean,
	noFooter    : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class ModalLayout extends Vue {
	dismiss() {
		if (this.cancellable)
			return this.$emit('cancel');
	}

	get showHeader() {
		return !this.noHeader && (this.$slots.header || this.cancellable);
	}
}
