const localStorage
	/* eslint-disable */
	= (function (global) {
		/* Storage Polyfill
			Sources:
				https://gist.github.com/juliocesar/926500#gistcomment-1558590
				https://gist.github.com/juliocesar/926500#gistcomment-2619473
		*/
		try {
			const test = `__localStorage_available__`;

			global.localStorage.setItem(test, test);
			const okay = global.localStorage.getItem(test);

			if (test !== okay) throw new Error();

			global.localStorage.removeItem(test);

			return global.localStorage;
		}
		catch (_) {
			class Storage {
				constructor() { this._data = {} }
				setItem(key, value) { this._data[key] = String(value) }
				getItem(key) { return this._data[key] || null }
				removeItem(key) { delete this._data[key] }
				clear() { this._data = {} }
			}

			return new Storage();
		}
	})(window);
	/* eslint-enable */

export default {
	set(key, value) {
		return localStorage.setItem(key, JSON.stringify(value || null));
	},

	get(key) {
		const value = localStorage.getItem(key);

		return value ? JSON.parse(value) : null;
	},

	clear(key) {
		return key ? this.set(key) : localStorage.clear();
	},
};
