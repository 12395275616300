import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-order-report-table.html';
import Watch from '@/plugins/watch-decorator';
import { LoadingSignal } from '@/components/async-paginated-table';
import api from '@/api';

import { ErrorDisplay } from '@/components/error-display/error-display';
import { SortableTable } from '@/components/sortable-table/sortable-table';
import { Spinner } from '@/base-components/spinner/spinner';
import { StatusDisplay } from '@/fragments/status-display';
import { StatusMessage } from '@/components/status-message/status-message';
import { TableColumn } from '@/components/table-column/table-column';
import { WorkOrderFilters } from '@/components/work-order-filters/work-order-filters';

const components = {
	ErrorDisplay,
	SortableTable,
	Spinner,
	StatusDisplay,
	StatusMessage,
	TableColumn,
	WorkOrderFilters,
};
const filters = {
	perc(value) {
		if (typeof value !== 'number') return value;

		return new Intl.NumberFormat('en-US', {
			style                 : 'percent',
			minimumFractionDigits : 0,
			maximumFractionDigits : 1,
		}).format(value);
	},
};

@Component({
	template,
	components,
	filters,
})
export class WorkOrderReportTable extends Vue {
	data() {
		return {
			error   : null,
			loading : null,

			filters : null,
			list    : null,
			signal  : null,
		};
	}

	beforeDestroy() {
		this.cancel();
	}

	@Watch('filters')
	async onFiltersChange() {
		try {
			this.error = null;
			await this.fetchAll();
		}
		catch (error) {
			this.error = error;
		}
	}

	async cancel() {
		if (this.signal) {
			this.signal.cancel = true;

			await this.signal.promise
				.catch(() => null);

			this.signal = null;
		}
	}

	async fetchAll() {// eslint-disable-line
		await this.cancel();

		const results = [];
		const signal = new LoadingSignal();

		let page = null;
		let pages = 1;

		this.signal = signal;
		this.list = null;

		try {
			[page, pages] = await this.fetchFirstPage(signal);
			results.push(...page);

			for (let current = 2; current <= pages; current++) {
				page = await this.fetchSeqPage(current, signal);

				if (signal.cancel) throw null; // eslint-disable-line no-throw-literal
				results.push(...page);
			}

			if (signal.cancel) throw null; // eslint-disable-line no-throw-literal

			this.signal = null;
			this.list = results;

			return results;
		}
		catch (error) {
			if (error)
				this.error = null;
		}
		finally {
			signal.resolve();
		}
	}

	async fetchFirstPage(signal) {
		const where = this.createQuery(1, signal);

		return api.workOrder.paginate(where)
			.then(results => {
				if (this.signal)
					this.signal.mark(results.list.length, Number.parseInt(results.total, 10));

				this.$forceUpdate();

				return [results.list, results.pages];
			});
	}

	async fetchSeqPage(page, signal) {
		const where = this.createQuery(page, signal);

		return api.workOrder.paginate(where)
			.then(results => {
				if (this.signal)
					this.signal.mark(results.list.length);

				this.$forceUpdate();

				return results.list;
			});
	}

	createQuery(page, signal) {
		if (!this.filters) return null;

		return Object.assign({}, this.filters, {
			page,
			signal,
			limit : 100,
		});
	}

	get colspan() {
		const len = 8;

		if (!this.filters) return len;

		const rem = [
			'workLocation_id',
			'employee_id',
			'workCode_id',
			'status',
		].map(key => this.filters[key] ? 1 : 0).reduce((a, b) => a + b);

		return len - rem;
	}

	get totalHours() {
		if (!this.list) return 0;

		return this.list.map(({ hours }) => Number(hours) || 0).reduce((total, hours) => total + hours);
	}

	get totalAcres() {
		if (!this.list) return 0;

		return this.list.map(({ acres }) => Number(acres) || 0).reduce((total, acres) => total + acres);
	}
}

Vue.component('work-order-report-table', WorkOrderReportTable);
