import api from './api';
import company from './company';

export default {
	list,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	Employee : _extend,
};

function list(filters) {
	return filters
		? api.list('employee', filters)
			.then(list => list.map(employee => _extend(employee)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(employee_id) {
	return api.read('employee', employee_id)
		.then(employee => _extend(employee));
}

function create(employee) {
	return company.get()
		.then(({ company_id }) => Object.assign({ company_id }, employee))
		.then(employee => api.create('employee', employee));
}

function update(employee) {
	return api.update('employee', employee.employee_id, employee);
}

function save(employee) {
	return employee.employee_id ? update(employee) : create(employee);
}

function _delete(employee_id) {
	return api.delete('employee', employee_id);
}

function _extend(employee = {}) {
	return Object.assign({
		employeeName   : null,
		employeeNumber : null,
	}, employee);
}
