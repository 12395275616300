import moment from 'moment';

const locales = [
	['en', {
		weekdaysMin : ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
	}],
	['en-mon', {
		parentLocale : 'en',
		week         : {
			dow : 1,
			doy : 4,
		},
	}],
];

locales
	.forEach(([name, config]) => defineLocaleIfNotExist(name, config));


function defineLocaleIfNotExist(name, config) {
	const locale = moment.locale();

	if (moment.locales().includes(name))
		moment.updateLocale(name, config);
	else
		moment.defineLocale(name, config);


	moment.locale(locale);
}
