import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-zone-dropdown.html';
import AsyncLoading from '@/plugins/async-loading-decorator';
import api from '@/api';

const props = {
	value    : String,
	allowAll : Boolean,
};

@Component({
	template,
	props,
})
export class WorkZoneDropdown extends Vue {
	@AsyncLoading
	get list() {
		return api.workZone.list()
			.then(list => list.map(({
				workZoneName,
				workZone_id,
			}) => ({
				label : workZoneName,
				value : workZone_id,
			})))
			.then(list => this.allowAll ? [{
				label : `All ${this.$dsl('Work Zones')}`,
				value : null,
			}, ...list] : list);
	}
}

Vue.component('work-zone-dropdown', WorkZoneDropdown);
