import Vue from 'vue';
import Component from 'vue-class-component';
import template from './color-picker-dropdown.html';

import { DropdownLayout } from '@/layouts/dropdown-layout/dropdown-layout';
import { BasicButton } from '@/base-components/basic-button/basic-button';
const components = {
	DropdownLayout,
	BasicButton,
};

const props = {
	value    : String,
	readonly : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class ColorPickerDropdown extends Vue {
	data() {
		return {
			open : false,
		};
	}
}

Vue.component('color-picker-dropdown', ColorPickerDropdown);
