const component = () => import(/* webpackChunkName: "test-pages" */'./numeric-input');

export default {
	component,
	path : 'numeric-input',
	name : 'numeric-input-test',
	meta : {
		title  : 'Numeric Input Test',
		secure : false,
	},
};
