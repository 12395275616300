import Vue from 'vue';
import Component from 'vue-class-component';
import template from './id-format-form.html';
import Watch from '@/plugins/watch-decorator';

const iPrefix = '#prefix';
const iCount = '#count';
const iDigits = '#digits';
const iChanged = '#changed';

import { BasicInput } from '@/base-components/basic-input/basic-input';
import { NumericInput } from '@/inputs/numeric-input/numeric-input';
import { SubmitButton } from '@/base-components/submit-button/submit-button';

const components = {
	BasicInput,
	NumericInput,
	SubmitButton,
};

const props = {
	value    : Object,
	loading  : Boolean,
	readonly : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class IdFormatForm extends Vue {
	data() {
		return {
			[iPrefix]  : '',
			[iCount]   : 0,
			[iDigits]  : 4,
			[iChanged] : [],
		};
	}

	mounted() {
		this.matchValue();
	}

	@Watch('value')
	matchValue() {
		if (!this.value) return this.defaults();

		const { count, format } = this.value;
		const prefix = format
			? format.split(/{#+}$/)[0]
			: '';
		const match = format
			? format.match(/{(#+)}$/)
			: null;

		this[iPrefix] = prefix;
		this[iCount] = count || 0;
		this[iDigits] = match
			? match[1].length
			: 0;
		this[iChanged] = [];
	}

	defaults() {
		this[iPrefix] = '';
		this[iCount] = 0;
		this[iDigits] = 4;
		this[iChanged] = [];
	}

	get identity() {
		if (!this.value) return {};

		const { bookName, company_id, bookConfig_id } = this.value;

		return Object.assign(...[
			bookName ? { bookName } : {},
			company_id ? { company_id } : null,
			bookConfig_id ? { bookConfig_id } : null,
		]);
	}

	get text() {
		return this[iPrefix];
	}

	set text(value) {
		this.changed('prefix');
		this[iPrefix] = value;
	}

	get count() {
		return this[iCount];
	}

	set count(value) {
		this.changed('count');
		this[iCount] = value;
	}

	get digits() {
		return this[iDigits];
	}

	set digits(value) {
		this.changed('prefix');
		this[iDigits] = value;
	}

	get prefix() {
		return this.digits > 0
			? `${this.text}{${'#'.repeat(this.digits)}}`
			: `${this.text}`;
	}

	get preview() {
		return render(this.count, this.prefix);
	}

	get dirty() {
		return this[iChanged].length > 0;
	}

	changed(value) {
		if (!this[iChanged].includes(value))
			this[iChanged].push(value);
	}

	cancel() {
		this.matchValue();
	}

	save() {
		const record = this[iChanged].reduce((record, prop) => Object.assign(record, {
			[prop] : this[prop],
		}), {});
		const newValue = Object.assign({}, this.identity, record);

		return this.$emit('input', newValue);
	}
}

Vue.component('id-format-form', IdFormatForm);


function render(number, format = '{####}') {
	return format.replace(/{([#]+)}/g, (_, match) => {
		const length = match.length;
		const modulo = Math.pow(10, length);
		const base = number % modulo;

		return Number.isNaN(base)
			? `${number}`
			: `${base}`.padStart(length, 0);
	});
}
