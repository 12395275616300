import Vue from 'vue';
import Component from 'vue-class-component';
import template from './beta-badge.html';
import packageJson from '../../../package.json';

@Component({ template })
export class BetaBadge extends Vue {
	get build() {
		return process.env.DEPLOYMENT;
	}

	get version() {
		return packageJson.version;
	}
}

Vue.component('beta-badge', BetaBadge);
