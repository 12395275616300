import component from './session';

import createPurchaseOrder from './create-purchase-order/create-purchase-order.route';
import createTimecard from './create-timecard/create-timecard.route';
import createTimesheet from './create-timesheet/create-timesheet.route';
import createWorkOrder from './create-work-order/create-work-order.route';
import dashboard from './dashboard/dashboard.route';
import editTimesheet from './edit-timesheet/edit-timesheet.route';
import purchaseOrderReports from './purchase-order-reports/purchase-order-reports.route';
import purchaseOrdersDashboard from './purchase-orders-dashboard/purchase-orders-dashboard.route';
import settings from './settings/settings.route';
import timesheetDashboard from './timesheet-dashboard/timesheet-dashboard.route';
import timesheetReports from './timesheet-reports/timesheet-reports.route';
import updateTimecard from './update-timecard/update-timecard.route';
import workOrderReports from './work-order-reports/work-order-reports.route';

const children = [
	createPurchaseOrder,
	createTimecard,
	createTimesheet,
	createWorkOrder,
	dashboard,
	editTimesheet,
	purchaseOrderReports,
	purchaseOrdersDashboard,
	settings,
	timesheetDashboard,
	timesheetReports,
	updateTimecard,
	workOrderReports,
];

export default {
	children,
	component,
	path : '/session',
	name : 'session',
	meta : {
		title       : 'Session',
		secure      : true,
		skipBilling : false,
	},
};
