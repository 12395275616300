import api from './api-storage-list';
import company from './company';
import storage from '@/services/storage.service';
import config from './config';

export default {
	set,
	get,
	list,
	count,
	find,
	read,
	create,
	update,
	save,
	delete : _delete,

	WorkZone : _extend,
};

function set(workZone) {
	return Promise.resolve(appendCompany(workZone))
		.then(workZone => Promise.all([workZone, config.read(), workZone && workZone.workZone_id]))
		.then(([workZone, record, workZone_id]) => Promise.all([workZone, Object.assign(record, { workZone_id })]))
		.finally(() => api.resetQueue())
		.then(([workZone, record]) => Promise.all([storage.set('work-zone', workZone), config.save(record)]))
		.then(([workZone]) => workZone);

	function appendCompany(workZone) {
		if (!workZone) return workZone;

		return company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => Object.assign(workZone, { company_id }));
	}
}

function get() {
	return Promise.all([company.get(), storage.get('work-zone')])
		.then(([company, workZone]) => [company ? company.company_id : null, workZone])
		.then(([company_id, workZone]) => company_id === workZone.company_id ? workZone : Promise.reject(null))
		.catch(() => restore());

	function restore() {
		return Promise.all([config.read(), list()])
			.then(([{ workZone_id }, list]) => Promise.all([list.find(workZone => workZone.workZone_id === workZone_id) || list[0]]))
			.then(([restored, workZone]) => restored || set(workZone));
	}
}

function list(filters) {
	return filters
		? api.list('work-zone', filters)
			.then(list => list.map(workZone => _extend(workZone)))
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? list({ company_id }) : []);
}

function count(filters) {
	return filters
		? api.count('work-zone', filters)
		: company.get()
			.then(company => company ? company.company_id : null)
			.then(company_id => company_id ? count({ company_id }) : { count : 0 });
}

function find(filters) {
	return list(filters).then(list => list.length === 1 ? list[0] : null);
}

function read(workZone_id) {
	return api.read('work-zone', workZone_id)
		.then(workZone => _extend(workZone));
}

function create(workZone) {
	return company.get()
		.then(({ company_id }) => company_id)
		.then(company_id => api.create('work-zone', Object.assign(workZone, { company_id })))
		.then(workZone => set(workZone));
}

function update(workZone) {
	return api.update('work-zone', workZone.workZone_id, workZone);
}

function save(workZone) {
	return workZone.workZone_id ? update(workZone) : create(workZone);
}

function _delete(workZone_id) {
	return api.delete('work-zone', workZone_id)
		.then(result => Promise.all([result, get()]))
		.then(([result, workZone]) => [result, workZone ? workZone.workZone_id === workZone_id : null])
		.then(([result, selected]) => selected ? Promise.all([result, set()]) : [result])
		.then(([result]) => result);
}

function _extend(workZone = {}) {
	return Object.assign({
		workZoneName : '',
	}, workZone);
}
