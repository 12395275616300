import Vue from 'vue';
import Component from 'vue-class-component';
import template from './time-input.html';
import Watch from '@/plugins/watch-decorator';
import { IconButton } from '@/buttons/icon-button/icon-button';

const ivalue = '#value';
const DAY = 24;
const HOUR = 60;

const components = {
	IconButton,
};
const props = {
	value : [Date, String],
	label : String,
	step  : {
		type    : [Number, String],
		default : 1,
		validator(value) {
			/* eslint-disable no-magic-numbers*/
			return [1, 15, 30, 60].includes(Number.parseInt(value, 10));
		},
	},
	required : Boolean,
	disabled : Boolean,
};

@Component({
	template,
	components,
	props,
})
export class TimeInput extends Vue {
	data() {
		return {
			[ivalue] : null,
		};
	}

	get id() {
		return `TimeInput${this._uid}`;
	}

	mounted() {
		this.matchValue();
	}

	@Watch('value')
	matchValue() {
		if (this.value === this.output) return;

		const value = fromDate(this.value) || fromString(this.value);

		if (value)
			this[ivalue] = fromParts(value[0], getStep(value[1], this.step));
		else
			this[ivalue] = null;
	}

	get hours() {
		return getHours(this[ivalue]);
	}
	set hours(value) {
		let hour = Number(value);

		if (hour < 0 || isNaN(hour))
			hour = DAY - 1;
		 else
			hour %= DAY;

		this[ivalue] = fromParts(hour, this.minutes);

		this.emitValue();
	}

	get minutes() {
		return getMinutes(this[ivalue]);
	}
	set minutes(value) {
		const minutes = (Number(value) + HOUR) % HOUR;

		this[ivalue] = fromParts(this.hours, minutes);

		this.emitValue();
	}

	get mstep() {
		return Number.parseInt(this.step, 10);
	}

	get output() {
		return this[ivalue];
	}

	set output(timeString) {
		this[ivalue] = timeString;
		this.emitValue();
	}

	emitValue() {
		this.$emit('input', this[ivalue]);
	}

	input(event) {
		this.output = event.target.value;
	}

	focus(event) {
		this.$emit('focus', event);
	}

	blur(event) {
		const expected = getStep(this.minutes, this.step);

		if (expected !== this.minutes)
			this.minutes = expected;

		this.$emit('blur', event);
	}
}

Vue.component('time-input', TimeInput);

function fromParts(hours, minutes) {
	return [
		Number(hours)
			.toString(10)
			.padStart(2, '0'),
		Number(minutes)
			.toString(10)
			.padStart(2, '0'),
	].join(':');
}

function fromString(dateString) {
	if (typeof dateString !== 'string') return null;

	const timeRe = /^((?:[01]?[0-9])|(?:2[0-3])):([0-6][0-9])(am|pm| am| pm)?$/i;
	const value = dateString.toLowerCase().match(timeRe);

	if (!value) return null;

	const afternoon = (value[3] && value[3].trim()) === 'pm';
	const offset = afternoon ? 12 : 0;

	return [
		Number(value[1]) + offset,
		Number(value[2]),
	];
}

function fromDate(date) {
	if (!(date instanceof Date) || isNaN(date.getTime()))
		return null;

	return [
		date.getHours(),
		date.getMinutes(),
	];
}

function getStep(number, step) {
	if (step === 0) return 0;
	if (step === 1) return number;
	if (number % step === 0) return number;

	return (number / step >> 0) * step; // eslint-disable-line no-bitwise
}

function getHours(timeString) {
	if (!timeString) return null;

	return Number.parseInt(timeString.split(':')[0], 10);
}

function getMinutes(timeString) {
	if (!timeString) return null;

	return Number.parseInt(timeString.split(':')[1], 10);
}
