import Vue from 'vue';
import Component from 'vue-class-component';
import template from './page-spinner.html';
import { Spinner } from '@/base-components/spinner/spinner';

const components = {
	Spinner,
};

@Component({
	template,
	components,
})
export class PageSpinner extends Vue {}

Vue.component('page-spinner', PageSpinner);
