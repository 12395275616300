import Vue from 'vue';
import Component from 'vue-class-component';
import template from './request-password-reset.html';
import api from '@/api';
import sentry from '@/services/sentry.service';

import { ExternalLayout } from '@/layouts/external-layout/external-layout';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { EmailInput } from '@/inputs/email-input/email-input';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
const components = {
	ExternalLayout,
	ErrorDisplay,
	EmailInput,
	SubmitButton,
};

@Component({
	template,
	components,
})
export default class RequestPasswordReset extends Vue {
	data() {
		return {
			email     : null,
			error     : null,
			loading   : false,
			notFound  : null,
			submitted : false,
		};
	}

	submit() {
		if (this.loading) return;

		const email = this.email;

		this.loading = true;
		this.error = null;
		this.notFound = false;
		this.submitted = false;

		return api.passwordReset.create({ email })
			.then(() => { this.submitted = true })
			.catch(error => {
				const notFound = 404;
				const customStatus = 1038;
				const status = error.status || customStatus;

				if (status === notFound) {
					this.notFound = true;

					return;
				}

				this.error = `An unknown error has occurred. Please try again later. [Code: PR.SF-47${status}]`;

				sentry.captureException(error);
			})
			.finally(() => { this.loading = false });
	}
}
