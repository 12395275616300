export default {
	namespaced : true,

	state : {
		count : 0,
	},

	getters : {
		isLocked(state) {
			return state.count > 0;
		},
	},

	mutations : {
		lock(state) {
			state.count += 1;

			return state.count;
		},
		unlock(state) {
			if (state.count > 0) state.count -= 1;

			return state.count;
		},
		clear(state) {
			state.count = 0;

			return state.count;
		},
	},

	actions : {
		lock({ commit }) {
			return Promise.resolve(commit('lock'));
		},
		unlock({ commit }) {
			return Promise.resolve(commit('unlock'));
		},
		clear({ commit }) {
			return Promise.resolve(commit('clear'));
		},
	},
};
