import Vue from 'vue';
import Component from 'vue-class-component';
import template from './work-location-input-row.html';
import Watch from '@/plugins/watch-decorator';
import api from '@/api';

import { CancelButton } from '@/buttons/cancel-button/cancel-button';
import { ErrorDisplay } from '@/components/error-display/error-display';
import { NumericInput } from '@/inputs/numeric-input/numeric-input';
import { Spinner } from '@/base-components/spinner/spinner';
import { SubmitButton } from '@/base-components/submit-button/submit-button';
import { TextInput } from '@/inputs/text-input/text-input';
import { PlusButton } from '@/buttons/plus-button/plus-button';

const components = {
	CancelButton,
	ErrorDisplay,
	NumericInput,
	Spinner,
	SubmitButton,
	TextInput,
	PlusButton,
};
const props = {
	workZone : {
		type     : Object, // WorkZone record
		required : true,
	},
};

@Component({
	template,
	components,
	props,
})
export class WorkLocationInputRow extends Vue {
	data() {
		return {
			error        : null,
			loading      : null,
			workLocation : null,
		};
	}

	@Watch('workLocation')
	watchWorkLocation() {
		this.error = null;
	}

	start() {
		const { workZone_id } = this.workZone;

		this.workLocation = new api.workLocation.WorkLocation({
			workZone_id,
		});
	}

	submit() {
		if (this.loading) return;
		if (!this.workLocation.workLocationName)
			return;

		this.loading = true;
		this.error = false;

		return api.workLocation.save(this.workLocation)
			.then(workLocation => { this.$emit('input', workLocation) })
			.catch(error => { this.error = error })
			.finally(() => { this.loading = false });
	}
}

Vue.component('work-location-input-row', WorkLocationInputRow);
